

import { Button } from "@suid/material";
import { HomeIcon } from "../icons"
import { Show, createSignal } from "solid-js";
import { useNavigate } from "@solidjs/router";

import { isLarge }  from "../../../utils/sizeUtil"

import { Popover, Typography } from "@suid/material";
import { getText } from '../SmallComponents/Translate'

const HomeButton = () => {
  const navigate = useNavigate();

  const handleShowHomePage = () => {
    navigate("/home")
  };
  const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);

  const handlePopoverOpen = (event: { currentTarget: Element }) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
   
  const open = () => Boolean(anchorEl());

  const txtHome = getText("menu","home")
  
  return (

    <>
      <Show
        fallback={
          <Button color='primary' startIcon={<HomeIcon />}  sx={{ minWidth: 60 }}
            onClick={handleShowHomePage}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
          </Button>
        }
        when={isLarge() === true}
      >
        <Button color='primary'  startIcon={<HomeIcon />} onClick={handleShowHomePage}>{txtHome()}</Button>
      </Show>

      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: "none" }}
        open={open()}
        anchorEl={anchorEl()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography
          aria-owns={open() ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {txtHome()}
        </Typography>

      </Popover>
    </>
  );
}
export { HomeButton }
