import { Show, JSXElement, splitProps } from "solid-js";
import { Typography } from "@suid/material"; 
import { getText } from '../SmallComponents/Translate'
import { formatDateByCountry, formatTimeByCountry } from '../../../utils/datetimeflag';

interface WFStatusFooterProps {
    children: JSXElement;
    type: "customer" | "candidate"
    actionDate: string;
    userName?: string;
    showDivider?: boolean;
}

const WFStatusFooter = (props: WFStatusFooterProps) => {
    const [local] = splitProps(props, ['children'])
    const txtSend = props.type==="customer" ? getText("smallcomponent", "sendwithspaces") : getText("smallcomponent", "receivedwithspaces")
    const txtBy = props.type==="customer" ? getText("smallcomponent", "bywithspaces")  : getText("smallcomponent", "fromwithspaces")
     
    
    return (
        <>
            <Show
                when={props.userName}
                fallback={
                    <Show
                    when={props.actionDate}
                    >
                    <Typography  fontSize="80%" variant="body2" color="text.secondary" align="right" sx={{ px: 1 }}>{props.children}{txtSend()}{props.actionDate} </Typography>
                    </Show>
                }
            >
                <Typography  fontSize="80%" variant="body2" color="text.secondary" align="right" sx={{ px: 1 }}>{props.children}{txtSend()} {formatDateByCountry(props.actionDate)} {formatTimeByCountry(props.actionDate)} {txtBy()}{props.userName}</Typography>
            </Show>
        </>
    );
}
export { WFStatusFooter }