import { createSignal, Show, splitProps, JSXElement, children } from "solid-js";
import { Typography, Icon, Stack } from "@suid/material";

import {
    DashboardIcon, CheckIcon, QuestionMarkIcon, ScheduleIcon, ContractIcon, ProposalIcon, InterviewIcon,
    FavouritesIcon, FavouritesDeleteIcon, TimelineIcon, MatchingIcon, CommentIcon, AskForIcon,
    RateIcon, HoursIcon, TechnicalIcon, LanguageIcon, MethodIcon, RoleIcon, FeedbackIcon,
    Looks1OutlinedIcon, Looks2OutlinedIcon, Looks3OutlinedIcon, Looks4OutlinedIcon, Looks5OutlinedIcon, Looks6OutlinedIcon
} from "../icons"

interface HeadingProps {
    iconName?: string;
    pxValue?: number;
    size?: string;
    children: JSXElement;
}

const ShowHeading = (props: HeadingProps) => {
    const [local] = splitProps(props, ['children'])
    const iconName = props.iconName ? props.iconName : ""
    const pxValue = props.pxValue ? props.pxValue : 2
    const size = props.size ? props.size : ""

    let icon = <CheckIcon />;

    switch (iconName) {
        case "1":
            icon = <Looks1OutlinedIcon />;
            break;
        case "2":
            icon = <Looks2OutlinedIcon />;
            break;
        case "3":
            icon = <Looks3OutlinedIcon />;
            break;
        case "4":
            icon = <Looks4OutlinedIcon />;
            break;
        case "5":
            icon = <Looks5OutlinedIcon />;
            break;
        case "6":
            icon = <Looks6OutlinedIcon />;
            break;
        case "question":
            icon = <QuestionMarkIcon />;
            break;
        case "comment":
            icon = <CommentIcon />;
            break;
        case "schedule":
            icon = <ScheduleIcon />;
            break;
        case "contract":
            icon = <ContractIcon />;
            break;
        case "proposal":
            icon = <ProposalIcon />;
            break;
        case "askfor":
            icon = <AskForIcon />;
            break;
        case "interview":
            icon = <InterviewIcon />;
            break;
        case "language":
            icon = <LanguageIcon />;
            break;
        case "method":
            icon = <MethodIcon />;
            break;
        case "role":
            icon = <RoleIcon />;
            break;
        case "technical":
            icon = <TechnicalIcon />;
            break;
        case "favorite":
            icon = <FavouritesIcon />;
            break;
        case "heart":
            icon = <FavouritesIcon />;
            break;
        case "heartbroken":
            icon = <FavouritesDeleteIcon />;
            break;
        case "timeline":
            icon = <TimelineIcon />;
            break;
        case "matching":
            icon = <MatchingIcon />;
            break;
        case "hours":
            icon = <HoursIcon />;
            break;
        case "rate":
            icon = <RateIcon />;
            break;
        case "feedback":
            icon = <FeedbackIcon />;
            break;
        case "dashboard":
            icon = <DashboardIcon />;
            break;
        default:
            break;
    }
    let textPxValue = pxValue;
    if (pxValue > 2 && iconName)
        textPxValue = 2;
    return (
        <>
            <Stack direction="row" spacing={0.5}>
                <Show
                    when={iconName}
                    fallback={
                        <Show
                            when={size == "small"}
                            fallback={
                                <Typography sx={{ paddingLeft: pxValue, paddingRight: pxValue, py: 1 }} variant="h4" gutterBottom> {props.children}</Typography>
                            }
                        >
                            <Typography sx={{ paddingLeft: pxValue, paddingRight: pxValue, py: 1 }} variant="h6" gutterBottom> {props.children}</Typography>
                        </Show>
                    }
                >
                    <Show
                        when={size == "small"}
                        fallback={
                            <>
                                <Icon sx={{ paddingLeft: pxValue, paddingRight: pxValue, py: 1 }} fontSize="large"> {icon} </Icon>
                                <Typography sx={{ py: 1 }} variant="h4" gutterBottom> {props.children}</Typography>
                            </>
                        }
                    >
                        <Icon sx={{ paddingLeft: pxValue, paddingRight: pxValue, py: 1 }} fontSize="small"> {icon} </Icon>
                        <Typography sx={{ py: 1 }} variant="h6" gutterBottom> {props.children}</Typography>
                    </Show>
                </Show>

            </Stack>
        </>
    );
}

export { ShowHeading }