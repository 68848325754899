
import { createSignal, Show, } from "solid-js";
import { IconButton, Button, Typography, Badge, Popover, } from "@suid/material";
import toast from 'solid-toast';
import { getText } from '../SmallComponents/Translate'
import { useNavigate } from "@solidjs/router";
import { isLarge, isExtraLarge } from "../../../utils/sizeUtil"
import { SharingIcon } from "../icons"

const AppBarSharing = () => {
  const open = () => Boolean(anchorEl());

  const txtShare = getText("sharing", "share")
  const txtIntro = getText("sharing", "intro")

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);

  const handlePopoverOpen = (event: { currentTarget: Element }) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const handleOpenFavourits = () => {

    { txtIntro() }
    toast(txtIntro(), { duration: 5000, });
    navigate('/share')
  }

  return (
    <>

      {/* ********************** */}
      {/* Show Share Button      */}
      {/* ********************** */}
      <Show
        fallback={
          <>
            <Badge badgeContent={7} color="error">
              <IconButton color='primary' size="small" aria-label="Sharing" sx={{ minWidth: 60 }}
                onClick={handleOpenFavourits}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              >
                <SharingIcon />
              </IconButton>
            </Badge>
          </>
        }
        when={isLarge() || isExtraLarge()}
      >
        <Badge badgeContent={5} color="error">
          <Button color='primary' size="small" aria-label="Sharing" sx={{ minWidth: 120 }}
            onClick={handleOpenFavourits}
            startIcon={<SharingIcon />}>

            {txtShare()}

          </Button>
        </Badge>

      </Show>

      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: "none" }}
        open={open()}
        anchorEl={anchorEl()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography
          aria-owns={open() ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {txtShare()}
        </Typography>

      </Popover>

    </>
  );
}

export { AppBarSharing }
