import { TextField, FormControl, IconButton, Box, Popover, CircularProgress } from "@suid/material";
import { Show, createSignal  } from "solid-js";
import toast from "solid-toast";
import { getURL } from '../../../utils/fetchAPI'
import { AIIcon } from "../icons"
import { getText } from '../SmallComponents/Translate'

const apiUrl = import.meta.env.VITE_APP_API_URL;

interface FieldTextValueProps {
    id: string;
    label?: string;
    textValue: string;
    width?: number;
    multiline?: boolean;
    maxRows?: number;
    fullWidth?: boolean;
    error?: boolean;
    helperText?: string;
    valueOnChange: (value: string) => void;
    handleValueChangeFromParent?: (value: string) => void;
}

type AIRequest = {
    status: string
    ok: boolean
    error: string
    textRequest: string
    textResult: string
}

const FieldTextValue = (props: FieldTextValueProps) => {

    //onMount( () => ( {
    //props.error = props?.error === undefined ? false : props.error ;
    //{props?.helperText === undefined ? " " : props?.helperText}
    //})

    const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);

    const handlePopoverOpen = (event: Event) => {
        setAnchorEl(event.currentTarget as Element);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = () => Boolean(anchorEl());

    const [value, setValue] = createSignal<string | null>(props.textValue);

    const [chatGPTmessage, setChatGPTMessage] = createSignal("");

    const handleAITextRequest = () => {
        try {
            const aiRequest = `${apiUrl}/ai/aitextrequest?textRequest="${value()}"`

            const txtAIError = getText("smallcomponent", "aierror")
            if (aiRequest) {


                getURL(aiRequest
                    , 'GET', null)
                    .then((fetchedData: AIRequest) => {

                        if (fetchedData?.status === '200') {
                            setChatGPTMessage(fetchedData?.textResult)
                            setValue(fetchedData?.textResult)
                            props.valueOnChange?.(fetchedData?.textResult);

                            handlePopoverClose()
                        } else {
                            setChatGPTMessage(txtAIError() as string)
                            handlePopoverClose()
                        }
                    }).catch((error) => {

                        console.log("Error during handling of AI request: ", value(), ". Error: ", error)

                        toast.error("Error during handling of AI request. Error: ", error)
                        handlePopoverClose()
                    });

            }
        } catch (error) {

            console.log("Error during handling of AI request: ", value(), ". Error: ", error)

            toast.error("Error during handling of AI request.")
        }

    }

    const handleInputChange = (onChange: (value: string) => void) => (event: Event) => {
        const newValue = (event.target as HTMLInputElement).value;
        onChange?.(newValue);
        setValue(newValue)
    };

    //sx={{ width: props.width ? props.width : 115 }}
    return (
        <>

            <Popover
                id="mouse-over-popover"
                sx={{ pointerEvents: "none", width: '100vw', height: '100vh' }} // Stretch the popover to cover the entire viewport
                open={open()}
                anchorReference="anchorPosition" // Use anchor position for positioning
                anchorPosition={{ left: window.innerWidth / 2, top: window.innerHeight / 2 }} // Center it


                anchorEl={anchorEl()}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Box

                    alignItems="center"
                    justifyContent="center"
                    width="100%"
                    height="100%"
                >
                    <CircularProgress color="inherit" />
                </Box>
            </Popover>

            <Show
                fallback={
                    <FormControl fullWidth >
                        <TextField
                            required id={props.id} label={props.label} type="text" variant="outlined"
                            size="small"
                            defaultValue={props.textValue ? props.textValue : "" }

                            value={props.textValue}
                            onChange={handleInputChange(props.valueOnChange)}

                        />
                    </FormControl>
                }
                when={props.multiline}
            >
                <FormControl fullWidth >
                    <TextField
                        required id={props.id} label={props.label} type="text" variant="outlined"
                        size="small"
                        defaultValue={props.textValue ? props.textValue : ""}

                        value={props.textValue}
                        onChange={handleInputChange(props.valueOnChange)}
                        multiline={props.multiline}
                        rows={props.maxRows ? props.maxRows : 4}
                        InputProps={{
                            endAdornment: (

                                <IconButton

                                    id="ai-button"
                                    onClick={(event) => { handlePopoverOpen(event); handleAITextRequest() }}
                                >
                                    <AIIcon

                                    />
                                </IconButton>

                            ),
                        }}
                    />
                </FormControl>
            </Show>
        </>
    )
}

export { FieldTextValue } 