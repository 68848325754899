
import {   useNavigate } from "@solidjs/router";
import toast, { Toaster } from "solid-toast";
import { handleLogout } from "../utils/isloggedin";
const apiUrl = import.meta.env.VITE_APP_API_URL;
const isDEV = apiUrl.includes("localhost");

// credentials: 'same-origin',
export const getURL = async (
  url: RequestInfo | URL,
  method: string,
  body: any,
  noJson?: any,
  other = { current: 0, mode: null }
): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    fetch(url, {
      method: method || "GET",
      credentials: "include",

      headers: {
        "Content-Type": "application/json",
      } as HeadersInit,
      //mode: other.mode || 'cors',
      body: body ? JSON.stringify(body) : null,
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            // Handle unauthorized error (401)
            window.location.href = 'https://findasap.team/login'
            toast.error(
              "Please login again. You are not " + response.statusText,
              { duration: 5000 }
            );

            // Ensure to ask for redirect to login next time if in production
            console.log("Unauthorized error (401) - Is developement: " + isDEV);
            if (!isDEV) handleLogout();

            // return resolve({ status: 401, ok: false, message: 'Unauthorized' });
            //const navigate = useNavigate();
            //navigate("/login")
          
            reject({
              status: response.status,
              ok: false,
              message: "Unauthorized",
            });
           
            return;
          } else if (response.status === 404) {
            // Handle  error (404)
            toast.error("Page not found. Please try again. ", {
              duration: 5000,
            });

            // return resolve({ status: 401, ok: false, message: 'Unauthorized' });
            reject({
              status: response.status,
              ok: false,
              message: "Not found",
            });
            return;
          } else if (response.status === 429) {
            // Handle  error (429) to may requests
            toast.error("Tried to many times. ", { duration: 5000 });

            // return resolve({ status: 401, ok: false, message: 'Unauthorized' });
            reject({
              status: response.status,
              ok: false,
              message: "Tried to get data to many times from server",
            });
            return;
          } else if (response.status === 524) {
            // Handle  error (524) timeout
            toast.error("AI was not able to complete before timeout. ", { duration: 5000 });

            // return resolve({ status: 401, ok: false, message: 'Unauthorized' });
            reject({
              status: response.status,
              ok: false,
              message: "AI was not able to complete before timeout",
            });
            return;
          }
          if (other.current >= 2) {
            //return resolve({ status: response.status, ok: true, message: 'Failed to get URL.' });
            reject({
              status: response.status,
              ok: false,
              message: "Failed to get URL.",
            });
            return;
          }
          other.current++;
          return getURL(url, method, body, noJson, other).then(resolve);
        }
        //console.log(JSON.stringify({ status: response.status, text: response.statusText, header: response.headers, data: response.text }));

        return resolve(noJson ? response : response.json());
      })
      .catch((error) => {
        toast.error(
          "Something went wrong! Please try again. Technical Message: " +
            error?.message
            ? error?.messge
            : error,
          { duration: 5000 }
        );
        console.log(
          "Something went wrong! " + error?.message ? error.messge : error
        );
        reject(error);
      });
  });
};

//const response = await getURL("/api/users/new", 'POST', { name: 'abe', password: 'hej' })
