
import { createSignal, createEffect, Accessor, JSX } from "solid-js";
import { FieldUILanguageList } from '../SmallComponents/SmallFieldLanguageList';
import { MenuItem, ListItemIcon } from "@suid/material";
import { getUserStore, setUserStore } from "../../context/userContext";

type LocaleDataType = {
    [formName: string]: {
        [label: string]: string;
    };
};

const [translations, setTranslations] = createSignal<LocaleDataType>();
const [selectedLanguage, setSelectedLanguage] = createSignal((localStorage.getItem('UILanguage') ? localStorage.getItem('UILanguage') : "en" as string)); //da
const [fallbackLanguage, setFallbackLanguage] = createSignal("en");

let fetchingTranslations = false;
const fetchTranslations = async (lang: string): Promise<LocaleDataType> => {
    const response = await fetch(`/assets/locals/${lang}.json`);
    if (!response.ok && lang !== fallbackLanguage()) {
        console.log("Running fallback translation for " + lang, fallbackLanguage())
        return fetchTranslations(fallbackLanguage())
    } else if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    //console.log(lang + ": " + JSON.stringify(response));

    return response.json();
};

const InitTranslation = () => {
    if (fetchingTranslations) return;

    fetchingTranslations = true;

    try {
        //setTranslations({ "menu": { "home": "Home", "login": "Login", "signup": "Signup"}} )

        fetchTranslations(selectedLanguage() as string).then(inittranslations => {
            setTranslations(inittranslations)
            fetchingTranslations = false;

        }).catch(e => {
            console.log("Error when loading resource file with translaitons. Error: " + e)
        });

    } catch (e) {
        console.log("Error when loading resource file with translaitons. Error: " + e)
    }
};

const SelectTranslation = () => {
    const currentUILanguage = localStorage.getItem('UILanguage')
    if (selectedLanguage() !== currentUILanguage) {
        localStorage.setItem('UILanguage', (currentUILanguage ? currentUILanguage : selectedLanguage() as string));
    }
    return (
        <>

            <FieldUILanguageList defaultCode={selectedLanguage() as string} changeLanguage={changeLanguage} />

        </>
    );
}


const SelectLanguageMenuItem = () =>{
    const currentUILanguage = localStorage.getItem('UILanguage')
    if (selectedLanguage() !== currentUILanguage) {
        localStorage.setItem('UILanguage', (currentUILanguage ? currentUILanguage : selectedLanguage() as string));
    }
    return (
        <>
            <MenuItem  >
                <ListItemIcon>
                    
                    <FieldUILanguageList defaultCode={selectedLanguage() as string} changeLanguage={changeLanguage} />
                </ListItemIcon>
                {selectedLanguage() as string} 
            </MenuItem>
        </>
    );
}


export const changeLanguage = async (lang: string) => {

    setSelectedLanguage(lang);
    if (lang) {
        localStorage.setItem('UILanguage', lang);
    }
    fetchTranslations(lang).then(translations => {
        setTranslations(translations)

    }).catch(error => {
        console.error('Error fetching translations for ' + lang + ':' + error);
    });
};


const getCurrentUILanguageCode = () => {
    return (selectedLanguage() ? selectedLanguage() : 'en') as string;
}

const getText = (formName: string, label: string, vars?: { name: string, value: string }[]): Accessor<string | undefined> => {
    if (!translations()) {
        // setTranslations({ "menu": { "home": "Home", "login": "Login", "signup": "Signup"}} )
        if (!translations()) InitTranslation()
    };

    const [text, setText] = createSignal<string>();

    createEffect(() => {
        let newText = translations()?.[formName]?.[label];
        if (vars && newText) {
            vars.forEach((v) => {
                const placeholder = `{{${v.name}}}`;
                newText = newText?.replace(placeholder, v.value);
            });
        }
        setText(newText);
    });

    return text;
};


type GetTextUIProps = {
    formName: string;
    label: string;
    vars?: { name: string, value: string }[];

};

export const GetTextUI = (props: GetTextUIProps) => {
    if (!translations()) {
        // setTranslations({ "menu": { "home": "Home", "login": "Login", "signup": "Signup"}} )
        if (!translations()) InitTranslation()
    };

    const [text, setText] = createSignal<string>();

    createEffect(() => {
        let newText = translations()?.[props.formName]?.[props.label];
        if (props.vars && newText) {
            props.vars.forEach((v) => {
                const placeholder = `{{${v.name}}}`;
                newText = newText?.replace(placeholder, v.value);
            });
        }
        setText(newText);
    });

    return (
        <>
            {text()}
        </>
    );
};

export { SelectTranslation,  getText, getCurrentUILanguageCode, SelectLanguageMenuItem }
