

import { createSignal, For, onMount, Show } from "solid-js";
import { ToggleButtonGroup, ToggleButton, Input, Button, IconButton } from "@suid/material";
import Fa from '~/src/components/SolidFa'

import { PlayIcon, faCirclePlay, faYoutube, faFacebook, faInstagram, faTwitter, faLinkedin } from '../icons'
 
import {FieldTextValue} from "../SmallComponents/SmallFieldText"; 
import { getText } from '../SmallComponents/Translate'

import { isSmall, isExtraSmall } from "../../../utils/sizeUtil"

type CandidateCVSOMEDataElementsType = {
    productName: string;
    productURL: string;
    productURLId: string;
}[] | null;

interface UploadSocialMediaLinkProps {
    startValue?: string;
    SoMes?: CandidateCVSOMEDataElementsType;
}

const ShowSocialMedia = (props: UploadSocialMediaLinkProps) =>{


    const handleClickMedia = (key: String,someId: String) => {
        if (key === 'instagram') {
            const url = `https://www.instagram.com/?api=1&query=${encodeURIComponent(someId ? someId as string: "unknown")}`;
            window.open(url, '_blank');
        } else if (key === 'facebook') {
            const url = `https://www.facebook.com/?api=1&query=${encodeURIComponent(someId ? someId as string: "unknown")}`;
            window.open(url, '_blank');
        } else if (key === 'linkedin') {
            const url = `https://www.linkedin.com/in/${encodeURIComponent(someId ? someId as string : "unknown")}`;
            window.open(url, '_blank');
        } else if (key === 'twitter') {
            const url = `https://www.x.com/?api=1&query=${encodeURIComponent(someId ? someId as string : "unknown")}`;
            window.open(url, '_blank');
        } else if (key === 'youtube') {
            const url = `https://youtube.com/query=${encodeURIComponent(someId ? someId as string: "unknown")}`;
            window.open(url, '_blank');
        }

    };

    const getIcon = (productName: String) => {
        switch (productName.toLowerCase()) {
            case 'youtube':
                return <PlayIcon />;
            case 'linkedin':
                return <Fa icon={faLinkedin} />;
            case 'facebook':
                return <Fa icon={faFacebook} />;
            case 'twitter':
                return <Fa icon={faTwitter} />;
            case 'instagram':
                return <Fa icon={faInstagram} />;
            default:
                return null; // or a default icon
        }
    };

    interface ProductNameType {
        productName: String;
        productURLId: String;
    }

    const SocialMediaIcon = (props: ProductNameType) => {
        return (
            <IconButton onClick={() => handleClickMedia(props.productName,props.productURLId)}>
                {getIcon(props.productName)}
            </IconButton>
        );
    }

    return (
        <>
            <For each={props.SoMes}>
                {(item) => (
                    
                   
                        <SocialMediaIcon productName={item.productName} productURLId={item.productURLId}/>
                        
                )}
            </For>
        </>
    );
}

const UploadSocialMediaLink = (props: UploadSocialMediaLinkProps) =>{
    //const [local] = splitProps(props, ['other'])

    const [open, setOpen] = createSignal(false);

    const [value, setValue] = createSignal(props?.startValue ? props.startValue : "");
    // const [filteredOptions, setFilteredOptions] = createSignal<AddressType[]>([]);

    onMount(async () => {
        setValue(props?.startValue ? props?.startValue : "");
    })
 
    const [socialMedia, setSocialMedia] = createSignal("presentation");
    const handleSocialMedia = (value: string) => {
        setSocialMedia(value);
    }
    const handleInputChange = (key: string, value: string) => {

    };

    const txtUrlsocial = getText("jobpage", "urlsocial")
    const txtUpload = getText("default", "upload")
    return (
        <>

            <ToggleButtonGroup
                color="primary"
                sx={{ px: 2 }}
                value={socialMedia}

                exclusive

                onChange={(event, value) => {
                    handleSocialMedia(value);
                }}
            >

                <ToggleButton value="presentation" ><Fa icon={faCirclePlay} /></ToggleButton>
                <ToggleButton value="youtube"> <Fa icon={faYoutube} /></ToggleButton>
                <ToggleButton value="linkedIn"><Fa icon={faLinkedin} /></ToggleButton>
                <ToggleButton value="facebook"> <Fa icon={faFacebook} /></ToggleButton>
                <ToggleButton value="twitter"> <Fa icon={faTwitter} /></ToggleButton>
            </ToggleButtonGroup>

            <Show
                when={!isExtraSmall()}
            >
                <FieldTextValue
                    id="linktsocme" label={txtUrlsocial() as string} width={300} textValue=""
                    valueOnChange={value => handleInputChange("linksocme", value)}
                />
                <label for="contained-button-file">
                    <Input
                        accept="image/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                    />
                    <Button variant="contained" component="span">
                        {txtUpload()}
                    </Button>
                </label>
            </Show>

        </>
    );
}

export { ShowSocialMedia, UploadSocialMediaLink };

