import { Show, JSX, splitProps  } from "solid-js";
import { useNavigate } from "@solidjs/router";

import Avatar from '@suid/material/Avatar';
import { blue } from "@suid/material/colors";
import { ImageIcon, JobIcon } from '../icons';

import { Divider, ListItem, ListItemText, ListItemAvatar, ListItemButton } from "@suid/material";

interface NotificationListItemProps {
   // primary: string;
    secondary?: string;
    notificationIcon?: JSX.Element;
    handleEvent?: (event: MouseEvent) => void;
    type?: string;
    showDivider?: boolean;
    children: JSX.Element;
    route?: string;
}
 
const NotificationListItem = ( props: NotificationListItemProps) => {
    const [local] = splitProps(props, ['children'])
    props.secondary === undefined ? props.secondary = "" : props.secondary =props.secondary
   // props.notificationIcon = null
    props.showDivider===undefined ? props.showDivider = true : props.showDivider = false
    props.type === undefined ? props.type = "" : props.type = props.type
    const navigate = useNavigate();
    const handleOnClick = () => {
        if (!props.route) return;
        navigate( props.route )
    }
    return (
        <>
            <ListItem disablePadding >
                <ListItemButton onClick={handleOnClick}>
                    <ListItemAvatar>
                        <Avatar sx={{
                            bgcolor: blue[100],
                            color: blue[600],
                        }}
                        >
                            {props.notificationIcon}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={props.children} secondary={props.secondary} /> 
                </ListItemButton>
            </ListItem>
            <Show
                when={props.showDivider}
            >   
                <Divider variant="inset" component="li" />
            </Show>
        </>
    );
}

const NotificationList = () =>{

    return (
        <>
            <NotificationListItem secondary="5 hours ago" notificationIcon={<ImageIcon/>} >"3 co-workers joined your team" </NotificationListItem>
            <NotificationListItem secondary="6 hours ago" notificationIcon={<JobIcon/>} >"3 interviews comming up today" </NotificationListItem>
            <NotificationListItem secondary="8 hours ago" notificationIcon={<JobIcon/>} showDivider={false} >"7 people loved your job" </NotificationListItem>
        </>
    );
}

export {NotificationListItem, NotificationList }