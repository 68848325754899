

import { Suspense, createSignal, createEffect, createMemo, Switch as SolidSwitch, Match, For, Show, JSXElement, splitProps } from "solid-js";
import { createStore, } from "solid-js/store";
import { Grid, Alert, Card,  Button, Chip, Stack, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Box, Typography } from "@suid/material";
import { CardWithMinimize } from '../AppMenuBar/CardList'
import { CloseIcon, AddIcon, RecordVoiceOverIcon, CreateIcon } from '../icons';
import { FieldSelectList } from '../SmallComponents/SmallFieldSelectList'
import { isExtraSmall, isSmall, } from "../../../utils/sizeUtil"

import { CandidateCVCompetenceDataElementsType } from "../../../types/candidateCVDataTypes"

import { getText, GetTextUI } from '../SmallComponents/Translate' 
import { FieldTextValue } from "./SmallFieldText";

type OptionType = string[]; // Replace with the correct type for options

interface CompetenceListProps {
    options?: CandidateCVCompetenceDataElementsType; //CandidateCompetenciesFetchType; // OptionType |
   
    header?: string;
    alignItems?: 'left' | 'center' | 'right' | 'top' | 'bottom'
    direction?: 'column' | 'row' | 'row-reverse' | 'column-reverse';
    type: "role" | "industry" | "technical" | "method" | "personal" | "language"
    showAddButton?: boolean
    children?: JSXElement;
}
interface DialogHeaderProps {
    header: string;
}
type LevelType = {
 
    level: string;
    header: string | undefined;
    description: string | undefined;
};


const CompetenceList = (props: CompetenceListProps) => {
    const [local] = splitProps(props, ['children'])
    const [level, setLevel] = createSignal("0");
    const [label, setLabel] = createSignal("");

    const [saving, setSaving] = createSignal(false);

    const [openDialog, setOpenDialog] = createSignal(false);
    const [openLevelDialog, setOpenLevelDialog] = createSignal(false);
    const handleOpenDialog = () => {
        setOpenDialog(true);
    }
    const handleOpenLevelDialog = (chipLabel: string, newLevel: string) => {
        setOpenLevelDialog(true);
        setLabel(chipLabel);
        setLevel(newLevel)
    }
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setOpenLevelDialog(false)
        setSaving(false)
    }
    const handleSetLevel = (newLevel: string) => {
        setLevel(newLevel)
    }


    const handleAddCompetence = () => {

    }

    const handleUpdateCompetence = () => {
        setSaving(true)
    }

    const txtHeader = getText("jobpage", "competencies")
    const txtClose = getText("default", "close")
    const txtUpdate = getText("default", "update")

    const [size, setSize] = createSignal({ dialogMargin: 2, dialogSpacing: 2, buttonSize: 200 })
    if (isExtraSmall())
        setSize({ dialogMargin: 1, dialogSpacing: 1, buttonSize: 40 });

    const DialogHeader = ({ header }: DialogHeaderProps) => {
        return (
            <DialogTitle>
                <Stack direction="row" spacing={size().dialogSpacing} >
                    <IconButton onClick={handleCloseDialog} > <CloseIcon /> </IconButton>
                    <Typography variant="h4">{header}</Typography>
                </Stack>
            </DialogTitle>
        )
    }
    interface footerProps {
        showUpdateButton: boolean;
    }
    const DialogFooter = (props: footerProps) => {
        return (
            <DialogActions>
                <Show when={props.showUpdateButton === true}>
                    <Button variant="contained" color="info" onClick={handleUpdateCompetence}>{txtUpdate()}</Button>
                </Show>
                <Button variant="outlined" color="info" onClick={handleCloseDialog}>{txtClose()}</Button>
            </DialogActions>
        )
    }

    const LevelButton = (
        props: {
            item: LevelType,
        }
    ) => {
        const { item } = props;
        const [isActive, setIsActive] = createSignal(false)

        createEffect(() => {
            setIsActive(level() === item.level);
        });
        let buttonText = isExtraSmall() ? item.level : item.level + ". " + item.header

        return (
            <Stack direction="row" spacing={2}>
                <Button
                    sx={{ minWidth: size().buttonSize }}
                    color="success"
                    onClick={() => handleSetLevel(item.level)}
                    size="large"
                    variant={isActive() ? "contained" : "outlined"}
                >
                    {buttonText}
                </Button>
                <Typography
                    sx={{ px: 1 }}
                    variant="body2"
                    color={isActive() ? "text.primary" : "text.secondary"}
                >
                    {item.description}
                </Typography>
            </Stack>
        );
    };

    const txtH = !props?.header ? "" : props.header
    const txtHasCompetency = getText("smallcomponent", "hascompetency")
    const txtCompetenceInstrucs = getText("smallcomponent", "competenceinstrucs")

    const txtUpdateCompetencies = getText("smallcomponent", "updatecompetencies", [{ name: 'header', value: txtH }])
    const txtSelfAssesment = getText("smallcomponent", "selfassesment")
    const txtShowCustomerSelfAssesment= getText("smallcomponent", "selfassesmentcustomer")
    const txtCompetenceL0Header = getText("smallcomponent", "headerl0")
    const txtCompetenceL1Header = getText("smallcomponent", "headerl1")
    const txtCompetenceL2Header = getText("smallcomponent", "headerl2")
    const txtCompetenceL3Header = getText("smallcomponent", "headerl3")
    const txtCompetenceL4Header = getText("smallcomponent", "headerl4")
    const txtCompetenceL5Header = getText("smallcomponent", "headerl5")
    const txtCompetenceL0 = getText("smallcomponent", "l0")
    const txtCompetenceL1 = getText("smallcomponent", "l1")
    const txtCompetenceL2 = getText("smallcomponent", "l2")
    const txtCompetenceL3 = getText("smallcomponent", "l3")
    const txtCompetenceL4 = getText("smallcomponent", "l4")
    const txtCompetenceL5 = getText("smallcomponent", "l5")

    const txtNotFound = getText("default", "notfound")


    const [state, setState] = createStore({
        addField: '',
    });

    const handleChange = (key: keyof typeof state, newValue: any) => {
        setState(key, newValue);
    };

    let competenceLevels: LevelType[] = [];
    createMemo(() => {
        competenceLevels = [
            { level: "0", header: txtCompetenceL0Header() as string, description: txtCompetenceL0() as string },
            { level: "1", header: txtCompetenceL1Header() as string, description: txtCompetenceL1() as string },
            { level: "2", header: txtCompetenceL2Header() as string, description: txtCompetenceL2() as string },
            { level: "3", header: txtCompetenceL3Header() as string, description: txtCompetenceL3() as string },
            { level: "4", header: txtCompetenceL4Header() as string, description: txtCompetenceL4() as string },
            { level: "5", header: txtCompetenceL5Header() as string, description: txtCompetenceL5() as string },
        ];
    });

    const getLevelText = (level: string): string | undefined => {
        /*
        const foundLevel = competenceLevels.find(competenceLevel => competenceLevel.level === level);
        if (foundLevel) {
            return " (" + foundLevel.header + ")"
        } else
            return undefined;
        */
        if (level === "1")
            return " (1. " + txtCompetenceL1Header() + ")"
        else if (level === "2")
            return " (2. " + txtCompetenceL2Header() + ")"
        else if (level === "3")
            return " (3. " + txtCompetenceL3Header() + ")"
        else if (level === "4")
            return " (4. " + txtCompetenceL4Header() + ")"
        else if (level === "5")
            return " (5. " + txtCompetenceL5Header() + ")";
        return "";
    }

    

    const txtAdd = getText("smallcomponent", "addtag")
    const chipMargin = '4px'
    const failedColor = "error"

    return (
        <>
            <Suspense fallback={
                <Alert sx={{ px: 1, py: 1 }} severity="info">
                    <GetTextUI formName={"default"} label={"loading"} />.
                </Alert>
            }>
                <Show
                    when={props.header}
                    fallback={

                        <Stack sx={{ px: 1 }} alignItems={props.alignItems} direction="column" spacing={0.5}>
                            <Stack sx={{ px: 1, py: 1, display: 'flex', flexWrap: 'wrap' }} alignItems={props.alignItems} direction={props.direction} spacing={1}  >
                                <SolidSwitch fallback={<div>{txtNotFound()}</div>}>
                                    <Match when={props.type === "language"}>
                                        <For each={props.options} children={(option, index) => (
                                            <Chip label={option.languageCode} size="small" variant="outlined" style={{
                                                margin: chipMargin
                                            }} />
                                        )} />
                                    </Match>
                                    <Match when={props.type === "method"}>
                                        <For each={props.options} children={(option, index) => (
                                            <Chip label={option.translatedText + getLevelText(option.level)} size="small" variant="outlined" style={{
                                                margin: chipMargin
                                            }} />
                                        )} />
                                    </Match>
                                    <Match when={props.type === "role"}>
                                        <For each={props.options} children={(option, index) => (
                                            <Chip label={option.translatedText + getLevelText(option.level)} size="small" variant="outlined" style={{
                                                margin: chipMargin
                                            }} />
                                        )} />
                                    </Match>
                                    <Match when={props.type === "technical"}>
                                        <For each={props.options} children={(option, index) => (
                                            <Chip label={option.translatedText + getLevelText(option.level)} size="small" variant="outlined" style={{
                                                margin: chipMargin
                                            }} />
                                        )} />
                                    </Match>
                                </SolidSwitch >
                            </Stack>
                        </Stack>

                    }
                >
                    <CardWithMinimize level={1} iconName={props.type} header={props.header as string} type="list" defaultOpen={true}>
                        <Stack direction="column" alignItems={props.alignItems}    >
                            {/* Add a margin bottom to all direct children (which are the wrapped flex items) */}

                            <Grid container margin={1} >
                                <Grid xs={12}>

                                    <Show when={props.showAddButton === true}>
                                        <Chip onClick={handleOpenDialog} icon={<AddIcon />} label={txtAdd()} size="small"  style={{
                                            margin: chipMargin, cursor: 'pointer',
                                        }} />
                                    </Show>
                                    <SolidSwitch fallback={<div>{txtNotFound()}</div>}>
                                        <Match when={props.type === "language"}>
                                            <For each={props?.options} children={(option, index) => (
                                                <>

                                                    <Chip variant={option?.verbalStatus==="failed" ? "outlined" : "filled" } color={option?.verbalStatus==="failed" ? failedColor : "default"} onClick={event => handleOpenLevelDialog("Spoken " + option.languageCode, option.verbalLevel)} icon={<RecordVoiceOverIcon />} label={option.languageCode + getLevelText(option.verbalLevel)} size="small"  style={{
                                                        margin: chipMargin, cursor: 'pointer',
                                                    }} />
                                                    <Chip variant={option?.writtenStatus==="failed" ? "outlined" : "filled" } color={option?.writtenStatus==="failed" ? failedColor : "default"} onClick={event => handleOpenLevelDialog("Written " + option.languageCode, option.writtenLevel)} icon={<CreateIcon />} label={option.languageCode + getLevelText(option.writtenLevel)} size="small"  style={{
                                                        margin: chipMargin, cursor: 'pointer',
                                                    }} />

                                                </>
                                            )} />
                                        </Match>
                                        <Match when={props.type === "method"}>
                                            <For each={props.options} children={(option, index) => (
                                                <Chip variant={option?.status==="failed" ? "outlined" : "filled" } color={option?.status==="failed" ? failedColor : "default"} onClick={event => handleOpenLevelDialog(option.translatedText, option.level)} label={option.translatedText + getLevelText(option.level)} size="small"   style={{
                                                    margin: chipMargin, cursor: 'pointer',
                                                }} />
                                            )} />
                                        </Match>
                                        <Match when={props.type === "role"}>
                                            <For each={props.options} children={(option, index) => (
                                                <Chip variant={option?.status==="failed" ? "outlined" : "filled" } color={option?.status==="failed" ? failedColor : "default"} onClick={event => handleOpenLevelDialog(option.translatedText, option.level)} label={option.translatedText + getLevelText(option.level)} size="small"  style={{
                                                    margin: chipMargin, cursor: 'pointer',
                                                }} />
                                            )} />
                                        </Match>
                                        <Match when={props.type === "technical"}>
                                            <For each={props.options} children={(option, index) => (
                                                <Chip variant={option?.status==="failed" ? "outlined" : "filled" } color={option?.status==="failed" ? failedColor : "default"} onClick={event => handleOpenLevelDialog(option.translatedText, option.level)} label={option.translatedText + getLevelText(option.level)} size="small"  style={{
                                                    margin: chipMargin, cursor: 'pointer',
                                                }} />
                                            )} />
                                        </Match>
                                    </SolidSwitch >

                                </Grid>
                            </Grid>
                            <Show when={props.showAddButton === true}>
                                <Typography sx={{ px: 2 }} variant="body2" color="text.secondary">{txtCompetenceInstrucs()} </Typography>
                            </Show>



                        </Stack>
                    </CardWithMinimize>
                </Show>

                {openDialog() && (
                    <Dialog open={true} onBackdropClick={handleCloseDialog} >
                        <Box
                            component="form"
                            onSubmit={(e) => {
                                e.preventDefault();

                            }}
                        >
                            <DialogHeader header={txtHeader() as string} />
                            <DialogContent>
                                <Stack direction="column" spacing={size().dialogSpacing} margin={size().dialogMargin}>
                                    <Typography sx={{ px: 1 }} variant="body1"  >{txtUpdateCompetencies()}</Typography>
                                    <FieldSelectList saving={saving()} id="roleListId" label={props.type} type={props.type} />
                                    <Card>
                                        <Stack direction="column" spacing={size().dialogSpacing} margin={size().dialogMargin}>
                                            <FieldTextValue id="addinput" label="Add new" textValue="" valueOnChange={(newValue) => handleChange('addField', newValue)} />
                                            <Button variant="contained" color="info" onClick={handleAddCompetence}>{txtAdd()}</Button>
                                        </Stack>
                                    </Card>
                                </Stack>
                            </DialogContent>
                            <DialogFooter showUpdateButton={true} />
                        </Box>
                    </Dialog>
                )}

                {openLevelDialog() && (
                    <Dialog open={true} onBackdropClick={handleCloseDialog} >
                        <Box
                            component="form"
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}
                        >
                            <DialogHeader header={txtHeader() as string} />
                            <DialogContent>
                                <Stack direction="column" spacing={size().dialogSpacing} margin={size().dialogMargin}>

                                    <Typography sx={{ px: 1 }} variant="body1">{txtHasCompetency()}</Typography>
                                    <Chip label={label()} size="small" variant="outlined" />
                                    <Show when={props.showAddButton === true}
                                     fallback={
                                        <Typography sx={{ px: 1 }} variant="body1">{txtShowCustomerSelfAssesment()}</Typography>
                                     }>
                                        <Typography sx={{ px: 1 }} variant="body1">{txtSelfAssesment()}</Typography>
                                    </Show>
                                    <For
                                        each={competenceLevels}
                                    >{(item) => (
                                        <LevelButton item={item} />
                                    )}
                                    </For>
                                </Stack>
                            </DialogContent>
                            <DialogFooter showUpdateButton={false} />
                        </Box>
                    </Dialog>
                )}
            </Suspense>
        </>
    );
}


const CompetenceListWithGrids = (props: CompetenceListProps) => {
    return (
        <>
          <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
              <CompetenceList {...props} />
          </Grid>
        </>
    )
}

export { CompetenceList, CompetenceListWithGrids }