import List from '@suid/material/List';
import ListItem from '@suid/material/ListItem';
import ListItemText from '@suid/material/ListItemText';
import { blue, grey } from "@suid/material/colors";
import { NotificationsIcon } from "../icons"
import { createSignal, Show } from "solid-js";
import { Badge, Typography, IconButton, Divider, ListItemIcon, ListItemButton, Dialog, } from "@suid/material";

import { NotificationList } from '../SmallComponents/SmallNotificationList'
import { getText } from '../SmallComponents/Translate'

interface ShowNotificationProps {
    showButton: boolean;
    badgeContent: string;
}

const ShowNotifications = (props: ShowNotificationProps) => {
    const [open, setOpen] = createSignal(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const txtNotifications = getText("menu", "notifications");
    const txtSeeAll = getText("default", "seeall");

    return (
        <>
            {/* ********************** */}
            {/* Show button in menubar OR Listitem in list */}
            {/* ********************** */}
            <Show
                when={props.showButton}
                fallback={
                    <ListItemButton onClick={handleOpen}>
                    <ListItem >
                      <ListItemIcon>
                        <NotificationsIcon />
                      </ListItemIcon>
                      <ListItemText>
                        {txtNotifications()}
                      </ListItemText>
                      <Badge badgeContent={props.badgeContent}  max={99} color="error">

                      </Badge>
                    </ListItem>
                  </ListItemButton>
                }
            >
                <IconButton
                    size="large"
                    aria-label="show new notifications"
                    color='primary'
                    onClick={handleOpen}
                >
                    <Badge badgeContent={props.badgeContent} max={999} color="error">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
            </Show>

            {/* ********************** */}
            {/* Open dialog box        */}
            {/* ********************** */}
            {open() && (
                <Dialog open={true} onBackdropClick={handleClose}>
                    <Typography sx={{ margin: "15px" }} variant="h6">{txtNotifications()}</Typography>
                    <Divider />
                    <List sx={{ pt: 0, width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        <NotificationList />
                        <Divider />
                        <ListItem onClick={handleClose} disablePadding sx={{ bgcolor: grey[100], color: grey[600], }} >
                            <ListItemButton autoFocus>
                                <ListItemText primary={txtSeeAll()} sx={{
                                    textAlign: "center",
                                    color: blue[600],
                                }} />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Dialog>
            )}
        </>
    );
}
export { ShowNotifications }