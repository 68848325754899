
import { JSXElement, splitProps } from "solid-js";
import { useNavigate } from "@solidjs/router";

import { isSmall, isExtraSmall } from "../../../utils/sizeUtil"

import { Grid, Typography, Button, Stack } from "@suid/material";
import { blue, green } from "@suid/material/colors";
 
const xlSize = 2
const lgSize = 2
const mdSize = 2
const smSize = 3
const xsSize = 5
const buttonMargin = 1


interface HomePageIconProps {
    homePageIcon: JSXElement;
    //handleEvent: (event: MouseEvent) => void;
    type?: string;
    routeName: string;
    children: JSXElement;
}

const HomePageIcon = (
    props: HomePageIconProps) =>
{
    const [local] = splitProps(props, ['children'])
    //let iconColor = ((props.type == "candidate")) ? green[300] : blue[300];
    let iconColor = ((props.type == "candidate")) ? "seagreen" : "darkslategray";

    const navigate = useNavigate();

   //onClick={props.handleEvent}
    return (
        <>
           <Grid item xs={xsSize} sm={smSize} md={mdSize} lg={lgSize} xl={xlSize} sx={{ margin: buttonMargin }} >
            <Button
                sx={{
                    width: isExtraSmall() ? 90: 120,
                    height: isExtraSmall() ? 60: 100,
                    color: "honeydew",
                    bgcolor: iconColor,
                    
                }}
                variant="contained"
                
                onClick={() => navigate(props.routeName ? props.routeName : '/home')}
            >
                <Stack direction="column" alignItems="center" >
                    {props.homePageIcon}
                    <Typography variant="body2">{props.children}</Typography>
                </Stack>
            </Button>
            </Grid>
        </>
    );
}
export { HomePageIcon }
