
import { createSignal, createResource, For, Show, Suspense } from "solid-js";
import { Alert, Avatar, List, ListItem, ListItemText, ListItemAvatar, Typography, Chip, MenuItem, ListItemIcon, Stack, Divider, Dialog, DialogTitle, ListItemButton} from "@suid/material";
 
import { BusinessIcon } from "../icons"

import { UserFetchType } from "../../../types/userDataTypes"
import { MyThemeProvider } from "./AppBarToogleColorMode";
import { getText, GetTextUI } from '../SmallComponents/Translate'

const apiUrl = import.meta.env.VITE_APP_API_URL;
 
interface SelectOrganizationProps {
    showButton: boolean;
}

const SelectOrganization = (props: SelectOrganizationProps) => {

    const fetchUser = async (): Promise<UserFetchType> => {
        const response = await fetch(`${apiUrl}/security/user`, {
            method: "GET", credentials: "include", headers: { "Content-Type": "application/json" } as HeadersInit
        });
        if (!response.ok) {
            throw new Error('User response was not ok.');
        }
        const data = await response.json();
        return data;
    }

    const [user] = createResource<UserFetchType>(() => fetchUser());

    const [open, setOpen] = createSignal(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    let showCompanyName = 'Select Organization'
    //let showCompanyName = userContext.userStore.userCompanyName ? userContext.userStore.userCompanyName : 'Select Organization'
    const txtHeader = getText("menu", "selectorganization")
    const txtTest = getText("default", "test")
    const txtProduction = getText("default", "production")
    const txtRoleText = getText("menu", "roletext")
    const txtGrantedRole = getText("menu", "grantedrole")

    const mergeText = (role: string) => {
        if (!role) return 'role not defined';
        if (txtGrantedRole() === undefined || txtRoleText() === undefined) return role;
        return txtGrantedRole() + role + txtRoleText()
    }

    const handleClick = (id: string) => {
        alert("change to company: " + id)
    }

    return (
        <>
            {/* ********************** */}
            {/* Show button in menubar OR Listitem in list */}
            {/* ********************** */}
            <Show
                when={props.showButton}
                fallback={
                    <ListItemButton onClick={handleOpen}>
                        <ListItem >
                            <ListItemIcon>
                                <BusinessIcon />
                            </ListItemIcon>
                            <ListItemText>
                                {txtHeader()}
                            </ListItemText>

                        </ListItem>
                    </ListItemButton>
                }
            >
                <MenuItem onClick={handleOpen}  >
                    <ListItemIcon>
                        <BusinessIcon fontSize="small" />
                    </ListItemIcon>
                    {txtHeader()}
                </MenuItem>
            </Show>

            {open() && (
                <MyThemeProvider  >
                    <Dialog open={true} onBackdropClick={handleClose} >
                        <DialogTitle>
                            {" "}
                            <Typography variant="h4">{txtHeader()}</Typography>
                        </DialogTitle>
 

                            <Suspense fallback={
                                <Alert sx={{ px: 1, py: 1 }} severity="info">
                                    <GetTextUI formName={"default"} label={"loading"} />.
                                </Alert>
                            }>
                                <List >
                                    <For each={user()?.teams}>
                                        {(item, idx) => (
                                            <>
                                                <ListItem  >
                                                    <ListItemButton onClick={event => handleClick(item.companyId)}>
                                                        <ListItemAvatar>
                                                            <Avatar src={item.src}></Avatar>
                                                        </ListItemAvatar>
                                                        <ListItemText primary={item.companyName} secondary={mergeText(item.teamUserRole)} />


                                                        <Chip label={item.companyType === "PRD" ? txtProduction() : (item.companyType === "DEMO" ? "Demo" : txtTest())} variant="outlined" color={item.companyType === "PRD" ? "success" : "primary"} />

                                                    </ListItemButton>
                                                </ListItem>
                                                <Divider variant="inset" component="li" />
                                            </>
                                        )}
                                    </For>
                                </List>
                            </Suspense>
                    </Dialog>
                </MyThemeProvider>
            )
            }
        </>
    );
}

export { SelectOrganization }