import { createSignal, JSX, onMount, Show, splitProps } from "solid-js";
import { Box, Stack, Card, IconButton, Divider, List } from "@suid/material";
import { ShowHeading } from '../SmallComponents/SmallHeader'

import { getText, GetTextUI } from '../SmallComponents/Translate'

import { OpenListIcon, CloseListIcon } from '../icons';

interface CardWithMinimizeProps {
  header: string;
  formName?: string;
  formLabel?: string;
  size?: number;
  children?: JSX.Element;
  defaultOpen?: boolean;
  iconName?: string;
  type: "stack" | "list"
  direction?: "row" | "column"
  level?: 1 | 2;
}

const CardWithMinimize = (props: CardWithMinimizeProps) => {
  const [local] = splitProps(props, ['children'])
  const [listBoxIsOpen, setListBoxIsOpen] = createSignal(props.defaultOpen);
  const [cardColor, setCardColor] = createSignal("var( --find-bg-color-card)");
  const handleToogle = () => {
    setListBoxIsOpen(listBoxIsOpen() ? false : true);
  }

  onMount(() => {
    setListBoxIsOpen(props.defaultOpen ? true : false);
    setCardColor(props?.level === 1 ? "var( --find-bg-color-card)" : "var( --find-bg-color-card2)")
  })
  const [txtHeader, setTextHeader] = createSignal(props.header as string);
  
  if (props.formName && props.formLabel) {
    const gtext=getText(props.formName, props.formLabel) 
    const gtext2= props.size ? " (" + props.size + ")" : ""
    setTextHeader(gtext() + gtext2 )
  }
  return (
    <>
      <Card sx={{ margin: 1, display: 'flex', backgroundColor: cardColor() }}>

        <Stack sx={{ width: '100%', backgroundColor: cardColor() }} >
          <Box  onClick={handleToogle}>
            <Stack direction="row" justifyContent="space-between">
            <ShowHeading iconName={props.iconName} size="small" >{props.header}</ShowHeading>

            <Show
              when={listBoxIsOpen()}
              fallback={
                <IconButton size="small" onClick={handleToogle}>
                  <CloseListIcon />
                </IconButton>

              }
            >
              <IconButton size="small" onClick={handleToogle}>
                <OpenListIcon />
              </IconButton>

            </Show>
            </Stack>
          </Box>
          <Divider />
          <Box sx={{ display: listBoxIsOpen() ? 'block' : 'none' }} >
            <Show
            fallback={
              <Stack direction={props?.direction==="column" ? "column" : "row" } spacing={1}>

                {props.children}

              </Stack>

            }
              when={props.type === "list"}
            >
              <List sx={{ width: '100%' }}>

                {props.children}

              </List>
            </Show>
          </Box>
        </Stack >
      </Card>

    </>
  )
}
export { CardWithMinimize }