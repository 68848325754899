
import { createSelect, createOptions, createAsyncOptions } from "@thisbeyond/solid-select";
import {
  createResource,

  createUniqueId,
  createSignal,
  createEffect,
  mergeProps,
  splitProps,
  on,
  Show,
  For,
  ParentComponent,
  VoidComponent,
  Setter,
} from "solid-js";
import {
  Box,
  Alert,
  Menu,
  Chip,
  List,
  TextField,
  ListItemButton,
  ListItemText,
  ListItem,
  Paper,
  rgbToHex,
} from "@suid/material";

import { GetTextUI } from '../SmallComponents/Translate'
import { SelectChangeEvent } from "@suid/material/Select";
import createElementRef from "@suid/system/createElementRef";

import { CandidateCompetenciesFetchType } from "../../../types/candidateCVDataTypes"

import { Popper } from "@suid/material" //"./suid-popper";


const apiUrl = import.meta.env.VITE_APP_API_URL;


type SelectProps = Parameters<typeof createSelect>[0] & {
  id?: string;
  name?: any;
  label?: string;
  error?: string | string[];
  touched?: boolean;
  format?: (data: any, type: "option" | "value") => any;
  placeholder?: string;
  readonly?: boolean;
  loading?: boolean;
  loadingPlaceholder?: string;
  emptyPlaceholder?: string;
  onBlur?: (event: Event) => void;
};

const Select: VoidComponent<SelectProps> = (props) => {
  const [selectProps, local] = splitProps(
    mergeProps(
      {
        format: (data: any, type: "option" | "value") => data,
        placeholder: "Select...",
        readonly: typeof props.options !== "function",
        loading: false,
        loadingPlaceholder: "Loading...",
        emptyPlaceholder: "No options",
      },
      props
    ),
    [
      "options",
      "optionToValue",
      "isOptionDisabled",
      "multiple",
      "disabled",
      "onInput",
      "onChange",
    ]
  );
  const select = createSelect(selectProps);

  createEffect(
    on(
      () => local.initialValue,
      (value) => {
        if (value != null) select.setValue(value);
      }
    )
  );

  const removeValue = (index: number) => {
    const value = select.value();
    select.setValue([...value.slice(0, index), ...value.slice(index + 1)]);
  };

  const fieldRef = createElementRef();


  return (

    <Box width={400}   >
      <TextField
        ref={fieldRef}
        id={local.id}
        name={local.name}
        label={local.label}
        error={local.touched && local.error != null}
        disabled={select.disabled}
        size="small"
        fullWidth
        InputLabelProps={{
          shrink:
            select.isActive() || select.hasValue() || select.hasInputValue(),
          sx: { textTransform: "capitalize" },
        }}
        InputProps={{
          startAdornment: (
            <Show when={select.hasValue()}>
              <Show when={!select.multiple && !select.hasInputValue()}>
                <Box position="absolute" sx={{ pointerEvents: "none" }}>
                  {local.format(select.value(), "value")}
                </Box>
              </Show>
              <Show when={select.multiple}>
                <For each={select.value()}>
                  {(value, index) => (
                    <Chip
                      sx={{ m: "2px 4px 2px 0px" }}
                      size="small"
                      onMouseDown={select.onMouseDown}
                      onDelete={() => removeValue(index())}
                      label={local.format(value, "value")}
                    />
                  )}
                </For>
              </Show>
            </Show>
          ),
        }}
        sx={{
          "& .MuiInputBase-root": {
            flexWrap: "wrap",
            padding: "6px 6px 6px 14px",
            background: "inherit",
          },
          "& .MuiInputBase-input": {
            padding: "2.5px 0px",
            minWidth: 30,
            width: 0,
            flexGrow: 1,
            ...(select.isActive() ? {} : { caretColor: "transparent" }),
          },
        }}
        onFocusIn={select.onFocusIn}
        onFocusOut={select.onFocusOut}
        onBlur={local.onBlur}
        onClick={() => !select.disabled && select.toggleOpen()}
        onChange={(_, value) => {
          select.setInputValue(value);
        }}
        onKeyDown={select.onKeyDown}
        value={select.inputValue()}
        placeholder={
          !select.multiple && select.hasValue() ? "" : props.placeholder
        }
      />

      <Popper
        open={select.isOpen()}
        anchorEl={fieldRef.ref}

        placement="bottom"
        onMouseDown={select.onMouseDown}
      >
        <Paper

          sx={{
            zIndex: 8888,

            overflow: 'hidden',
            minWidth: fieldRef.ref.clientWidth,

          }}
        >

          <Menu
            autoFocus={false}
            anchorEl={fieldRef.ref}
            open={select.isOpen()}

          >
            <List sx={{ maxHeight: "40vh", overflow: "hidden", background: "inherit", }}>
              <Show
                when={!local.loading}
                fallback={
                  <OptionListPlaceholder>
                    {local.loadingPlaceholder}
                  </OptionListPlaceholder>
                }
              >
                <For
                  each={select.options()}
                  fallback={
                    <OptionListPlaceholder>
                      {local.emptyPlaceholder}
                    </OptionListPlaceholder>
                  }
                >
                  {(option) => (
                    <ListItemButton
                      onClick={() => select.pickOption(option)}
                      selected={select.isOptionFocused(option)}
                      disabled={select.isOptionDisabled(option)}
                    >
                      <ListItemText
                        disableTypography
                        sx={{
                          "& mark": {
                            textDecoration: "underline",
                            background: "transparent",
                          },
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {local.format(option, "option")}
                      </ListItemText>
                    </ListItemButton>
                  )}
                </For>
              </Show>
            </List>
          </Menu>

        </Paper>
      </Popper>

    </Box>
  );
};

const OptionListPlaceholder: ParentComponent = (props) => {
  return (
    <ListItem>
      <ListItemText>{props.children}</ListItemText>
    </ListItem>
  );
};


interface FieldSelectListProps {
  id: string;
  label: string;
  type: 'role' | 'technical' | 'personal' | 'method' | 'industry' | 'language' | 'joblist';
  saving: boolean;
  setLabel?: (value: string) => void;
}
 

const FieldSelectList = (props: FieldSelectListProps) => {


  const languageId = 'da'
  const idFetch = props.type==='role' ? 'roles' : ( props.type==='technical' ? 'technicals' : 'methods' );
/*
  const fetchDataList = async <K extends keyof CandidateCompetenciesFetchType>(
    id: string, parmString: K
  ): Promise<CandidateCompetenciesFetchType[K]> => {
    const response = await fetch(`${apiUrl}/candidate/competenceoptions?type=${id}&language=${languageId}`, {
      method: "GET", credentials: "include", headers: { "Content-Type": "application/json" } as HeadersInit
    });
    if (!response.ok) {
      throw new Error('Competence datalist was not ok.');
    }
    const data: CandidateCompetenciesFetchType = await response.json();
    return data[parmString];
  }
*/
 
  const fetchDataList = async (id: string): Promise<CandidateCompetenciesFetchType['roles']> => {
    const response = await fetch(`${apiUrl}/candidate/competenceoptions?type=${id}&language=${languageId}`, {
      method: "GET", credentials: "include", headers: { "Content-Type": "application/json" } as HeadersInit
    });
    if (!response.ok) {
      throw new Error('Competence datalist was not ok.');
    }
    const data = await response.json();
    return data.roles;
  }

  

  interface Value {
    id: string;
    name: string;
  }

  //const [initialData] = createResource<CandidateCompetenciesFetchType>(() => fetchDataList(props.type));
  const asyncProps = createAsyncOptions(fetchDataList as any) as {
    readonly options: any;
    readonly loading: boolean;
    onInput: Setter<string>;
    readonly: boolean;
  };
  const [values, setValues] = createSignal<Value[]>([]);
  const [selectedValues, setSelectedValues] = createSignal<Value[]>([]);

  const onChange = (selected: Value[]) => {
    setSelectedValues(selected);
    const lastValue = selected[selected.length - 1];
    if (lastValue && !values().includes(lastValue)) {
      setValues([...values(), lastValue]);
    }
  };

  const format = (item: any, type: any) => (item.translatedText);

  const createValue = (name: string): Value => {
    return { id: createUniqueId(), name };
  };
  /*
  const datasets: Record<string, Value[]> = {
    
    technical: [
      createValue("HR"),
      createValue("CRM"),
      createValue("SD"),
      createValue("MM"),
      createValue("LO"),
      createValue("FI"),
      createValue("CO"),
      createValue("Concur"),
    ], method: [
      createValue("Safe"),
      createValue("Prince 2"),
      createValue("Scrum")
    ], industry: [
      createValue("Automotive"),
      createValue("Defence"),
      createValue("Oil")
    ], role: [
      createValue("(All) All Roles"),
      createValue("Program Manager"),
      createValue("Project Manager"),
      createValue("Test Manager"),
      createValue("Application Manager"),
      createValue("Application Specialist"),
      createValue("Integration Manager"),
      createValue("Integration Specialist"),
    ]
  };
  */
  //const initialData = datasets[props.type || 'role'] || datasets.candidates;


  const updateCompetenceOptions = async (
    selectedValues: CandidateCompetenciesFetchType['roles'] // Replace `any` with the actual type of your `selectedValues`
  ): Promise<void> => {
    try {
      const response = await fetch(`${apiUrl}/candidate/updatecompetenceoptions`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          selectedValues // Assuming your API expects a key named 'selectedValues'. Change as needed.
        })
      });

      if (!response.ok) {
        throw new Error('Failed to update competence options.');
      }

      // Handle the response here, if needed
      const result = await response.json();
      console.log('Update successful:', result);

    } catch (error) {
      console.error('Error updating competence options:', error);
      // Handle any additional error logic here
    }
  };

  // Usage
  // Assuming `selectedValues` is a state or otherwise available
  // updateCompetenceOptions('someTypeId', 'someLanguageId', selectedValues());
  const [updateStatus, setUpdateStatus] = createSignal<string | null>(null);
  /*
    createEffect(() => {
      // Call the async function inside the effect and update state accordingly
      updateCompetenceOptions(selectedValues())
        .then(() => setUpdateStatus('Update successful'))
        .catch((error) => setUpdateStatus(`Error: ${error.message}`));
    }); 
  
  */


  return (
    <>
      <Select
        multiple
        id={props.id}
        label={props.label}
        placeholder=""

        isOptionDisabled={(option) => selectedValues().includes(option)}
        format={format}
        onChange={onChange}
        {...asyncProps}
      />
      <Show when={props.saving === true}>
        <Alert sx={{ px: 1, py: 1 }} severity="info">
          <GetTextUI formName={"default"} label={"loading"} />.


          Selected value: {JSON.stringify(selectedValues())} {updateStatus()}

        </Alert>
      </Show>
    </>
  );
}

export { FieldSelectList }

/*
import Box from "@suid/material/Box";
import Chip from "@suid/material/Chip";
import Stack from "@suid/material/Stack";
import FormControl from "@suid/material/FormControl"; 
import InputLabel from "@suid/material/InputLabel"; 
import MenuItem from "@suid/material/MenuItem";
import OutlinedInput from "@suid/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@suid/material/Select";
import { Theme, useTheme } from "@suid/material/styles";
import { createSignal } from "solid-js"; 

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      "max-height": `${ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP}px`,
      width: `${250}px`,
    },
  },
};

const technical = [
  "HR",
  "CRM",
  "SD",
  "MM",
  "LO",
  "FI",
  "CO",
  "Concur",
];

const methods = [
  "Safe",
  "Prince 2",
  "Scrum"
];
const industry = [
  "Automotive",
  "Defence",
  "Oil"
];

const roles = [
  "(All) All Roles",
  "Program Manager",
  "Project Manager",
  "Test Manager",
  "Application Manager",
  "Application Specialist",
  "Integration Manager",
  "Integration Specialist",
];

function getStyles(name: string, RoleName: readonly string[], theme: Theme) {
  return {
    "font-weight":
      RoleName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


interface FieldSelectListProps {
  id: string;
  label: string;
  type: 'role' | 'technical' | 'personal' | 'methods' | 'industry'
  setLabel?: (value: string) => void;
}


export default function FieldSelectList(props: FieldSelectListProps) {
  const theme = useTheme();
  const [roleName, setRoleName] = createSignal<string[]>([]);

// This function will return the appropriate list based on the type prop.
const getListBasedOnType = () => {
  switch (props.type) {
    case 'role': return roles;
    case 'technical': return technical;
    case 'methods': return methods;
    case 'industry': return industry;
    case 'personal': 
    default:
      return []; // Add personal list here if you have it, else keep it empty or return some default.
  }
};


  const handleChange = (event: SelectChangeEvent<string | string[]>) => {
    const {
      target: { value },
    } = event;

    setRoleName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div>

      <Stack direction="column" spacing={0.1}>
       
        <FormControl
        fullWidth 
        
        >
          
          <InputLabel id={props.id}>{props.label} </InputLabel>
          <Select
            labelId={props.id}
            id={props.id}
            multiple
            value={roleName()}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label={props.label} />}
            renderValue={(selected) => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                }}
              >
                {selected.map((value) => (
                  <Chip label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {getListBasedOnType().map((name) => (
              <MenuItem value={name} style={getStyles(name, roleName(), theme)}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </div>
  );
}
*/