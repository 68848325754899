
import { createMediaQuery } from "@solid-primitives/media"

// Default breakpoints

// Each breakpoint (a key) matches with a fixed screen width (a value):

// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 900px
// lg, large: 1200px
// xl, extra-large: 1536px


export const isExtraSmall = createMediaQuery("(max-width: 430px)");
export const isSmall = createMediaQuery("(max-width: 600px)");
export const isMedium = createMediaQuery("(max-width: 900px)");
export const isLarge = createMediaQuery("(min-width: 1200px)");
export const isExtraLarge = createMediaQuery("(min-width: 1900px)");
export const isMega = createMediaQuery("(min-width: 2400px)");
export const mobileMenu = isExtraSmall() ? true : false;
 