
import { createSignal, createEffect, onMount, Show } from 'solid-js';
import { useNavigate } from "@solidjs/router";

import {
    Button, Typography, Stack, Grid, Card, CardMedia, TextField,
    Divider, Dialog, DialogActions, DialogTitle, DialogContent, Box,
} from "@suid/material";

import { getURL } from '../../../utils/fetchAPI'
import toast from 'solid-toast';
import { getText } from '../SmallComponents/Translate'
import { showError } from '../SmallComponents/SmallShowError'
import { KeyIcon } from "../icons"

const apiUrl = import.meta.env.VITE_APP_API_URL;
interface ConfirmCodesProps {
    validationType: "account" | "email" | "mobile";
    validationMedia: "email" | "sms" | "app"
    verificatUser: string;

    open: boolean;
    handleOpen: () => void;
    handleClose: () => void;
}
const SecurityConfirmCodes = (props: ConfirmCodesProps) => {
    const navigate = useNavigate();
    const txtErrorExt = getText("confirmcode", "errorextension")
    const txtError = getText("default", "error", [{ name: 'during', value: txtErrorExt() as string }])

    const handleSignIn = () => {

        if (userName()?.length < 3)
            setUserName(props?.verificatUser)

        type TokenValidation = {
            status: string,
            redirect: string,
            error: string,
        }

        getURL(`${apiUrl}/security/signupConfirmCode`, 'POST', { email: userName(), token: confirmCode() })
            .then((fetchedData: TokenValidation) => {
                if (fetchedData.status === '200') {
                    toast.success("Your code got confirmed", { duration: 5000, });
                    setDisabled(!disabled());
                    //props.open = false;
                    props.handleClose();
                
                    navigate('/onboard')

                } else {
                    showError(txtError() as string, `${fetchedData.status}`);
                }
            }).catch((error) => {
                showError(txtError() as string, `${error.message}`);
            })
    };


    const setElementFocus = (elementId: string) => {
        const element = document?.getElementById(elementId);
        element?.focus();
    }

    const handleCodeChange = (index: number, value: string) => {
        // only allow input of length 1
        if (value.length > 1) return;
        value = value.toLocaleUpperCase();

        // Create a copy of the current code array
        let newCode = [...code()];

        // Update the value at the specific index
        newCode[index - 1] = value;

        // Update the state
        setCode(newCode);

        if (value.length === 1 && index < 6) {
            // Get the value of the signal, which will be the DOM element, and call the .focus() method on it
            // We get the next input element by calling textInputRef[index + 1]()
            // Get the next TextField's ref and focus it
            let idElement = `codeIdNumber${index + 1}`;

            setElementFocus(idElement)
        }
    }

    createEffect(() => {
        setConfirmCode(code()[0] + code()[1] + code()[2] + code()[3] + code()[4] + code()[5])
    })
    createEffect(() => {
        if (props.open) {
            setElementFocus("codeIdNumber1")
        }
    });

    const [messageText, setMessageText] = createSignal("")
    const [disabled, setDisabled] = createSignal(false);
    const [userName, setUserName] = createSignal(props?.verificatUser === undefined ? "" : props.verificatUser);
    const [confirmCode, setConfirmCode] = createSignal('');

    const [code, setCode] = createSignal(Array(6).fill(""));



    // if non of the props are set we assume it is an account creation we would like to validate with a code from an email
    /*
     props?.validationType === undefined ? props.validationType = "account" : props.validationType = props.validationType 
     props?.validationMedia === undefined ? props.validationMedia = "email" : props.validationMedia = props.validationMedia 
     
     const txtTo = getText("confirmcode", "to")
     const locName = props?.verificatUser === undefined ? "" : txtTo() + props.verificatUser
     const txtMediaApp = getText("confirmcode", "appmessage", [{ name: 'validationType', value: props.validationType }])
     const txtMediaEmail = getText("confirmcode", "emailmessage", [{ name: 'locName', value: $`locName` }])
     const txtMediaSMS = getText("confirmcode", "smsmessage")
 
     if (props.validationMedia === "email")
         setMessageText(txtMediaEmail() + props.validationType);
     else if (props.validationMedia === "sms")
         setMessageText(txtMediaSMS() + props.validationType);
     else
         setMessageText(txtMediaApp() as string);
 */
    const { validationType = "account", validationMedia = "email", verificatUser } = props;

    const txtTo = getText("confirmcode", "to");
    const locName = verificatUser ? `${txtTo()}${verificatUser}` : "";
    const txtMediaApp = getText("confirmcode", "appmessage", [{ name: 'validationType', value: validationType }]);
    const txtMediaEmail = getText("confirmcode", "emailmessage", [{ name: 'locName', value: locName }]);
    const txtMediaSMS = getText("confirmcode", "smsmessage");

    createEffect(() => {
        if (validationMedia === "email") {
            setMessageText(`${txtMediaEmail()}${validationType}`);
        } else if (validationMedia === "sms") {
            setMessageText(`${txtMediaSMS()}${validationType}`);
        } else {
            setMessageText(txtMediaApp() as string);
        }
    });

    const txtHeader = getText("confirmcode", "header")
    const txtSubmit = getText("confirmcode", "submit")
    const txtClose = getText("default", "close")

    return (
        <>
            {props.open && (
                <Dialog open={props.open} onBackdropClick={props.handleClose}>
                    <Box
                        component="form"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSignIn();
                        }}
                    >
                        <DialogTitle>
                            {" "}
                            <Typography variant="h4">{txtHeader()}</Typography>
                        </DialogTitle>
                        <DialogContent>

                            <Grid container spacing={2}>

                                <Grid item xs={2} md={4} container alignItems="center" justifyContent="center">

                                    <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 2 }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                                            <CardMedia
                                                component="img"
                                                sx={{ maxWidth: '100%', maxHeight: '100%' }}
                                                image="/assets/imgs/signup/08.png"
                                                alt="Live from space"
                                            />
                                        </Box>
                                    </Card>
                                </Grid>

                                <Grid item xs={10} md={8}  >

                                    <Stack direction="column" margin={1} spacing={2} alignItems="center"
                                        justifyContent="center">

                                        <Stack direction="row" margin={1} spacing={2} alignItems="center"
                                            justifyContent="center">

                                            {[1, 2, 3, 4, 5, 6].map((index) => (
                                                <Card >
                                                    <TextField fullWidth
                                                        id={`codeIdNumber${index}`}
                                                        required
                                                        inputProps={{ maxLength: 1 }}

                                                        onChange={(event) => {
                                                            const value = event.target.value;
                                                            handleCodeChange(index, value);
                                                        }}
                                                    />
                                                </Card>
                                            ))}


                                        </Stack>
                                        <Typography
                                            variant="body1"
                                        >
                                            {messageText()}

                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Divider />
                        </DialogContent>
                        <DialogActions>

                            <Stack direction="row" margin={1} spacing={2} alignItems="center">
                                <Button variant="outlined" onClick={props.handleClose}>{txtClose()}</Button>
                                <Show
                                    fallback={
                                        <Button disabled variant="contained" startIcon={<KeyIcon />} type="submit" >{txtSubmit()}</Button>

                                    }
                                    when={confirmCode().length === 6}
                                >
                                    <Button variant="contained" startIcon={<KeyIcon />} type="submit" >{txtSubmit()}</Button>
                                </Show>
                            </Stack>

                        </DialogActions>
                    </Box>
                </Dialog>
            )}
        </>
    );
} 

export { SecurityConfirmCodes }