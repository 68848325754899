
import { Accessor, Setter, createContext, useContext, onCleanup } from "solid-js";
import { createStore } from "solid-js/store";
import { createSignal } from "solid-js";

export interface UserStore {
  userId: string;
  userFirstName: string;
  userLastName: string;
  userCompanyId: string;
  userCompanyName: string;
  userAvatar: string;
  userLanguage: string;
  userCountry: string;
}

// const UserContext = createContext<{
//   userStore: Accessor<UserStore>;
//   setUserStore: Setter<UserStore>;
// }>();

// interface UserProps {
//   children?: any;
// }

// export function CreateUserContextProvider(props: UserProps) {
//   const initialData: UserStore = JSON.parse(localStorage.getItem('UserStore') || '{}');
//   const [userStore, setUserStore] = createSignal<UserStore>({
//     userId: initialData.userId || "",
//     userFirstName: initialData.userFirstName || "",
//     userLastName: initialData.userLastName || "",
//     userCompanyId: initialData.userCompanyId || "",
//     userCompanyName: initialData.userCompanyName || "",
//     userAvatar: initialData.userAvatar || "",
//     userLanguage: initialData.userLanguage || "",
//     userCountry: initialData.userCountry || "",
//   });

  const setUserStore = (userStore: UserStore) => {
    const storeData = JSON.stringify(userStore);
    
    //console.log(storeData);

    localStorage.setItem('UserStore', storeData);
    return userStore;
  };
  const getUserStore = () => {
    const initialData: UserStore = JSON.parse(localStorage.getItem('UserStore') || '{}');
 
    return initialData;
  }

  
  // return (
  //   <UserContext.Provider value={{ userStore, setUserStore }}>
  //     {props.children}
  //   </UserContext.Provider>
  // );
  
//}


// export function useUserContext() {
//   const context = useContext(UserContext);
//   if (!context) {
//     throw new Error("useUserContext must be used within a UserContextProvider");
//   }
//   return context;
// }





/*
VERSION 1

import { Accessor, Setter, createContext, useContext, JSX } from "solid-js";
import { createStore, SetStoreFunction } from "solid-js/store";
import { createSignal } from "solid-js";


export interface UserStore {
  userId: string;
  userFirstName: string;
  userLastName: string;
  userCompanyId: string;
  userCompanyName: string;
  userAvatar: string;
  userLanguage: string;
  userCountry: string;
}

const UserContext = createContext<{
  userStore: Accessor<UserStore>;
  setUserStore: Setter<UserStore>;
}>();

const UserContext = createContext<{
  userStore: Accessor<UserStore> 
  setUserStore: Setter<UserStore>;
}>();

interface userProps {
  children?: JSX.Element;
}

export function CreateUserContextProvider(props: userProps) {
  const [userStore, setUserStore] = createSignal<UserStore>({
    userId: "",
    userFirstName: "",
    userLastName: "",
    userCompanyId: "",
    userCompanyName: "",
    userAvatar: "",
    userLanguage: "",
    userCountry: "",
  });

  return (
    <UserContext.Provider value={{ userStore, setUserStore }}>
      {props.children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within a UserContextProvider");
  }
  return context;
}

*/


/*
VERSION 2

export interface UserStore {
    userId: string;
    userFirstName: string;
    userLastName: string;
    userCompanyId: string;
    userCompanyName: string;
    userAvatar: string;
    userLanguage: string;
    userCountry: string;
  }
  
  const UserContext = createContext<{
    userStore: UserStore;
    setUserStore: SetStoreFunction<UserStore>;
  }>();
  
  interface userProps {
    userId: string;
    userFirstName: string;
    userLastName: string;
    userCompanyId: string;
    userCompanyName: string;
    userAvatar: string;
    userLanguage: string;
    userCountry: string;
    children?: JSX.Element;
  }
  
  export function CreateUserContextProvider(props: userProps) {
    const {
      userId,
      userFirstName,
      userLastName,
      userCompanyId,
      userCompanyName,
      userAvatar,
      userLanguage,
      userCountry,
    } = props;
    const [userStore, setUserStore] = createStore<UserStore>({
      userId,
      userFirstName,
      userLastName,
      userCompanyId,
      userCompanyName,
      userAvatar,
      userLanguage,
      userCountry,
    });
  */

    export { getUserStore, setUserStore }