
import toast, { Toaster } from 'solid-toast';
import { JSX, splitProps } from "solid-js";

const showError = (messageLoc: string, status: string) => {
    toast.error(`${messageLoc ? messageLoc : ''} ${status ? status : ''}`, { duration: 5000 });
    console.error(`${messageLoc ? messageLoc : ''} ${status ? status : ''}`);
}

type ShowErrorUIProps = {
    message: string;
    status?: string
    children?: JSX.Element;
};

const ShowErrorUI = (props: ShowErrorUIProps) => {
    const [local] = splitProps(props, ['children'])
    console.error(`${props.message ? props.message : ''} ${props.status ? props.status : ''}`);
    const notify = () => toast(`${props.message ? props.message : ''} ${props.status ? props.status : ''}`);
    return (
        <>
            {props.children}
            {notify}
        </>
    );
}
export { showError, ShowErrorUI }