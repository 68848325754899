import { For, Show } from "solid-js";
import { Typography, Stack, Grid, Link } from "@suid/material";
import { A } from "@solidjs/router";
import { getText, GetTextUI } from '../SmallComponents/Translate'
import { isExtraSmall } from "../../../utils/sizeUtil"

import { ShowUserProfile } from '../SmallComponents/SmallUser';
//import { AvatarChipWithIcon } from '../SmallComponents/SmallChips';
import { FieldAddress } from "../SmallComponents/SmallFieldAddress";
import { concatenateAddress } from '../../../utils/concatenateAddress'
import { RateValueForTable } from '../SmallComponents/SmallRateValue';
import { getCountryFromCurrencyCode } from "../DataLists/DataCountryList";
import { CardWithMinimize } from '../AppMenuBar/CardList'
import { CompetenceListWithGrids } from '../SmallComponents/SmallChipsCompetence'
import { ShowSocialMedia } from '../SmallComponents/SmallSocialMedia';

import { CustomerJobContactsDataElementsType, CustomerCVSOMEDataElementsType } from "../../../types/customerJobDataTypes"
import { CandidateCompetenciesFetchType, CandidateCVCompetenceDataElementsType } from "../../../types/candidateCVDataTypes"
import { formatDateYearMonthByCountry } from "../../../utils/datetimeflag"

interface TypeTextElement {
    page: string
    label: string
    data?: string
    country?: string
    street?: string
    city?: string
    postalCode?: string
    value?: string
    currency?: string
    mobileNumber?: string
    email?: string
    candidateId?: string
    firstName?: string
    lastName?: string
    skip?: boolean
}

interface TypeCompetenciesElement {
    page: string
    label: string
    options: CandidateCompetenciesFetchType; //CandidateCompetenciesFetchType; // OptionType |
    showAddButton: boolean;
    skip?: boolean
}

interface TypeContactsElement {
    page: string
    label: string
    contacts: CustomerJobContactsDataElementsType; //CandidateCompetenciesFetchType; // OptionType |
    skip?: boolean
}
interface TypeSOMEsElement {
    page: string
    label: string
    SOMEs: CustomerCVSOMEDataElementsType; //CandidateCompetenciesFetchType; // OptionType |
    skip?: boolean
}


interface TypeRatesElement {
    page: string
    label: string
    flatRate: string
    onSiteRate: string
    remoteRate: string
    currency: string
    skip?: boolean
}
interface TypeDurationElement {
    page: string
    label: string
    jobEndDate: string
    jobStartDate: string
    skip?: boolean
}

interface TypeAllocationElement {

    units: "hours" | "days"
    unitsPer: "day" | "week" | "month" | "year"
    workRemote: string
    workOnsite: string
    skip?: boolean
}


const TextElement = (props: TypeTextElement) => {
    if (props.skip === true && (props.data === null || props.data === undefined || props.data.length === 0)) return;
    return (
        <>
            <Show when={props.data !== undefined}>
                <br />
                <Typography variant="body1"><GetTextUI formName={props.page} label={props.label} /> </Typography>
                <Typography variant="body2" color="text.secondary">{props.data} </Typography>
            </Show>
            <Show when={props.mobileNumber !== undefined}>
                <br />
                <Typography variant="body1"><GetTextUI formName={props.page} label={props.label} /> </Typography>
                <Link href={`tel:${props.mobileNumber}`}>{props.mobileNumber}</Link>
            </Show>
            <Show when={props.email !== undefined}>
                <br />
                <Typography variant="body1"><GetTextUI formName={props.page} label={props.label} /> </Typography>
                <Link href={`mailto:${props.email}`}>{props.email}</Link>
            </Show>
            <Show when={props.candidateId !== undefined}>
                <br />
                <Typography variant="body1"><GetTextUI formName={props.page} label={props.label} /> </Typography>
                <A href={`/viewcv/${props.candidateId}`}>{props.firstName} {props.lastName}</A>
            </Show>
        </>
    )
}

const AddressElement = (props: TypeTextElement) => {
    if (props.skip === true && (props.country === null || props.country === undefined || props.country.length === 0)) return;

    const txtOnsiteaddress = getText("jobpage", "onsiteaddress")
    return (
        <>
            <Show when={props.country} >
                <Stack direction="column"   >
                    <TextElement page="contact" label="address" data={""} />
                    <FieldAddress type="view"
                        id="adresse" label={txtOnsiteaddress()} startValue={concatenateAddress(props?.country as string, props?.postalCode as string, props?.city as string, props?.street as string)}
                    />
                </Stack>
            </Show>
        </>
    )
}

const ValueElement = (props: TypeTextElement) => {
    if (props.skip === true && (props.value === null || props.value === undefined || props.value.length === 0)) return;
    return (
        <>
            <Show when={props.value} >
                <Stack direction="row"  >
                    <RateValueForTable displayType="Normal" value={Number(props.value)} country={getCountryFromCurrencyCode(props.currency as string)} currency={props.currency as string} primaryRateModel={"hour"} />
                    <Typography variant="body2" fontSize="80%" color="text.secondary" >(<GetTextUI formName={props.page} label={props.label} />)<br /></Typography>
                </Stack>
            </Show>
        </>
    )
}

const ContactsElement = (props: TypeContactsElement) => {
    if (props.skip === true && (props.contacts === null || props.contacts === undefined)) return;
    const getUserName = (s1: string, s2: string, s3: string) => {
        return s1 + " " + s2 + ", " + s3
    }
    //                  
    //<AvatarChipWithIcon url={contact.src}>{contact.firstName} {contact.lastName}, {contact.contactRole}</AvatarChipWithIcon>
    return (
        <>
            <TextElement page={props.page} label={props.label} data={""} />
            <Stack direction="column" spacing={1} >
                <For each={props.contacts}>
                    {(contact) =>
                        <ShowUserProfile type="chip" avatarSrc={contact.src} userId={contact.id} userName={getUserName(contact.firstName, contact.lastName, contact.contactRole)} />

                    }
                </For>
            </Stack>
        </>
    )
}


const SocialMediasElement = (props: TypeSOMEsElement) => {
    if (props.skip === true && (props.SOMEs === null || props.SOMEs === undefined)) return;
    return (
        <>
            <Show when={props.SOMEs}>
                <br />
                <Stack direction="column" alignItems="left"  >
                    <TextElement page="jobpage" label="social" data={""} />
                    <Stack direction="row" alignItems="left" spacing={1}>
                        <ShowSocialMedia SoMes={props.SOMEs} />
                    </Stack>
                </Stack>
            </Show>
        </>
    )
}

const RatesElement = (props: TypeRatesElement) => {
    if (props.skip === true && (props.flatRate === null || props.flatRate === undefined)) return;
    return (
        <>
            <Show when={props.flatRate || props.onSiteRate || props.remoteRate} >
                <TextElement page={props.page} label={props.label} data={""} />
                <ValueElement page="jobpage" label="budgetflat" value={props.flatRate} currency={props.currency} />
                <ValueElement page="jobpage" label="budgetonsite" value={props.onSiteRate} currency={props.currency} />
                <ValueElement page="jobpage" label="budgetremote" value={props.remoteRate} currency={props.currency} />
            </Show>
        </>
    )
}

const DurationElement = (props: TypeDurationElement) => {
    if (props.skip === true && (props.jobStartDate === null || props.jobStartDate === undefined)) return;
    if (props.skip === true && (props.jobEndDate === null || props.jobEndDate === undefined)) return;
    return (
        <>
            <Show when={props.jobStartDate || props.jobEndDate} >
                <Typography variant="body1"><GetTextUI formName={props.page} label={props.label} /> </Typography>
                <Stack direction="row" spacing={1}>
                    <Typography variant="body2" color="text.secondary">
                        {formatDateYearMonthByCountry(props.jobStartDate)}<GetTextUI formName={"default"} label={"towithspaces"} />{formatDateYearMonthByCountry(props.jobEndDate)}
                    </Typography>
                </Stack>
            </Show>
        </>
    )
}


const AllocationElement = (props: TypeAllocationElement) => {
    if (props.skip === true && (props.workRemote === null || props.workRemote === undefined)) return;
    if (props.skip === true && (props.workOnsite === null || props.workOnsite === undefined)) return;
    return (
        <>
            <Show when={props.workRemote || props.workOnsite} >
                <Typography variant="body1"><GetTextUI formName="smallcomponent" label={props.units === "hours" ? "hours" : "days"} />/<GetTextUI formName={"smallcomponent"} label={props.unitsPer === "week" ? "week" : "month"} /></Typography>
                <Typography variant="body2" color="text.secondary"><GetTextUI formName={"smallcomponent"} label={"remote"} /> {props.workRemote ? props.workRemote : "0"} <GetTextUI formName={"smallcomponent"} label={"onsite"} /> {props.workOnsite} </Typography>
            </Show >
        </>
    )
}



const CompetenciesElements = (props: TypeCompetenciesElement) => {
    if (props.skip === true && (props.options === null || props.options === undefined)) return;

    const txtCompetencies = getText("jobpage", "requestedcompetencies")
    const txtLanguages = getText("default", "languages")
    const txtMethods = getText("default", "methods")
    const txtRoles = getText("default", "roles")
    const txtTechnical = getText("default", "technical")

    return (
        <>

            <Show when={props.options?.languages || props.options?.technicals || props.options?.methods || props.options?.roles}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CardWithMinimize level={1} header={txtCompetencies() as string} type="stack" defaultOpen={isExtraSmall() ? false : true}>
                            {/* List of  different types of competencies */}

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Stack direction="row" alignItems="center" spacing={2} margin={2}>

                                        <Typography variant="body1" color="text.secondary" ><GetTextUI formName={props.page} label={props.label} /> </Typography>

                                    </Stack>
                                </Grid>

                                <CompetenceListWithGrids showAddButton={props.showAddButton} type="language" direction="row" header={txtLanguages() as string} options={props.options?.languages} />
                                <CompetenceListWithGrids showAddButton={props.showAddButton} type="method" direction="row" header={txtMethods() as string} options={props.options?.methods} />
                                <CompetenceListWithGrids showAddButton={props.showAddButton} type="role" direction="row" header={txtRoles() as string} options={props.options?.roles} />
                                <CompetenceListWithGrids showAddButton={props.showAddButton} type="technical" direction="row" header={txtTechnical() as string} options={props.options?.technicals} />

                            </Grid>
                        </CardWithMinimize>

                    </Grid>
                </Grid >
            </Show>
        </>
    )
}
export { TextElement, ValueElement, AddressElement, CompetenciesElements, ContactsElement, RatesElement, DurationElement, AllocationElement, SocialMediasElement } 