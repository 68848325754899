//import type { mergeAttributes, Node } from '@tiptap/core';
import { Editor } from '@tiptap/core';
import BubbleMenu from '@tiptap/extension-bubble-menu';
import StarterKit from '@tiptap/starter-kit';

import type { JSX } from 'solid-js';

import toast from "solid-toast";
import { getURL } from '../../../utils/fetchAPI'
import { Show, createSignal, onCleanup, createEffect } from 'solid-js';
import { createEditorTransaction, createTiptapEditor } from 'solid-tiptap';
import { Toolbar, CircularProgress, Checkbox, RadioGroup, Radio, FormLabel, ListItemIcon, ListItemButton, ListItem, FormControlLabel, Switch, Chip, TextField, FormControl, InputLabel, Select, Menu, MenuItem, ListItemText, ToggleButtonGroup, ToggleButton, Dialog, DialogTitle, DialogContent, DialogActions, Avatar, Typography, Button, IconButton, Stack, Card, Alert, Popover, Grid, Divider, Box } from "@suid/material";
import { AIIcon, FormatQuoteIcon, FormatCodeIcon, FormatParagraphIcon, FormatItalicIcon, FormatBoldIcon, FormatStrikethroughIcon, FormatListBulletedIcon, FormatListNumberedIcon, MenuIcon } from "../icons"
import { CloseListIcon, TextDecreaseIcon, TextIncreaseIcon, SpellcheckIcon, ExecuteIcon, Looks1Icon, Looks2Icon, FormatUndoIcon } from '../icons';

import { getText } from '../SmallComponents/Translate'

const apiUrl = import.meta.env.VITE_APP_API_URL;

//import { Toggle, Toolbar } from 'terracotta';

type AIRequest = {
  status: string
  ok: boolean
  error: string
  textRequest: string
  textResult: string
}

const CONTENT = `
<h2>
Hi there,
</h2>
<p>
this is a <em>basic</em> example of <strong>tiptap</strong>. Sure, there are all kind of basic text styles you’d probably expect from a text editor. But wait until you see the lists:
</p>
<ul>
<li>
  That’s a bullet list with one …
</li>
<li>
  … or two list items.
</li>
</ul>
<p>
Isn’t that great? And all of that is editable. But wait, there’s more. Let’s try a code block:
</p>
<pre><code class="language-css">body {
  display: none;
}</code></pre>
<p>
I know, I know, this is impressive. It’s only the tip of the iceberg though. Give it a try and click a little bit around. Don’t forget to check the other examples too.
</p>
<blockquote>
Wow, that’s amazing. Good work, boy! 👏
<br />
— Mom
</blockquote>    
`;

/*
 
import {   onCleanup } from 'solid-js';
import { render } from 'solid-js/web';
import Component from './Component'; // Ensure Component is adapted for SolidJS and TypeScript

interface NodeAttributes {
  count: number;
}

interface HTMLAttributes {
  [key: string]: any;
}

interface NodeViewProps {
  node: any; // Replace 'any' with a more specific type based on your editor's Node type
  view: any; // Replace 'any' with a more specific type based on your editor's View type
  getPos: () => number;
  count: number;
}

// Define a custom node for your editor, assuming 'editor' is an instance of some editor that you're extending
export default function createReactComponentNode(editor: any): void { // Replace 'any' with your editor's type
  // Assuming your editor has a similar API for custom nodes
  editor.addNode('solidComponent', {
    name: 'solidComponent',
    group: 'block',
    atom: true,

    addAttributes(): { count: { default: number } } {
      return {
        count: {
          default: 0,
        },
      };
    },

    parseHTML(): { tag: string }[] {
      return [
        {
          tag: 'solid-component',
        },
      ];
    },

    renderHTML({ HTMLAttributes }: { HTMLAttributes: HTMLAttributes }): (string | HTMLAttributes)[] {
      return ['solid-component', { ...HTMLAttributes }];
    },

    addNodeView(): (node: any, view: any, getPos: () => number) => {
      dom: HTMLElement;
      update: (updatedNode: any) => boolean;
      destroy: () => void;
    } {
      return (node, view, getPos) => {
        const div = document.createElement('div');
        div.className = 'solid-component-wrapper';

        // Mount SolidJS component
        const dispose = mountSolidComponent(Component, div, { node, view, getPos, count: node.attrs.count });

        onCleanup(dispose);

        return {
          dom: div,
          update: (updatedNode) => {
            if (updatedNode.type === node.type) {
              // This line is assuming your component can handle its own updates based on props
              return true;
            }
            return false;
          },
          destroy: () => {
            dispose();
          },
        };
      };
    },
  });
}

// Helper function to mount a SolidJS component, adjusted for TypeScript
function mountSolidComponent(Component: Component<NodeViewProps>, container: HTMLElement, props: NodeViewProps): () => void {
  let dispose: () => void = () => {};
  render(() => (
    <Component {...props} />
  ), container);
  return dispose;
}
*/


function Separator() {
  return (
    <div class="flex items-center" aria-hidden="true">
      <div class="h-full border-l border-gray-300" />
    </div>
  );
}

interface ControlProps {
  class: string;
  editor: Editor;
  title: string;
  key: string;
  onChange: () => void;
  isActive?: (editor: Editor) => boolean;
  children: JSX.Element;
}

function Control(props: ControlProps): JSX.Element {
  const flag = createEditorTransaction(
    () => props.editor,
    instance => {
      if (props.isActive) {
        return props.isActive(instance);
      }
      return instance.isActive(props.key);
    },
  );

  return (
    <>
      <ToggleButton value="editor"> {props.title} </ToggleButton>


    </>
  );
}

interface ToolbarProps {
  editor: Editor | undefined;
}

function ToolbarContents(props: ToolbarProps): JSX.Element {
  return (
    <>
      <ToggleButton value="simple" onClick={() => props.editor?.chain().focus().setParagraph().run()}>  <FormatParagraphIcon /> </ToggleButton>
      <ToggleButton value="h1" onClick={() => props.editor?.chain().focus().setHeading({ level: 1 }).run()}>  H1 </ToggleButton>
      <ToggleButton value="h2" onClick={() => props.editor?.chain().focus().setHeading({ level: 2 }).run()}>  H2 </ToggleButton>
      <ToggleButton value="h3" onClick={() => props.editor?.chain().focus().setHeading({ level: 3 }).run()}>  H3 </ToggleButton>

      <ToggleButton value="bold" onClick={() => props.editor?.chain().focus().toggleBold().run()}>  <FormatBoldIcon /> </ToggleButton>
      <ToggleButton value="italic" onClick={() => props.editor?.chain().focus().toggleItalic().run()}>  <FormatItalicIcon /> </ToggleButton>
      <ToggleButton value="strike" onClick={() => props.editor?.chain().focus().toggleStrike().run()}>  <FormatStrikethroughIcon /> </ToggleButton>
      <ToggleButton value="bullet" onClick={() => props.editor?.chain().focus().toggleBulletList().run()}>  <FormatListBulletedIcon /> </ToggleButton>
      <ToggleButton value="numbered" onClick={() => props.editor?.chain().focus().toggleOrderedList().run()}>  <FormatListNumberedIcon /> </ToggleButton>
      <ToggleButton value="code" onClick={() => props.editor?.chain().focus().toggleCode().run()}>  <FormatCodeIcon /> </ToggleButton>
      <ToggleButton value="quote" onClick={() => props.editor?.chain().focus().toggleBlockquote().run()}>  <FormatQuoteIcon /> </ToggleButton>


    </>
  );
}

interface EditorDataProps {
  editorDocument: string;
  readonly?: boolean;
}

//export function App(): JSX.Element {
export const TxtEditor = (TxtEditorProps: EditorDataProps) => {
  let editor2: Editor | undefined;
  // Assume `props.content` is the new content you want to load into the editor.
  const [content, setContent] = createSignal(TxtEditorProps.editorDocument);


  const [subMenuAnchorEl, setSubMenuAnchorEl] = createSignal<Element | null>(null);
  const handleSubMenuOpen = (event: { currentTarget: Element }) => {
    setSubMenuAnchorEl(event.currentTarget);
  }
  const handleSubMenuClose = () => {
    setSubMenuAnchorEl(null);
  };
  // Initialize the editor
  createEffect(() => {
    editor2 = new Editor({
      // element: document.querySelector('#editor2')!,
      // extensions: [StarterKit],
      content: content(),

      element: container()!,
      extensions: [
        StarterKit,
        BubbleMenu.configure({
          element: menu()!,
        }),
      ],
      editorProps: {
        attributes: {
          class: 'p-8 focus:outline-none prose max-w-full',
        },
      },
      editable: !isReadonly(),
      //  content: TxtEditorProps.editorDocument,
    });

    // Cleanup on component unmount
    onCleanup(() => {
      editor2?.destroy();
    });
  });
  // Watch for changes in content and update the editor
  createEffect(() => {
    const currentContent = content();
    if (editor2 && currentContent) {
      // Update the editor's content
      editor2.commands.setContent(currentContent, false); // `false` to not emit an update event
    }
  });

  const [container, setContainer] = createSignal<HTMLDivElement>();
  const [menu, setMenu] = createSignal<HTMLDivElement>();
  const [isReadonly, setIsReadOnly] = createSignal(TxtEditorProps.readonly === true ? true : false);


  const [anchorMenuEl, setAnchorMenuEl] = createSignal<Element | null>(null);
  const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);
  const handleMenuOpen = (event: { currentTarget: Element }) => {
    setAnchorMenuEl(event.currentTarget);
  }
  const handlePopoverOpen = (event: Event) => {
    setAnchorEl(event.currentTarget as Element);
  };

  const handleMenuClose = () => {
    setAnchorMenuEl(null);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = () => Boolean(anchorEl());
  const [value, setValue] = createSignal<string | null>(TxtEditorProps.editorDocument);


  const [chatGPTmessage, setChatGPTMessage] = createSignal("");

  const handleAITextRequest = (command: string) => {
    try {
      // const aiRequest = `${apiUrl}/ai/aitextrequest?textRequest="${value()}"`
      const aiRequest = `${apiUrl}/ai/aitextrequest?textRequest="${command ? command + '...' + editor2?.getHTML() + '...' : editor2?.getHTML()}"`

      const txtAIError = getText("smallcomponent", "aierror")
      if (aiRequest) {


        getURL(aiRequest
          , 'GET', null)
          .then((fetchedData: AIRequest) => {

            if (fetchedData?.status === '200') {
              setChatGPTMessage(fetchedData?.textResult)
              setValue(fetchedData?.textResult)
              //TxtEditorProps.editorDocument = fetchedData?.textResult;
              setContent(fetchedData?.textResult)
              //            props.valueOnChange?.(fetchedData?.textResult);

              handlePopoverClose()
            } else {
              setChatGPTMessage(txtAIError() as string)
              handlePopoverClose()
            }
          }).catch((error) => {

            console.log("Error during handling of AI request: ", value(), ". Error: ", error)

            toast.error("Error during handling of AI request. Error: ", error)
            handlePopoverClose()
          });

      }
    } catch (error) {

      console.log("Error during handling of AI request: ", value(), ". Error: ", error)

      toast.error("Error during handling of AI request.")
    }

  }

  /*
    const editor = createTiptapEditor(() => ({
      element: container()!,
      extensions: [
        StarterKit,
        BubbleMenu.configure({
          element: menu()!,
        }),
      ],
      editorProps: {
        attributes: {
          class: 'p-8 focus:outline-none prose max-w-full',
        },
      },
      editable: !isReadonly(),
      content: TxtEditorProps.editorDocument,
    }));
    */
  /*
    const isFocused = useEditorIsFocused(() => props.editor);
     
        createEffect(() => {
          if (isHeading()) {
            // do something
          }
        });
        */
  const [templateGroupId, setTemplateGroupId] = createSignal('interview');
  return (
    <>
      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: "none", width: '100vw', height: '100vh' }} // Stretch the popover to cover the entire viewport
        open={open()}
        anchorReference="anchorPosition" // Use anchor position for positioning
        anchorPosition={{ left: window.innerWidth / 2, top: window.innerHeight / 2 }} // Center it


        anchorEl={anchorEl()}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box

          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
        >
          <CircularProgress color="inherit" />
        </Box>
      </Popover>
      <Card>

        <div id="editor2"></div>

        <div class="w-screen h-screen bg-gradient-to-bl from-sky-400 to-blue-500 flex items-center justify-center">
        
            <Stack spacing={2}>
            <Show when={isReadonly() === false}>
              <ToggleButtonGroup
                color="primary"
                value={"simple"}
                size="small"

                onChange={(event, newAlignment) => {
                  setTemplateGroupId("aiEditor");
                }}
              >

                <ToggleButton value="simple" onClick={() => editor2?.chain().focus().setParagraph().run()}>  <FormatParagraphIcon /> </ToggleButton>
                <ToggleButton value="h1" onClick={() => editor2?.chain().focus().setHeading({ level: 1 }).run()}>  H1 </ToggleButton>
                <ToggleButton value="h2" onClick={() => editor2?.chain().focus().setHeading({ level: 2 }).run()}>  H2 </ToggleButton>
                <ToggleButton value="h3" onClick={() => editor2?.chain().focus().setHeading({ level: 3 }).run()}>  H3 </ToggleButton>

                <ToggleButton value="bold" onClick={() => editor2?.chain().focus().toggleBold().run()}>  <FormatBoldIcon /> </ToggleButton>
                <ToggleButton value="italic" onClick={() => editor2?.chain().focus().toggleItalic().run()}>  <FormatItalicIcon /> </ToggleButton>
                <ToggleButton value="strike" onClick={() => editor2?.chain().focus().toggleStrike().run()}>  <FormatStrikethroughIcon /> </ToggleButton>
                <ToggleButton value="bullet" onClick={() => editor2?.chain().focus().toggleBulletList().run()}>  <FormatListBulletedIcon /> </ToggleButton>
                <ToggleButton value="numbered" onClick={() => editor2?.chain().focus().toggleOrderedList().run()}>  <FormatListNumberedIcon /> </ToggleButton>
                <ToggleButton value="code" onClick={() => editor2?.chain().focus().toggleCode().run()}>  <FormatCodeIcon /> </ToggleButton>
                <ToggleButton value="quote" onClick={() => editor2?.chain().focus().toggleBlockquote().run()}>  <FormatQuoteIcon /> </ToggleButton>

                <ToggleButton value="ai" onClick={(event) => { handlePopoverOpen(event); Boolean(anchorMenuEl()); handleAITextRequest("") }}>   <AIIcon />
                </ToggleButton>

                <Button variant="outlined" type="button"
                  color="inherit"
                  size="small"

                  startIcon={<AIIcon />}
                  endIcon={<CloseListIcon />}
                  aria-controls={anchorMenuEl() ? "long-menu-country1" : undefined}
                  aria-haspopup="true"
                  aria-expanded={anchorMenuEl() ? "true" : undefined}
                  onClick={(event) => handleMenuOpen(event)}>
                  <Menu
                    id="long-menu-country1"
                    anchorEl={anchorMenuEl()}
                    open={Boolean(anchorMenuEl())}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={(event) => { handlePopoverOpen(event); Boolean(anchorMenuEl()); handleAITextRequest("") }}>   <ListItemIcon>
                      <ExecuteIcon />
                    </ListItemIcon>Run text as prompt</MenuItem>
                    <MenuItem onClick={(event) => { handlePopoverOpen(event); Boolean(anchorMenuEl()); handleAITextRequest("Write text based on the keywords ") }}><ListItemIcon>
                      <ExecuteIcon />
                    </ListItemIcon>Write text based on keywords</MenuItem>
                    <MenuItem onClick={() => editor2?.commands.undo()}>   <ListItemIcon>
                      <FormatUndoIcon />
                    </ListItemIcon>Undo last AI prompt</MenuItem>
                    <Divider />
                    <MenuItem onClick={(event) => { handlePopoverOpen(event); Boolean(anchorMenuEl()); handleAITextRequest("Write into a formal tone ") }}>
                      <ListItemIcon>
                        <Looks1Icon />
                      </ListItemIcon>
                      Write it in a formal tone</MenuItem>
                    <MenuItem onClick={(event) => { handlePopoverOpen(event); Boolean(anchorMenuEl()); handleAITextRequest("Rewrite into an unformal tone ") }}>  <ListItemIcon>
                      <Looks2Icon />
                    </ListItemIcon>Write it in a unformal tone</MenuItem>

                    <Divider />
                    <MenuItem onClick={(event) => { handlePopoverOpen(event); Boolean(anchorMenuEl()); handleAITextRequest("Spellchek and optimize the text ") }}><ListItemIcon>

                      <SpellcheckIcon /></ListItemIcon>Spellcheck and optimize text</MenuItem>

                    <MenuItem onClick={(event) => { handlePopoverOpen(event); Boolean(anchorMenuEl()); handleAITextRequest("Shorten the text ") }}><ListItemIcon>
                      <TextDecreaseIcon />
                    </ListItemIcon>Shorten the text</MenuItem>
                    <MenuItem onClick={(event) => { handlePopoverOpen(event); Boolean(anchorMenuEl()); handleAITextRequest("Enhance the text ") }}><ListItemIcon>
                      <TextIncreaseIcon />
                    </ListItemIcon>Enhance the text</MenuItem>
                    <Divider />
                    <MenuItem>Templates
                      <Menu
                        anchorEl={subMenuAnchorEl()}
                        open={Boolean(subMenuAnchorEl())}
                        onClose={handleSubMenuClose}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                      >
                        <MenuItem>Submenu Option 1</MenuItem>
                        <MenuItem>Submenu Option 2</MenuItem>
                      </Menu>
                    </MenuItem>
                  </Menu>

                </Button>

              </ToggleButtonGroup>
            </Show>
            </Stack>
            <div
              class="h-[80vh] bg-white overflow-y-scroll rounded-lg"
              ref={setContainer}
            />

          
        </div>
      </Card>
    </>
  );
}

/*
<Toolbar
ref={setMenu}
class="dynamic-shadow bg-gradient-to-bl from-indigo-500 to-blue-600 text-white rounded-lg"
horizontal
>
<Show when={editor()} keyed>
  {instance => <ToolbarContents editor={instance} />}
</Show>
</Toolbar>
*/