import { createSignal, onMount, Show, splitProps, JSX } from "solid-js";
import { Typography, Stack, TextField, Select, MenuItem, ListItemIcon, ListItemText, Divider, IconButton, FormControl } from "@suid/material";

import { SelectChangeEvent } from "@suid/material/Select";

import { grey } from "@suid/material/colors";
import { LocationIcon, SaveIcon } from '../icons';
import toast, { Toaster } from 'solid-toast';
import { getText } from '../SmallComponents/Translate'


interface AddressType {
    address: string;
    suggested?: boolean;
}

interface AddressValueInputProps {
    id: string;
    startValue?: string;
    label?: string;
    width?: number;
    other?: string;
    country?: string;
    city?: string;
    street?: string;
    type: "edit" | "view"
  //  children: JSX.Element;
}
const addressList: readonly AddressType[] = [
    { address: 'Søvang 11, DK-2970 Hørsholm, Denmark', suggested: true },
    { address: 'Benslow Road, London', suggested: true },
];

const openGoogleMaps = (searchAddress: string) => {
    const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(searchAddress)}`;
    window.open(url, '_blank');
}

const FieldAddress = (props: AddressValueInputProps) => {
//    const [local] = splitProps(props, ['other'])

    const [open, setOpen] = createSignal(false);

    const [value, setValue] = createSignal(props?.startValue ? props.startValue : "");
//const [value, setValue] = createSignal(props?.children ? props.children : "");
    

    const [filteredOptions, setFilteredOptions] = createSignal<AddressType[]>([]);

    onMount(async () => {
        setValue(props?.startValue ? props.startValue : "");

        const suggestedCountries = addressList.filter(address => address.suggested);
        setFilteredOptions(suggestedCountries);
    })

    const handleAddressChange = (value: string) => {
        setValue(value);
    };

    const handleOpenGoogleMaps = () => {
        const locValue = value() as string
        openGoogleMaps(locValue)
    }

    const handleCurrencyCodeChange = (event: SelectChangeEvent) => {
        // Implement functionality
        const txtChange = getText("smallcomponent", "addaddress")

        toast(txtChange(), { duration: 5000, });

        const newValue = event.target.value;
    };

    const txtSave = getText("smallcomponent", "saveaddress")
    return (
        <>
            <Show
                when={props.type === "view"}
                fallback={

                    <>
                        <FormControl>
                            <TextField
                                id={props.id}
                                required
                                multiline
                                rows={4}
                                label={props.label}
                                variant="outlined"
                                size="small"
                                sx={{ textAlign: 'right' }}
                                {...props.width ? { width: props.width } : ''}
                                value={value()}
                                onChange={(event, value) => { handleCurrencyCodeChange(event) }}



                                InputProps={{

                                    startAdornment: (
                                        <IconButton onClick={handleOpenGoogleMaps} size="small"><LocationIcon sx={{ color: grey[500] }} /></IconButton>
                                    ),

                                    endAdornment:

                                        (
                                            <FormControl>
                                                <Select
                                                    open={open()}

                                                    onChange={handleCurrencyCodeChange}
                                                    onClose={() => setOpen(false)}
                                                    onOpen={() => setOpen(true)}
                                                    variant="standard"
                                                    disableUnderline
                                                    renderValue={(selected) => ` `}
                                                >
                                                    {filteredOptions().map((option, index) => (
                                                        <MenuItem
                                                            value={option.address}
                                                            onClick={() => {
                                                                handleAddressChange(option.address as string);
                                                                setOpen(false);
                                                            }}
                                                        >

                                                            <ListItemText>
                                                                {option.address}
                                                            </ListItemText>
                                                        </MenuItem>
                                                    ))}
                                                    <Divider />

                                                    <MenuItem value={"Others"}>
                                                        <ListItemIcon>
                                                            <SaveIcon />
                                                        </ListItemIcon>
                                                        <ListItemText>
                                                            {txtSave()}
                                                        </ListItemText>
                                                    </MenuItem>

                                                </Select>
                                            </FormControl>
                                        ),

                                }}
                            />
                        </FormControl>
                    </>
                }
            >
                <Stack direction="row" alignItems="center" spacing={0.5}>
                    <IconButton onClick={handleOpenGoogleMaps} size="small"><LocationIcon sx={{ color: grey[500] }} /></IconButton>

                    <Typography variant="body2" color="text.secondary">
                        {value()}
                    </Typography>

                </Stack>
            </Show>
        </>
    );
}

export { FieldAddress, openGoogleMaps };
