import { Show, createSignal, Switch as SolidSwitch, Match, } from "solid-js";
import { TableCell, Popover, Typography, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, Divider } from "@suid/material";
import { getText } from '../SmallComponents/Translate'

interface RateValueProps {
    value: number;
    country: string;
    currency: string;
    primaryRateModel: "hour" | "day";
    displayType?: "smallGreen" | "Normal" | "smallPrimary"
}

const RateValueForTable = (props: RateValueProps) => {

    const openPopover = () => Boolean(anchorEl());
    const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);

    const handlePopoverOpen = (event: { currentTarget: Element }) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const txtDailyRate = getText("smallcomponent", "dailyrate")
    const txtDailyRateEUR = getText("smallcomponent", "dailyrateeur")
    const txtDailyRateUSD = getText("smallcomponent", "dailyrateusd")

    const eurRate = 7.45;
    const usdRate = 8.95;
    const hoursPrDay = 8;

    if (!props.currency) return ("")

    interface CurrencyDisplay {
        language: string;
        currency: string;
        value: number;
        textAfter: string;
    }

    interface CurrencyType {
        country: string;
        language: string;
        currency: string;
        primaryValue: number;
        secondaryValue?: number;
        showSecondary: boolean;
        primaryRateModel: "hour" | "day";

    }


    const convertValue = (fromValue: number, currencyRate: number, units: number) => {
        return fromValue * currencyRate * units;
    }

    const CurrencyDisplay = (props: CurrencyDisplay) => {
        //  const formattedValue = new Intl.NumberFormat('en-US', {
        const formattedValue = new Intl.NumberFormat(props.language, {
            style: 'currency',
            currency: props.currency
        }).format(props.value);

        return <> {formattedValue} {props.textAfter} </>
    }

    const calcSecondaryRate = (primaryRateModel: string, value: number) => {
        if (primaryRateModel === "hour")
            return value * hoursPrDay
        else
            return value / hoursPrDay
    }

    const AddCurrency = (props: CurrencyType) => {
        const primaryText = props.primaryRateModel === "hour" ? "/hour" : "/day"
        const secondaryText = props.primaryRateModel === "hour" ? "/day" : "/hour"
        const secondaryValue = calcSecondaryRate(props.primaryRateModel, props.primaryValue);

        return (
            <>
                <ListItem>
                    <ListItemIcon>
                        <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${props.country.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${props.country.toLowerCase()}.png 2x`}
                            alt=""
                        />
                    </ListItemIcon>
                    <ListItemText primary={
                        <CurrencyDisplay language={props.language} currency={props.currency} value={props.primaryValue} textAfter={primaryText} />

                    } />
                    <Show
                        when={props.showSecondary}
                    >
                        <ListItemText secondary={
                            <CurrencyDisplay language={props.language} currency={props.currency} value={secondaryValue} textAfter={secondaryText} />
                        } />
                    </Show>
                </ListItem >
            </>
        )
    }

    return (
        <>
            <SolidSwitch   >
                <Match when={props.displayType === "smallGreen"} >
                    <Typography variant="body2" color="text.secondary" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} gutterBottom align="center">
                        <AddCurrency primaryValue={props.value} primaryRateModel={props.primaryRateModel} language={'en-US'} country={props.country} currency={props.currency} showSecondary={false} />
                    </Typography>
                </Match>
                <Match when={props.displayType === "Normal"} >
                    <Typography sx={{ px: 1 }} fontSize="80%" variant="body2" color="text.primary" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} gutterBottom align="left">

                        <CurrencyDisplay language={'en-US'} currency={props.currency} value={props.value} textAfter={""} />
                    </Typography>
                </Match>
                <Match when={props.displayType === "smallPrimary"} >
                    <Typography sx={{ px: 1 }} fontSize="80%" variant="body2" color="text.primary" onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} gutterBottom align="left">

                        <CurrencyDisplay language={'en-US'} currency={props.currency} value={props.value} textAfter={""} />
                    </Typography>
                </Match>
            </SolidSwitch>

            <Popover
                id="mouse-over-popover" sx={{ pointerEvents: "none" }} open={openPopover()}
                anchorEl={anchorEl()}
                anchorOrigin={{ vertical: "bottom", horizontal: "left", }}
                transformOrigin={{ vertical: "top", horizontal: "left", }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography margin={2} variant="h4" align="center" gutterBottom>Rate Conversion  </Typography>
                <Divider variant="middle" />

                {/* Day rate*/}
                <AddCurrency primaryValue={props.value} primaryRateModel={props.primaryRateModel} language={'en-US'} country={props.country} currency={props.currency} showSecondary={true} secondaryValue={props.value / hoursPrDay} />
                {/* Day rate*/}
                <AddCurrency primaryValue={props.value / eurRate} primaryRateModel={props.primaryRateModel} language={'en-US'} country={"EU"} currency={"EUR"} showSecondary={true} secondaryValue={props.value / eurRate / hoursPrDay} />
                {/* Hour rate*/}
                <AddCurrency primaryValue={props.value / usdRate} primaryRateModel={props.primaryRateModel} language={'en-US'} country={"US"} currency={"USD"} showSecondary={true} secondaryValue={props.value / usdRate / hoursPrDay} />

                <Divider variant="middle" />
                <Typography margin={2} variant="body2" gutterBottom align="center">The exchange rates are delivered from currencylayer and <br />
                    are yesterdays avg. rate <CurrencyDisplay language={'en-US'} currency={"EUR"} value={eurRate} textAfter={""} /> & <CurrencyDisplay language={'en-US'} currency={"USD"} value={usdRate} textAfter={""} />
                    .</Typography>

                <Typography margin={2} variant="body2" gutterBottom align="center">8 hours a day are used to convert between day and hourly rate.</Typography>

            </Popover >
        </>
    );
}

export { RateValueForTable }