
import { IconButton, Chip, Alert, Avatar, Button, Typography, Link } from "@suid/material";

import { createSignal, createResource, Show, For, Suspense } from "solid-js";
import { Divider, Stack, Dialog, DialogActions, DialogTitle, DialogContent } from "@suid/material";
import { TextElement } from '../SmallComponents/SmallTextElements';

import { isExtraSmall, isSmall } from "../../../utils/sizeUtil"

import { UserInfoFetchType } from "../../../types/userDataTypes"
import { CloseIcon } from '../icons';

import { getText, GetTextUI } from './Translate'

const apiUrl = import.meta.env.VITE_APP_API_URL;


interface DialogHeaderProps {
    userName: string;
    userId: string;
    avatarSrc?: string;
    type?: string;
}

const ShowUserProfile = (props: DialogHeaderProps) => {
   
    const [open, setOpen] = createSignal(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [userId, setUserId] = createSignal(props.userId);

    const fetchUser = async (userId: string): Promise<UserInfoFetchType> => {
        const response = await fetch(`${apiUrl}/security/userinfo/${userId}`, {
            method: "GET", credentials: "include", headers: { "Content-Type": "application/json" } as HeadersInit
        });
        if (!response.ok) {
            throw new Error('User response was not ok.');
        }
        const data = await response.json();
        return data;
    }

    const [user] = createResource<UserInfoFetchType>(() => fetchUser(userId()));

    const [size, setSize] = createSignal({ dialogMargin: 2, dialogSpacing: 2, buttonSize: 200 })
    if (isExtraSmall())
        setSize({ dialogMargin: 1, dialogSpacing: 1, buttonSize: 40 });

    const txtClose = getText("default", "close")

    return (
        <>
            <Show when={props.type === "chip"}
                fallback={
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Avatar src={props.avatarSrc}></Avatar> <Link onClick={handleOpen}>{props.userName}</Link>
                    </Stack>
                }
            >
                <Chip
                    avatar={<Avatar src={props.avatarSrc} />}
                    label={props.userName}
                    variant="outlined"
                    size="small"
                    sx={{ cursor: 'pointer' }}
                    onClick={handleOpen}
                />
            </Show>
            {open() && (
                <Dialog open={true} >
                    <DialogTitle>
                        <Stack direction="row" spacing={size().dialogSpacing} >
                            <IconButton onClick={handleClose} > <CloseIcon /> </IconButton>
                            <Typography variant="h4">{props.userName}</Typography>
                        </Stack>
                    </DialogTitle>
                    <DialogContent>
                        <Stack margin={2} spacing={isExtraSmall() || isSmall() ? 1 : 5} direction={isExtraSmall() || isSmall() ? "column" : "row"}>
                            <Suspense fallback={
                                <Alert sx={{ px: 1, py: 1 }} severity="info">
                                    <GetTextUI formName={"default"} label={"loading"} />.
                                </Alert>
                            }>
                                <For each={user()?.user}>
                                    {(item, idx) => (
                                        <>
                                            <Stack alignItems={isExtraSmall() || isSmall() ? "center" : "right"} >
                                                <Avatar sx={{ width: 120, height: 120 }} src={item.src} ></Avatar>
                                            </Stack>
                                            <Stack margin={isExtraSmall() || isSmall() ? 0 : 2} spacing={isExtraSmall() || isSmall() ? 0 : 1}>
                                                <TextElement page="contact" label="firstname" data={item.firstName} />
                                                <TextElement page="contact" label="lastname" data={item.lastName} />
                                                <TextElement page="contact" label="country" data={item.countryCode2Chars} />
                                                <TextElement page="contact" label="country" data={item.languageCode2Chars} />
                                            </Stack>
                                            <Stack margin={isExtraSmall() || isSmall() ? 0 : 2} spacing={isExtraSmall() || isSmall() ? 0 : 1}>
                                                <TextElement page="contact" label="mobilenumber" mobileNumber={item.mobileNumber} />
                                                <TextElement page="contact" label="email" email={item.email} />
                                                <TextElement page="quicklauncher" label="cv" candidateId={item.candidateId} firstName={item.firstName} lastName={item.lastName} />
                                            </Stack>
                                            <Stack margin={2} spacing={2}>
                                                <Divider />
                                            </Stack>
                                        </>
                                    )}
                                </For>
                            </Suspense>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="info" onClick={handleClose}>{txtClose() as string}</Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
export { ShowUserProfile }