import { Suspense, createResource, createSignal, For, Show, Switch as SolidSwitch, Match, JSX, } from "solid-js";
import { createStore, } from "solid-js/store";
import { useNavigate, useParams } from "@solidjs/router";
import { WFStatusFooter } from '../SmallComponents/SmallShowWFStatusFooter';
import { DateInput } from '../SmallComponents/SmallFieldDate'
import { MenuIcon } from "../icons"
import { CardWithMinimize } from '../AppMenuBar/CardList'

import { TxtEditor } from '../SmallComponents/SmallEditor';
import { formatDateByCountryLong, formatDateByCountry, formatTimeByCountry, isInvoiceDueDatePassed } from "../../../utils/datetimeflag"

import { ShowUserProfile } from '../SmallComponents/SmallUser';
import { ShowHeading } from '../SmallComponents/SmallHeader'
import { isExtraSmall } from "../../../utils/sizeUtil"
import { InterviewPreviewFetchType, InterviewPreviewType } from "../../..//types/workflowDataTypes"

import { AvatarChipWithIcon, WFChip } from '../SmallComponents/SmallChips';

import {
    Alert, Menu, MenuItem, Switch, Button, Typography, Grid, Card, Stack, Divider, MenuList, Link, FormControlLabel,
    TextField, DialogActions, Box, Dialog, DialogTitle, DialogContent
} from "@suid/material";
import { getText, GetTextUI } from '../SmallComponents/Translate'


const apiUrl = import.meta.env.VITE_APP_API_URL;


const fetchInterviewMessage = async (interviewId: string | null): Promise<InterviewPreviewFetchType> => {
    try {
        const response = await fetch(`${apiUrl}/job/customerinterviewmessage/${interviewId}`, {
            method: "GET", credentials: "include", headers: { "Content-Type": "application/json" } as HeadersInit
        });
        if (!response.ok) {
            throw new Error('Interview message was not ok.');
        }
        const data = await response.json();


        return data;
    } catch (error) {
        console.error("Error fetching interview message:", error);
        throw error; // Rethrow to ensure the error is propagated to the resource.
    }
}

const ShowMenu = () => {
    const [anchorEl, setAnchorEl] = createSignal<HTMLElement | null>(null);
    const [selectedMenuIndex, setSelectedMenuIndex] = createSignal<number>(1);

    const openMenu = () => !!anchorEl();

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleClickMenuListItem = (event: MouseEvent) => {
        setAnchorEl(event.currentTarget as HTMLElement);
        openMenu()
    };

    const menuItems = [

        { text: "Local action menu 1", switch: true, checked: true, },

        { text: "Local action menu 2", disabled: true, },

    ];

    return (
        <>
            <Button size="small" color="primary" variant="text" onClick={handleClickMenuListItem}>  <MenuIcon /> </Button>
            {openMenu() && (

                <Menu anchorEl={anchorEl()} open={openMenu()} onClose={handleMenuClose}>
                    <MenuList >

                        {menuItems.map((item, index) => (
                            <MenuItem value={index} disabled={item.disabled}>
                                {item.switch && <Switch id="autoconfirmedEnabled" defaultChecked={item.checked} />}
                                {item.text}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>

            )}
        </>

    );
}


interface CustomerWFInterviewComponentProps {
    header: string;
    type: string; // "contract" | "proposal" | "interview" | "matching" | "drop" | "greylist";
}


/*
const previewProps: InterviewPreviewType = {
    internalComment: "He is realy good. Pls. fix start date.",
    topText: "<b>Hi Michael,</b><br/> We are delighted to schedule an interview with you.",
    bottomText: "<br />If you require any further assistance, please do not hesitate to let us know.<br /><b>Best regards,</b><br/><br/>Lion Messia",
    job: {
        jobId: "23",
        jobName: "Project Manager, SAP HR Upgrade",
        jobUrl: "/customerjob/23"
    },
    interviewDate: "10/2/22 9:33AM",
    interviewers: [
        {
            userId: "1",
            userName: "Tany Smidth",
            avatarUrl: "/assets/imgs/avatar2.jpg"
        },
        {
            userId: "2",
            userName: "Jim Nelson",
            avatarUrl: "/assets/imgs/avatar3.jpg"
        }
    ],
    meetingType: "MS Teams",
    meetingUrl: "none"
}
*/



const GetInterviewPreviewText = () => {

    const params = useParams()
    //const fetchId = params.id ? params.id : "0"
    const fetchId = params.jobRoleAssignmentId ? params.jobRoleAssignmentId : "0"

    const [previewProps] = createResource<InterviewPreviewFetchType>(() => fetchInterviewMessage(fetchId));
    /*
        if (previewProps.loading) {
            return <div>Loading (previewProps.loading)...</div>;
        }
    
        if (previewProps.error) {
            return <div>Error: {previewProps.error.message}</div>;
        }
        */

    const getDateForChip = (s1: string) => {
        return formatDateByCountry(s1) + " " + formatTimeByCountry(s1)
    }



    const politeStart = getText("workflowpage", "politestart")

    return (
        <>

            <Show
                when={previewProps()?.internalComment}
            >
                <CardWithMinimize header={"Internal Comment" as string} type="list" defaultOpen={true} >
                    <Typography sx={{ px: 2 }} variant='body1'> {previewProps()?.internalComment}</Typography >
                </CardWithMinimize>
            </Show>

            <CardWithMinimize header={"Message" as string} type="list" defaultOpen={true} >

                <Stack direction="column" margin={1} spacing={2}>


                    <Typography color='text.secondary' p={0.5} >

                        <Grid container spacing={2}>


                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TxtEditor readonly={true} editorDocument={"<b>" + politeStart() + "</b>" + previewProps()?.firstName + " " + previewProps()?.lastName + ",</br>" + previewProps()?.topText + "</br>" + previewProps()?.bottomText} />
                            </Grid>


                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Typography variant='body1'>

                                    <b> <GetTextUI formName="default" label="interview" /></b>
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Stack margin={2} >
                                    <Card>
                                        <Stack margin={2} >

                                                <Typography variant='body1' >
                                                    <GetTextUI formName="jobpage" label="jobrole" />
                                                </Typography>
                                                <Link href={"/customerjobroleview/" + previewProps()?.job.jobId || "#"} target='none'>{previewProps()?.job.jobName}</Link>
<br />
                                           
                                                <Typography variant='body1' >
                                                    <GetTextUI formName="interviewcomponent" label="date" />
                                                </Typography>
                                                <WFChip type="customer" wfCompleted={false} category="schedule" >{getDateForChip(previewProps()?.interviewDate as string)}</WFChip>
                                                <br />

                                                <Typography variant='body1' >
                                                    <GetTextUI formName="interviewcomponent" label="interviewby" />
                                                </Typography>
                                            
                                                <Stack spacing={1} direction="row">
                                                    <For each={previewProps()?.interviewers}>
                                                        {(interviewer) => (
                                                            <>
                                                                <ShowUserProfile type="chip" avatarSrc={interviewer.avatarUrl} userId={interviewer.userId} userName={interviewer.userName} />

                                                            </>
                                                        )}

                                                    </For>
                                                </Stack>
                                                <br />

                                         
                                                <Typography variant='body1' >

                                                    <GetTextUI formName="workflowpage" label="meetingtype" />
                                                </Typography>
                                             
                                                <Link href={previewProps()?.meetingUrl || "#"} target='none'>{previewProps()?.meetingType}</Link>
                                           
                                        </Stack>
                                    </Card>
                                </Stack>
                            </Grid>

                        </Grid>

                    </Typography >

                </Stack>

            </CardWithMinimize>
        </>
    )
}


const CustomerWFInterviewComponent = (props: CustomerWFInterviewComponentProps) => {
    const [open, setOpen] = createSignal(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);



    const [state, setState] = createStore({
        rateModel: 'Simple',
        expenseModel: 'AllInclusive',
        fromDate: '2023-05-01',
        toDate: '2023-05-15',
        calendarDays: 0,
        allocation: '8',
        allocationOnSite: '0',
        allocationRemote: '0',
        allocationUnits: 'Hours' || 'Days',
        allocationUnitsPr: 'Day' || 'Week' || 'Month',
        currency: 'EUR',
        ratePer: 'Hour' || 'Day' || 'Week' || 'Month',
        rateValue: 0,
        remoteRateValue: 0,
        onSiteRateValue: 0,
        expenseValue: 0,
        remoteExpenseValue: 0,
        onSiteExpenseValue: 0,
        totalHours: 0,
        totalOnSiteHours: 0,
        totalRemoteHours: 0,

        sumRates: '0.00',
        sumExpenses: '0.00',
        sumTotal: '0.00',
    });

    const handleChange = (key: keyof typeof state, newValue: any) => {
        setState(key, newValue);
    };

    const [textMessage, setTextMessage] = createSignal("");

    const formatNumberBasedOnLocale = (numberString: string): string => {
        const number = Number(numberString.replace(/,/g, ''));
        const formatter = new Intl.NumberFormat(navigator.language);
        return formatter.format(number);
    }
    const txtSendInterview = getText("workflowpage", "sendinterview");

    const txtSend = getText("workflowpage", "send");
    const txtInternalMessage = getText("workflowpage", "internalmessage");
    const txtExternalMessage = getText("workflowpage", "externalmessage");
    const txtSaveDraft = getText("workflowpage", "savedraft");
    const txtOptions = getText("workflowpage", "options");
    const txtMeetingDate = getText("workflowpage", "meetingdate");
    const txtMeetingTime = getText("workflowpage", "meetingtime");
    const txtAddParticipant = getText("workflowpage", "addparticipant");
    const txtInviteParticipant = getText("workflowpage", "inviteparticipant");
    const txtMeetingUrl = getText("workflowpage", "meetingurl");
    const txtMeetingType = getText("workflowpage", "meetingtype");
    const txtUpdateSchedule = getText("workflowpage", "updateschedule");

    const getButtonActionText = (type: string) => {
        if (type === "interview")
            return txtSendInterview();

        else
            return txtSend()
    }

    return (

        <>
            {/* ********************** */}
            {/* Show button in wf bar */}
            {/* ********************** */}

            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

                width: '100%',

            }}>
                <Box sx={{ margin: 2, width: 1000, bgcolor: 'background.default'[200] }}>
                    <Card>

                        <Stack direction="column" alignItems="top" justifyContent="space-between" sx={{ display: 'flex' }} >
                            {/* Addition navigation options  */}
                            <Box sx={{ margin: 1, display: 'flex', }} >

                                <ShowMenu />
                                <ShowHeading >{getButtonActionText(props.type)} to {props.header}</ShowHeading>
                            </Box>


                        </Stack>


                        <Divider />

                        <Card sx={{ margin: 1, display: 'flex' }}>


                            <Grid container spacing={2}>

                                <Grid item xs={12} md={6}  >



                                    {/* Contract Period*/}
                                    <CardWithMinimize header={txtInternalMessage() as string} type="list" >

                                        <TextField
                                            required
                                            fullWidth={true}
                                            id="textarea-input"
                                            multiline
                                            rows={6}
                                            autoComplete="text"
                                            variant="outlined"
                                            size="small"
                                        />
                                    </CardWithMinimize>

                                    <CardWithMinimize header={txtOptions() as string} type="list" defaultOpen={true}>
                                        <Stack direction="column" spacing={2} alignItems="center" margin={2}>


                                            <DateInput
                                                value={state.fromDate}
                                                defaultValue={state.fromDate}
                                                id="dateidto"
                                                label={txtMeetingDate()}
                                                onChange={(newValue) => handleChange('fromDate', newValue)}
                                            />

                                            <DateInput
                                                value={state.fromDate}
                                                defaultValue={state.fromDate}
                                                id="timeid"
                                                label={txtMeetingTime()}
                                                onChange={(newValue) => handleChange('fromDate', newValue)}
                                            />

                                            <DateInput
                                                value={state.fromDate}
                                                defaultValue={state.fromDate}
                                                id="timeid"
                                                label={txtAddParticipant()}
                                                onChange={(newValue) => handleChange('fromDate', newValue)}
                                            />

                                            <DateInput
                                                value={state.fromDate}
                                                defaultValue={state.fromDate}
                                                id="timeid"
                                                label={txtInviteParticipant()}
                                                onChange={(newValue) => handleChange('fromDate', newValue)}
                                            />

                                            <DateInput
                                                value={state.fromDate}
                                                defaultValue={state.fromDate}
                                                id="timeid"
                                                label={txtMeetingType()}
                                                onChange={(newValue) => handleChange('fromDate', newValue)}
                                            />

                                            <DateInput
                                                value={state.fromDate}
                                                defaultValue={state.fromDate}
                                                id="timeid"
                                                label={txtMeetingUrl()}
                                                onChange={(newValue) => handleChange('fromDate', newValue)}
                                            />

                                            <FormControlLabel
                                                control={
                                                    <Switch id="autoconfirmedEnabled" defaultChecked

                                                    />
                                                }
                                                label={txtUpdateSchedule()}
                                                sx={{ px: 2 }}
                                            />


                                        </Stack>
                                    </CardWithMinimize>


                                </Grid>
                                <Grid item xs={12} md={6} >


                                    {/* ***************************** */}
                                    {/* Preview message section       */}
                                    {/* ***************************** */}

                                    <Card sx={{ backgroundColor: 'background.default'[50], flexGrow: 1, margin: 1 }} >
                                        <Stack direction="column" margin={1} spacing={2}>

                                            {/* message*/}
                                            <CardWithMinimize header={txtExternalMessage() as string} type="list" >
                                                <TextField
                                                    required
                                                    fullWidth={true}
                                                    sx={{ width: "100%" }}
                                                    id="textarea-input"
                                                    multiline
                                                    rows={4}
                                                    autoComplete="text"
                                                    variant="outlined"
                                                    size="small"
                                                    value={textMessage()}
                                                    onChange={(event) => {
                                                        setTextMessage(event.target.value);

                                                    }}
                                                />
                                            </CardWithMinimize>

                                            {/*  Typography color="text.secondary" innerHTML={previewTextMessage().replace(/\n/g, '<br>')} > */}


                                            <GetInterviewPreviewText />

                                        </Stack>

                                    </Card>
                                </Grid>
                            </Grid>


                        </Card>

                        <DialogActions>

                            <Button variant="outlined" >
                                {txtSaveDraft()}
                            </Button>
                            <Button variant="contained">
                                {getButtonActionText(props.type)}
                            </Button>


                        </DialogActions>
                    </Card>
                </Box>
            </Box>
        </>
    );
}

interface PreviewInterview {
    open: boolean;
    header: string;
    handleClose: () => void;
    type: "customer" | "candidate"
}

const ShowPreviewInterview = (props: PreviewInterview) => {
    const txtInvite = getText("interviewcomponent", "invite")
    const txtFeedback = getText("interviewcomponent", "feedback")
    const txtClose = getText("default", "close")


    /*
        const params = useParams()
        const fetchId = params.id ? params.id : "0"
    
        const [previewProps] = createResource<InterviewPreviewFetchType>(() => fetchInterviewMessage(fetchId));
    
        if (previewProps.error) {
            console.error("Error: ", previewProps.error);
        }
    */

    return (

        <>

            <Dialog open={props.open} onBackdropClick={props.handleClose}>
                <DialogTitle>{props.header}</DialogTitle>
                <DialogContent>

                    <Suspense fallback={
                        <Alert sx={{ px: 1, py: 1 }} severity="info">
                            <GetTextUI formName={"default"} label={"loading"} />.
                        </Alert>
                    }>

                        {/* message*/}


                        {/*  Typography color="text.secondary" innerHTML={previewTextMessage().replace(/\n/g, '<br>')} > */}


                        <GetInterviewPreviewText />


                        <WFStatusFooter type={props.type} actionDate="10.2.22 14:01" userName="Stefan Hansen" >{txtInvite() as string}</WFStatusFooter>

                        <Show
                            when={props.type === "customer"}
                        >
                            <WFStatusFooter type={props.type} actionDate="10.2.22 14:01" userName="Stefan Hansen" >{txtFeedback() as string}</WFStatusFooter>
                        </Show>


                    </Suspense>

                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="info" onClick={props.handleClose}>{txtClose()}</Button>
                </DialogActions>
            </Dialog >

        </>
    );
}

const ShowPreviewInterviewDialog = (props: PreviewInterview) => {

    const txtClose = getText("default", "close")

    return (

        <>

            <Dialog open={props.open} onBackdropClick={props.handleClose}>
                <DialogTitle>{props.header}</DialogTitle>
                <DialogContent>

                    <Suspense fallback={
                        <Alert sx={{ px: 1, py: 1 }} severity="info">
                            <GetTextUI formName={"default"} label={"loading"} />.
                        </Alert>
                    }>


                        <GetInterviewPreviewText />

                    </Suspense>

                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="info" onClick={props.handleClose}>{txtClose()}</Button>
                </DialogActions>
            </Dialog >

        </>
    );
}



export { ShowPreviewInterview, CustomerWFInterviewComponent, ShowPreviewInterviewDialog } // ShowPreviewInterviewCard 