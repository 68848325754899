
import { createSignal, onCleanup, onMount } from "solid-js"
import { Grow, Fade, Typography, Divider } from "@suid/material";
import { MailIcon, PhoneInTalkIcon } from "../components/icons"

const SplashBackground = () => {
	const [isFullScreen, setIsFullScreen] = createSignal(true);

	const toggleFullScreen = () => {
		setIsFullScreen(!isFullScreen());
	}

	
	const [backgroundImg, setBackgroundImg] = createSignal(["/assets/imgs/header.jpg", "/assets/imgs/B20231210a.jpg"]) //  "/assets/imgs/bg20231205small.jpg"
	const [textColor, setTextColor] = createSignal(["var(--find-text-color-dashboard-card)", "var(--find-darkmode-menu-color)"])
	
	const [h3, setH3] = createSignal(["Connected SAP talents", "We bring SAP customers, partners & freelancers together"])
	const [h5, setH5] = createSignal(["Much more than recruitment", "Fast & easy. Powered by AI."])
	const [idx, setIdx] = createSignal(0)

	function toggleEvery12Seconds(callback: (value: number) => void): () => void {
		let value = 0;
		const intervalId = setInterval(() => {
			value = 1 - value; // Toggle between 0 and 1
			callback(value);
		}, 8000); // 8000 milliseconds = 8 seconds

		// Return a function to stop the interval
		return () => clearInterval(intervalId);
	}

	const stopToggling = toggleEvery12Seconds((value) => {
		setIdx(value);
	});

	onCleanup(() => {
		// Call stopToggling() to stop the interval when needed
		stopToggling()
	})
	return (
		<>
			<div style={{ position: "relative" }}>
			<Grow in={true}>
				<img
					src={backgroundImg()[idx()]}
					alt="backgroundImage"
					style={{
						width: isFullScreen() ? "100vw" : "auto",
						height: isFullScreen() ? "100vh" : "auto",
						position: "absolute",
						top: 0,
						left: 0,
					}}
				/>
				</Grow>
				<div
					style={{
						position: "absolute",
						top: "100px",
						left: "30px",

						color: "white",

					}}
				>
					
					<Typography color={textColor()[idx()]} variant="h4">{h3()[idx()]}</Typography>
					<Typography color={textColor()[idx()]} variant="h5">{h5()[idx()]}</Typography>
					
					<Divider sx={{ backgroundColor: idx()===0 ? "white" : textColor()[idx()]  }} />
					<Typography color={textColor()[idx()]} variant="h6" sx={{ minWidth: 100, textAlign: "right" }}> <MailIcon />  info@findasap.team</Typography>
					<Typography color={textColor()[idx()]} variant="h6" sx={{ minWidth: 100, textAlign: "right" }}> <PhoneInTalkIcon />  +45 60 five four five four 30</Typography>
					
				</div>
			</div>
		</>
	);
}
export { SplashBackground } 