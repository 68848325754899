
import { createSignal, JSXElement } from "solid-js";
import { IconButton, Stack, Dialog, Grid } from "@suid/material";

import { InsightIcon, WorkflowIcon, MatchingIcon, JobIcon,  
    GridViewIcon, CandidatesIcon, CVIcon } from "../icons"
import { HomePageIcon } from '../SmallComponents/SmallHomePageIcon'
import { ShowHeading } from '../SmallComponents/SmallHeader'
import { isExtraSmall } from "../../../utils/sizeUtil"
import { MyThemeProvider } from "./AppBarToogleColorMode";
import { getText } from '../SmallComponents/Translate'

const smallFontSize = 25
const largeFontSize = 50

interface MenuProps {
    path: string;
    icon: JSXElement;
    textKey: string;
    type: "customer" | "candidate";
}

const ShowCustomerMenu = () => {

    const txtInsight = getText("quicklauncher", "insight")
    const txtMatching = getText("quicklauncher", "matching")
    const txtTodo = getText("quicklauncher", "todo")
    const txtJobs = getText("quicklauncher", "jobs")
    const txtCandidates = getText("quicklauncher", "candidates")
    const fontSize = isExtraSmall() ? smallFontSize : largeFontSize
    //<Stack direction={{ sm: 'row' }} alignItems={isExtraSmall() ? "center" : "left"} justifyContent="space-around" spacing={4} sx={{ margin: 2, display: 'flex' }} >

    return (
        <Stack justifyContent="space-around" sx={{ display: 'flex' }}>
            <Grid container direction="row" alignItems="center"  >
                <HomePageIcon homePageIcon={<InsightIcon sx={{ fontSize: fontSize }} />} routeName="/home" >{txtInsight() as string}</HomePageIcon>
                <HomePageIcon homePageIcon={<MatchingIcon sx={{ fontSize: fontSize }} />} routeName="/customermatching">{txtMatching() as string}</HomePageIcon>
                <HomePageIcon homePageIcon={<WorkflowIcon sx={{ fontSize: fontSize }} />} routeName="/customertodo" >{txtTodo() as string} </HomePageIcon>
                <HomePageIcon homePageIcon={<JobIcon sx={{ fontSize: fontSize }} />} routeName="/customerjob" >{txtJobs() as string} </HomePageIcon>
                <HomePageIcon homePageIcon={<CandidatesIcon sx={{ fontSize: fontSize }} />} routeName="/candidates/3" >{txtCandidates() as string} </HomePageIcon>
            </Grid>
        </Stack>
    )
}

const ShowCandidateMenu = () => {

    const txtInsight = getText("quicklauncher", "insight")
    const txtMatching = getText("quicklauncher", "matching")
    const txtTodo = getText("quicklauncher", "todo")
    const txtJobs = getText("quicklauncher", "jobs")
    const txtCV = getText("quicklauncher", "cv")
    const fontSize = isExtraSmall() ? smallFontSize : largeFontSize

    return (
        <Stack justifyContent="space-around" sx={{ display: 'flex' }}>
            <Grid container direction="row" alignItems="center"  >
                <HomePageIcon homePageIcon={<InsightIcon sx={{ fontSize: fontSize }} />} type="candidate" routeName="/home" >{txtInsight() as string} </HomePageIcon>
                <HomePageIcon homePageIcon={<MatchingIcon sx={{ fontSize: fontSize }} />} type="candidate" routeName="/candidatematching" >{txtMatching() as string}</HomePageIcon>
                <HomePageIcon homePageIcon={<WorkflowIcon sx={{ fontSize: fontSize }} />} type="candidate" routeName="/candidatetodo" >{txtTodo() as string}</HomePageIcon>
                <HomePageIcon homePageIcon={<JobIcon sx={{ fontSize: fontSize }} />} type="candidate" routeName="/myjobs" >{txtJobs() as string} </HomePageIcon>
                <HomePageIcon homePageIcon={<CVIcon sx={{ fontSize: fontSize }} />} type="candidate" routeName="/mycv" >{txtCV() as string} </HomePageIcon>
            </Grid>
        </Stack>
    )
}

const ShowQuickLauncher = () => {
    const [open, setOpen] = createSignal(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const txtAsCustomer = getText("quicklauncher", "workascustomer")
    const txtAsCandidate = getText("quicklauncher", "workascandidate")
 
    return (
        <>
            {/* ********************** */}
            {/* Show button in menubar */}
            {/* ********************** */}
            <IconButton
                size="large"
                aria-label=""
                color='primary'
                onClick={handleOpen}
            >
                <GridViewIcon />
            </IconButton>

            {/* ********************** */}
            {/* Open dialog box        */}
            {/* ********************** */}
            {open() && (
                <MyThemeProvider  >
                    <Dialog open={true} onBackdropClick={handleClose} >           
                        <ShowHeading size="small" >{txtAsCustomer()}</ShowHeading>
                        <ShowCustomerMenu />
                        <ShowHeading size="small" >{txtAsCandidate()}</ShowHeading>
                        <ShowCandidateMenu />
                    </Dialog>
                </MyThemeProvider>
            )
            }
        </>
    );
}

interface QuickLauncherProps {
    open: boolean; 
    handleClose: () => void;
}

const OpenQuickLauncher = (props: QuickLauncherProps) => {
    const [open, setOpen] = createSignal(false);     
    const handleClose = () => {setOpen(false); props.handleClose()};
    const txtAsCustomer = getText("quicklauncher", "workascustomer")
    const txtAsCandidate = getText("quicklauncher", "workascandidate")

    return (
        <>
            {/* ********************** */}
            {/* Open dialog box        */}
            {/* ********************** */}
            {props.open && (
                <MyThemeProvider  >
                    <Dialog open={true} onBackdropClick={handleClose} >       
                        <ShowHeading size="small" >{txtAsCustomer()}</ShowHeading>
                        <ShowCustomerMenu />
                        <ShowHeading size="small" >{txtAsCandidate()}</ShowHeading>
                        <ShowCandidateMenu />
                    </Dialog>
                </MyThemeProvider>
            )}         
        </>
    );
}

export { ShowQuickLauncher, ShowCustomerMenu, ShowCandidateMenu, OpenQuickLauncher }