export interface UILanguageType {
    flagCode: string;
    code: string;
    label: string;
    active?: boolean;
    default?: boolean;
} 
 
export const UILanguages: readonly UILanguageType[] = [
    { flagCode: 'dk', code: 'da', label: 'Dansk', active: true },
    { flagCode: 'us', code: 'en', label: 'English (U.S.)', active: true },
    { flagCode: 'gb', code: 'en-GB', label: 'English (UK)', active: true, default: true },
    { flagCode: 'de', code: 'de', label: 'Deutsch' },
    { flagCode: 'es', code: 'es', label: 'Español' },
    { flagCode: 'fr', code: 'fr', label: 'Français' },
    { flagCode: 'it', code: 'it', label: 'Italiano' },
    { flagCode: 'nl', code: 'du', label: 'Nederlands' },
    { flagCode: 'no', code: 'nb', label: 'Norsk (Bokmål)' },
    { flagCode: 'pl', code: 'pl', label: 'Polski' },
    { flagCode: 'pt', code: 'pt', label: 'Português' },
    { flagCode: 'fi', code: 'fi', label: 'Suomi' },
    { flagCode: 'sv', code: 'sv', label: 'Svenska' },
];