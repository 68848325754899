const countryCode = "DK";

const formatDateByCountryLong = (dateString: string): string => {
  try {
    if (!dateString || !countryCode) return "?";
    const date = new Date(dateString);
    if (!date || date === undefined) return "?";
    return (
      new Intl.DateTimeFormat(countryCode, { dateStyle: "long" }).format(date) +
      " "
    );
  } catch {
    return "?";
  }
};
const formatDateYearMonthByCountry = (dateString: string): string => {
  try {
    if (!dateString || !countryCode) return "?";
    const date = new Date(dateString);
    if (!date || date === undefined) return "?";
    return (
      new Intl.DateTimeFormat(countryCode, {
        year: "numeric",
        month: "short",
      }).format(date) + " "
    );
  } catch {
    return "?";
  }
};
const formatDateByCountry = (dateString: string): string => {
  try {
    if (!dateString || !countryCode) return "?";
    const date = new Date(dateString);
    if (!date || date === undefined) return "?";
    return (
      new Intl.DateTimeFormat(countryCode, { dateStyle: "short" }).format(
        date
      ) + " "
    );
  } catch {
    return "?";
  }
};
const formatTimeByCountry = (dateString: string): string => {
  try {
    if (!dateString || !countryCode) return "?";
    const date = new Date(dateString);
    if (!date || date === undefined) return "?";
    return new Intl.DateTimeFormat(countryCode, { timeStyle: "short" }).format(
      date
    );
  } catch {
    return "?";
  }
};
const formatTimeByCountryLong = (
  dateString: string,
  countryCode: string
): string => {
  try {
    if (!dateString || !countryCode) return "?";
    const date = new Date(dateString);
    if (!date || date === undefined) return "?";
    return new Intl.DateTimeFormat(countryCode, { timeStyle: "long" }).format(
      date
    );
  } catch {
    return "?";
  }
};
const isInvoiceDueDatePassed = (invoiceDueDate: string | Date): boolean => {
  // Convert invoiceDueDate to a Date object if it's not already one
  const dueDate =
    invoiceDueDate instanceof Date ? invoiceDueDate : new Date(invoiceDueDate);

  // Get the current date with the time set to midnight for a fair comparison
  const currentDate = new Date();

  currentDate.setHours(0, 0, 0, 0);

  // Compare the dates
  return dueDate < currentDate;
};
export {
  formatDateByCountryLong,
  formatDateYearMonthByCountry,
  formatDateByCountry,
  formatTimeByCountry,
  formatTimeByCountryLong,
  isInvoiceDueDatePassed,
};
