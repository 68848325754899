
import { useNavigate } from "@solidjs/router";
import { Button, } from "@suid/material";
import { getText } from '../SmallComponents/Translate'

import { AccountCircleIcon } from "../icons"

const ShowLoginButton = () => {

    const txtHeader = getText("menu", "login")
    const navigate = useNavigate();

    const click = () => {
        navigate('/login')
    }
 
    return (
        <>
            <Button color='primary' sx={{ minWidth: 150 }} startIcon={<AccountCircleIcon />} onClick={click}>{txtHeader()}</Button>
        </>
    );
}
export { ShowLoginButton }