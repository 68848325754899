
//import ChatMessages from 'components/chats/ChatMessages';
import { MyThemeProvider } from "../AppMenuBar/AppBarToogleColorMode";
import { CloseIcon, MailIcon } from '../icons';
import { For, createSignal, Show } from "solid-js";
import { useNavigate } from "@solidjs/router";
import { IconButton, Button, Typography, Badge, Card, Stack,  Divider,Dialog, DialogActions, DialogTitle, DialogContent, Box, } from "@suid/material";
import { ShowPreviewInterviewDialog } from '../../components/Workflow/CustomerWfInterviewComponent'
import { blue, grey, } from "@suid/material/colors";
import Avatar from '@suid/material/Avatar';
import Grid from '@suid/material/Grid';
import createTheme from "@suid/system/createTheme";
import { WFStatusFooter } from '../SmallComponents/SmallShowWFStatusFooter';
import { getDummyAvatar } from '../../../utils/getdummyavatar'

import { getText } from '../SmallComponents/Translate'

const radius = 2.5;
const size = 4;

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
      contrastText: "white",
    },
  },
});
/*
const MyComponent = styled("div")(({ theme }) => ({
   
  color: "darkslategray",
  backgroundColor: "aliceblue",
  padding: 8,
  borderRadius: 4,
   
  // if you want the same as facebook messenger, use this color '#09f'
  return {
    avatar: {
      width: size,
      height: size,
    },
    msg: {
      padding: 1,
      borderRadius: 4,
      marginBottom: 4,
      display: 'inline-block',
      wordBreak: 'break-word',
      fontFamily:
        // eslint-disable-next-line max-len
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    },
    leftRow: {
      textAlign: 'left',
    },
    left: {
      borderTopRightRadius: radius,
      borderBottomRightRadius: radius,
      backgroundColor: theme.palette.grey[100],
    },
    leftFirst: {
      borderTopLeftRadius: radius,
    },
    leftLast: {
      borderBottomLeftRadius: radius,
    },
    rightRow: {
      textAlign: 'right',
    },
    right: {
      borderTopLeftRadius: radius,
      borderBottomLeftRadius: radius,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    rightFirst: {
      borderTopRightRadius: radius,
    },
    rightLast: {
      borderBottomRightRadius: radius,
    },
  };
});
*/


{/* *************************** */ }
{/* Show mail button in menubar */ }
{/* *************************** */ }

interface showMessageProps {
  newMessages: number;
  showPeople: boolean;
  scrAvatar: string;

}

interface MyAvatarProps extends showMessageProps {
  selected: number | null,
  onClick: () => void,
  id: number
}

const MyAvatar = (props: MyAvatarProps)  => {


  const { selected, onClick, id, scrAvatar, showPeople, newMessages } = props;
  const isSelected = selected === id;

  return (
    <>
      {isSelected
        ? (

          <Box sx={{ backgroundColor: "success.light", color: "success.dark" }}>
            <Avatar sx={{ width: 56, height: 56 }} onClick={onClick} src={getDummyAvatar()} />
          </Box>
        )
        : (
          <Avatar onClick={onClick} src={getDummyAvatar()} />
        )
      }
    </>
  )
}

const ShowMessages = (props: showMessageProps) => {
  const navigate = useNavigate();
  const [open, setOpen] = createSignal(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [selectedAvatar, setSelectedAvatar] = createSignal<number | null>(null);
  const [showSelectedAvatar, setShowSelectedAvatar] = createSignal(true);
  const [avatarSrc, setAvatarSrc] = createSignal(1);


  const handleOpenMessageHistory = () => {

    // Show message history
    // Generate a random index
    const randomIndex = Math.floor(Math.random() * avatarGrossList.length);

    // Get the avatar with the random index
    const randomAvatar = avatarGrossList[randomIndex];
    setAvatarSrc(randomIndex)
    setShowSelectedAvatar(true)
  }
  const handleOpenCV = () => {
    navigate('/customercv')
  }
  const avatarGrossList = [
    { type: "Teddy", src: "/assets/imgs/avatar2.jpg" },
    { type: "Peter", src: getDummyAvatar() },
    { type: "Winny", src: getDummyAvatar() },
    { type: "Sahra", src: getDummyAvatar() },
    { type: "Kid", src: getDummyAvatar() },
    { type: "Sunshi", src: getDummyAvatar() },
    { type: "Berny", src: getDummyAvatar() },
    { type: "Kentucy", src: getDummyAvatar() },
    { type: "Fried", src: getDummyAvatar() },
    { type: "Carot", src: getDummyAvatar() },
    { type: "Åse", src: getDummyAvatar() },
    { type: "Cathy", src: getDummyAvatar() },
  ]

  const txtMessages = getText("smallcomponent", "messages")
  const txtNewMessages = getText("smallcomponent", "newmessages")
  const txtClose = getText("default", "close")
  return (
    <>
      <IconButton
        size="large"
        aria-label="show new messages"
        color='primary'
        onClick={handleOpen}
      >
        <Badge badgeContent={props.newMessages} color="error">
          <MailIcon />
        </Badge>
      </IconButton>

      {/* ********************** */}
      {/* Open dialog box        */}
      {/* ********************** */}
      {open() && (
        <MyThemeProvider  >
          <Dialog open={true} onBackdropClick={handleClose}>
            <DialogTitle>
              <Box sx={{ margin: 1, display: 'flex', }} >
                <IconButton onClick={handleClose} > <CloseIcon /> </IconButton>
                <Show
                  fallback={
                    <Typography variant="h6">{txtMessages()}</Typography>
                  }
                  when={props.showPeople}
                >
                  <Typography variant="h6">{txtNewMessages()}</Typography>
                </Show>
              </Box>

              <Divider />
            </DialogTitle>
            <DialogContent>


              <Grid container>
                {/* Show list of people with dialog */}
                <Show

                  when={props.showPeople}
                >
                  <Grid item xs={2} alignItems="center">

                    <Show
                      when={showSelectedAvatar()}
                    >
                      <Card sx={{ backgroundColor: blue[200], margin: 1, px: 1, py: 1, display: 'flex' }}  >
                        <Stack sx={{ display: 'flex' }} direction="column" alignItems="center" spacing={1}
                        >

                          <Stack sx={{ alignItems: 'center', display: 'flex' }}  >

                            <Button onclick={handleOpenCV}>
                              <Avatar sx={{ width: 40, height: 40 }} src={avatarGrossList[avatarSrc()].src} />
                            </Button>


                            <Typography fontSize="80%" variant="body2" color="text.secondary">{avatarGrossList[avatarSrc()].type}</Typography>
                          </Stack>

                        </Stack>
                      </Card>

                    </Show>
                    <Card sx={{ margin: 1, px: 1, py: 1, display: 'flex' }}  >
                      <Stack sx={{ display: 'flex' }} direction="column" alignItems="center" spacing={1}
                      >
                        <For each={avatarGrossList}>
                          {(item) =>
                            <Stack sx={{ alignItems: 'center', display: 'flex' }}  >
                              <Button onclick={handleOpenMessageHistory}>
                                <Avatar sx={{ width: 40, height: 40 }} src={item.src} />
                              </Button>

                              <Typography fontSize="80%" variant="body2" color="text.secondary">{item.type}</Typography>
                            </Stack>
                          }
                        </For>
                      </Stack>
                    </Card>


                  </Grid>
                </Show>
                {/* Show pane with messages */}
                <Grid item xs={props.showPeople === true ? 10 : 12}>


                  <Card sx={{ margin: 1, px: 1, py: 1 }}  >
                    <ChatDemo scrAvatar={props.scrAvatar} />
                  </Card>


                </Grid>
              </Grid>

            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} variant="outlined">
                {txtClose()}
              </Button>

            </DialogActions>
          </Dialog>
        </MyThemeProvider>
      )
      }
    </>
  );
}


{/* *************************** */ }
{/* Handle a chat message       */ }
{/* *************************** */ }

interface ChatMessageProps {
  avatar: string,
  messages: string[],
  side: "left" | "right",
  chatDate?: string,
  userName?: string,
  type?: string,
};


export const ChatMessages = ({ avatar = '', messages = [], side = 'left', chatDate = '', userName = '', type = 'message' }: ChatMessageProps) => {
  const txtDate = getText("interviewcomponent", "date")
  const txtInterviewBy = getText("interviewcomponent", "interviewby")
  const handleClose = () => {
   
  }
  return (
    <>
      <Grid container spacing={2}>
        <Show
          fallback={
            <>

              <Grid item xs={2}>

              </Grid>
              <Grid item xs={1}>
                <Avatar src={avatar} />
              </Grid>
              <Grid item xs={9} >
                <Stack alignItems="right" >
                  <WFStatusFooter type="customer" actionDate={chatDate} userName={userName} > </WFStatusFooter>
                  <ShowPreviewInterviewDialog open={false} header="From Chat" handleClose={handleClose} type="customer"/>



                </Stack>
              </Grid>

            </>
          }
          when={side === 'left'}
        >
          <Grid item xs={1} alignItems="right">
            <Avatar src={avatar} />
          </Grid>
          <Grid item xs={9}>
            <Stack alignItems="left" >
              <WFStatusFooter type="customer" actionDate={chatDate} userName={userName} > </WFStatusFooter>
              <Card sx={{ borderRadius: 4, backgroundColor: blue[200], margin: 1, px: 1, py: 1 }} >

                <Typography variant="body1">{messages}</Typography>
              </Card>

            </Stack>
          </Grid>
          <Grid item xs={2}>

          </Grid>
        </Show>
      </Grid>
    </>
  );
};



{/* *************************** */ }
{/* Test a list of messages     */ }
{/* *************************** */ }


interface chatDemoProps {
  scrAvatar: string;
}

const ChatDemo = (props: chatDemoProps) =>{

  const AVATAR =
    getDummyAvatar();
  // 'https://i.pinimg.com/originals/0a/dd/87/0add874e1ea0676c4365b2dd7ddd32e3.jpg';



  return (
    <>

      <ChatMessages
        side={'right'}
        avatar={AVATAR}
        type='interview'
        messages={[
          'Hi Michael,',
          'We would like to schedule a second and final interview. Invite will follw shortly.',

        ]}
        chatDate="10.2.22 14:01"
        userName="Stefan Hansen"
      />
      <ChatMessages side={'left'} avatar={props.scrAvatar} messages={['Im good.', 'See u later.']}
        chatDate="10.2.22 14:01"
        userName="Michael Scott"
      />
      <ChatMessages
        side={'right'}
        avatar={AVATAR}
        messages={[
          "Hi Michael,",
          'Thanks for taking your time to talk to us. We would like to offer your a contract. We will send your a proposal later today',
        ]}
        chatDate="13.2.22 9:01"
        userName="Tany Smidth"
        type='proposal'
      />
      <ChatMessages side={'left'} avatar={props.scrAvatar} messages={['Look forward to the interview', 'Talk to you later.']}
        chatDate="10.2.22 14:01"
        userName="Michael Scott"

      />

    </>
  );
};
export { ShowMessages }