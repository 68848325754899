
import { onMount, JSX, Show, createSignal, splitProps } from 'solid-js'; 
import { ThemeProvider, createTheme, Theme } from '@suid/material/styles';
import { MenuItem, ListItemIcon, useMediaQuery } from '@suid/material';
import { DarkModeIcon, LightModeIcon } from '../icons';

//import CssBaseline from '@suid/material/CssBaseline';

const isDark = () =>{
    //const savedTheme = localStorage.getItem('theme');
    //return (savedTheme === 'dark' ? true : false);
    return document?.body?.classList?.contains('dark-mode') ? true : false //
}

const ThemeSwitcher = () =>{
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const savedTheme = localStorage.getItem('theme');
    const [mode, setMode] = createSignal<string>(savedTheme ?? (prefersDarkMode() ? 'dark' : 'light'));

    const handleThemeSwitch = () => {
        localStorage.setItem('theme', mode() === 'light' ? 'dark' : 'light');
        location?.reload()
    };
    //const icon = createMemo(() => (mode() === 'dark' ? <LightModeIcon /> : <DarkModeIcon />));
    // const icon =  (mode() === 'dark' ? <LightModeIcon /> : <DarkModeIcon />);

    return (

        <MenuItem onClick={handleThemeSwitch} >
            <ListItemIcon>
                <Show
                    when={mode() === 'dark'}
                    fallback={
                        <DarkModeIcon fontSize="small" />
                    }
                >
                    <LightModeIcon fontSize="small" />
                </Show>
                </ListItemIcon>
                Dark/Light Theme
        </MenuItem>
    );
};


const ThemeForAppBar = createTheme({
    palette: {
        primary: {
            main: '#FFFFFF', // replace with your color

        },
        secondary: {
            main: '#FB6666', // replace with your color
        },
    },
    components: {
        MuiBottomNavigationAction: {

            defaultProps: {
                // Add your default styles here
                color: 'rgba(0, 80, 100, 0.54)', // default color

            }

        }
    }
});


interface MyThemeProviderPropos {
    theme?: Theme;
    children: JSX.Element;
}

const MyThemeProvider = (props: MyThemeProviderPropos) => {
    const [local] = splitProps(props, ['children'])
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const savedTheme = localStorage.getItem('theme');
    const newMode = savedTheme ?? (prefersDarkMode() ? 'dark' : 'light')
    const [mode, setMode] = createSignal<string>(newMode);

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',

        },
    })
    const lightTheme = createTheme({
        palette: {
            mode: 'light',

        },
    })

    onMount(() => {
        if (mode() === 'dark') {

            document?.body?.classList?.add('dark-mode');
            document.documentElement.setAttribute('data-theme', 'dark');

        } else {
            document?.body?.classList?.remove('dark-mode');
            document.documentElement.removeAttribute('data-theme');
        }
    });

    return (
        <ThemeProvider theme={props.theme ?? (mode() === 'light' ? lightTheme : darkTheme)}>
            {props?.children}
        </ThemeProvider>
    );
};





/*
const darkTheme = createTheme({

    palette: {
        mode: 'dark',
        primary: {
            // Purple and green play nicely together.
            main: "#8385C2",
        },
        secondary: {
            // This is green.A700 as hex.
            main: "#11cb5f",
        },
    },
})
const lightTheme = createTheme({

    palette: {
        mode: 'light',

        primary: {
            // Purple and green play nicely together.
            main: "#FB6666",
        },
        secondary: {
            // This is green.A700 as hex.
            main: "#11cb5f",
        },
    },


})
*/

export { MyThemeProvider, ThemeForAppBar, ThemeSwitcher, isDark }