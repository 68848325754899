
import { createSignal, onMount, Show } from "solid-js"
import { Stack, Card, TextField, Select, MenuItem, ListItemIcon, ListItemText, Divider, IconButton } from "@suid/material";
import { mobileMenu }  from "../../../utils/sizeUtil"
import { SearchIcon, SaveIcon } from '../icons';

import { SelectChangeEvent } from "@suid/material/Select";
import { getText } from '../SmallComponents/Translate'

interface SearchStringType {
  searchString: string;
  suggested?: boolean;
}

const addressList: readonly SearchStringType[] = [
  { searchString: '', suggested: true },
  { searchString: 'Gold', suggested: true },
  { searchString: 'S/4 HANA Upgrade', suggested: true },
];


interface SearchWithNavigationProps {
  maxWidth: number;
}
 
const SearchWithNavigation = (props: SearchWithNavigationProps) => {
  const [searchString, setSearchString] = createSignal<null | string>(addressList[0].searchString);
  const [open, setOpen] = createSignal(false);
  const [filteredOptions, setFilteredOptions] = createSignal<SearchStringType[]>([]);

  const handleSearchStringChange = (e: SelectChangeEvent) => {
    setSearchString(e.target.value);
  };

  onMount(async () => {
    const suggestedCountries = addressList.filter(searchList => searchList.suggested);
    setFilteredOptions(suggestedCountries);
  });

  const txtSaveSearch = getText("menu", "savesearch");

  const SearchInput = () => (
    <TextField
      fullWidth
      required
      id="searchTextField"
      type="search"
      size="small"
      variant="standard"
      
      defaultValue={"Search candidate, job or tag"}
      value={searchString()}
      onChange={(e, value) => setSearchString(value)}
      InputProps={{
        startAdornment: (
          <IconButton aria-label="search">
            <SearchIcon />
          </IconButton>
        ),
        endAdornment: (
          <Select
            open={open()}
            value={searchString()}
            onChange={handleSearchStringChange}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            variant="standard"
            disableUnderline
            renderValue={(selected) => ` `}
          >


            {filteredOptions().map((option, index) => (
              <MenuItem
                value={option.searchString}
                onClick={() => {
                  setSearchString(option.searchString as string);
                  setOpen(false);
                }}
              >

                <ListItemText>
                  {option.searchString}
                </ListItemText>
              </MenuItem>
            ))}

            <Divider />
            <MenuItem value={"Others"}>
              <ListItemIcon>
                <SaveIcon />
              </ListItemIcon>
              <ListItemText>
                {txtSaveSearch() as string}
              </ListItemText>
            </MenuItem>
          </Select>
        )
      }}
    />
  );

  return (
    <>
      <Show
        when={!props.maxWidth}
        fallback={
          <Card sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Stack  >
              <SearchInput />
            </Stack>
          </Card>
        }
      >
       
        <Card sx={{ width: '90%', margin: mobileMenu ? 4 : 1 }}>
          <SearchInput />
        </Card>
       

      </Show>
    </>
  );
}

// Add the following four lines to add a direction icon: (add to line 29) 
// <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
// <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
//   <DirectionsIcon />
// </IconButton>

export { addressList, SearchWithNavigation }
