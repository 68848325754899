import { MailIcon } from "../icons"
import { TextField, FormControl } from "@suid/material";
import { createSignal } from "solid-js";

interface FieldEmailProps {
    id: string;
    label?: string;
    textValue: string;
    width?: number;
    valueOnChange: (value: string) => void;
}

const FieldEmail = (props: FieldEmailProps) =>{
    const [userEmail, setUserEmail] = createSignal(props.textValue);
    const [value, setValue] = createSignal<string | null>(props.textValue);
    const handleInputChange = (onChange: (value: string) => void) => (event: Event) => {
        const newValue = (event.target as HTMLInputElement).value;
        onChange?.(newValue);
        setValue(newValue)
        setUserEmail(newValue)
    };

    const checkValidEmail = (email: string) =>{
        const regex = /^.{1,}@.{1,}\..{2,}$/;
        return regex.test(email);
    }

    return (
        <>
            <FormControl fullWidth >
                <TextField
                    required id={props.id} label={props.label} type="email" variant="outlined"
                    size="small"
                    defaultValue={props.textValue}
                    value={value()}
                    onChange={handleInputChange(props.valueOnChange)}
                    InputProps={{
                        endAdornment: (
                            <MailIcon
                                aria-label="more"
                                id="long-button"
                                sx={{ ml: 1 }}
                                color={checkValidEmail(userEmail()) ? "success" : 'disabled'}
                            />
                        ),
                    }}
                />
            </FormControl>
        </>
    )
}
export {FieldEmail}

//value={props.textValue}
//onChange={handleInputChange(props.valueOnChange)}
//onChange={(_, newValue: string | null) => {
//    setValue(newValue);
//  }}