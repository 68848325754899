const concatenateAddress = (country: string, postalCode: string, city: string, street: string) => {
    let address = "";
    if (country)
        address += country;
    if (postalCode)
        address += (address.length ? ", " : "") + postalCode;
    if (city)
        address += (address.length ? ", " : "") + city;
    if (street)
        address += (address.length ? ", " : "") + street;
    return address;
}

export { concatenateAddress }