import { FavouritesIcon } from "../icons"
import { Button } from "@suid/material";
import { Show, createSignal } from "solid-js";
import { isLarge }  from "../../../utils/sizeUtil"

import { useNavigate } from "@solidjs/router";
import { Popover, Typography } from "@suid/material";
import { getText } from '../SmallComponents/Translate'

const Favourites = () => {

  const navigate = useNavigate();
   
  const handleOpenFavourits = () => {
        
    navigate('/favourit')
  }


  const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);

  const handlePopoverOpen = (event: { currentTarget: Element }) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = () => Boolean(anchorEl());

  const txtFavourite = getText("menu", "favourits")

  return (
    <>
      <Show
        fallback={
          <Button color="primary" startIcon={<FavouritesIcon />}  sx={{ minWidth: 60 }}
            onClick={handleOpenFavourits}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
          </Button>
        }
        when={isLarge() === true}
      >
        <Button color="primary" sx={{ minWidth: 150 }} startIcon={<FavouritesIcon />} onClick={handleOpenFavourits}>{txtFavourite()}</Button>
      </Show>

      <Popover
        id="mouse-over-popover"
        sx={{ pointerEvents: "none" }}
        open={open()}
        anchorEl={anchorEl()}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography
          aria-owns={open() ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {txtFavourite()}
        </Typography>

      </Popover>
    </>

  );
}

export { Favourites }