import { createSignal, createEffect, onCleanup } from "solid-js";

// Initialize from localStorage
const [locIsLoggedIn, setLocIsLoggedIn] = createSignal(
  !!localStorage.getItem("isLoggedIn")
);

// Side-effect to store the status in localStorage whenever it changes
createEffect(() => {
  if (locIsLoggedIn()) {
    localStorage.setItem("isLoggedIn", "true");
  } else {
    localStorage.removeItem("isLoggedIn");
  }
});

const isLoggedIn = () => {
  return locIsLoggedIn();
}

const handleLogin = () => {
  // ... authentication logic ...

  setLocIsLoggedIn(true);
}

const handleLogout = () => {
  // ... logout logic ...

  setLocIsLoggedIn(false);
}

// Put this in the beginning of each page before they are loaded
/*
  import { useNavigate } from "@solidjs/router";

const ProtectedPage = () => {
  const navigate = useNavigate();

  if (!isLoggedIn()) {
    navigate('/login');
    return;
  }

  return (
 
  );
}
*/

export {isLoggedIn, handleLogin, handleLogout}