
import { PersonAddIcon } from "../icons"
import { createSignal, createEffect } from "solid-js";
import { getURL } from '../../../utils/fetchAPI' 
import toast from 'solid-toast';
import { FieldTextValue } from "../SmallComponents/SmallFieldText";
import { FieldEmail} from "../SmallComponents/SmallFieldEmail";
import { SecurityConfirmCodes } from "./SecurityConfirmCodes";
import {
  Button, Typography, Checkbox, Stack, Grid, Card, CardMedia, Link,
  Divider, Dialog, DialogActions, DialogTitle, DialogContent, Box,
} from "@suid/material";
import { setUserStore } from "../../context/userContext";
import { getText } from '../SmallComponents/Translate'
import { showError } from '../SmallComponents/SmallShowError'

const apiUrl = import.meta.env.VITE_APP_API_URL;

const SignUp = () => {
  const [open, setOpen] = createSignal(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openConfirmCodes, setOpenConfirmCodes] = createSignal(false);
  const handleOpenConfirmCodes = () => setOpenConfirmCodes(true);

  const handleCloseConfirmCodes = () => {
    // Close the dialog box with the confirmation codes
    setOpenConfirmCodes(false);
    // Also close the sign up dialog box
    setOpen(false);
  }
  const [firstName, setFirstName] = createSignal('');
  const [lastName, setLastName] = createSignal('');
  const [email, setEmail] = createSignal('');
  const [verifyEmail, setVerifyEmail] = createSignal('');
  const txtAggreeto = getText("signup", "agreeto")
  const txtWelcome = getText("login", "welcome")

  const txtErrorExt = getText("signup", "errorextension")
  const txtError = getText("default", "error", [{ name: 'during', value: txtErrorExt() as string }])

  const labelSignUp = { inputProps: { "aria-label": txtAggreeto() } };
  //const { disabled, setDisabled } = useContext(DisabledContext) as DisabledContextValue;

  const handleSignUp = () => {

    // getURL(`${apiUrl}/api/security/onboard`, 'POST', { email: email(), firstName: firstName(), lastName: lastName() })
    getURL(`${apiUrl}/security/signupGenerateCode`, 'POST', { email: email(), firstName: firstName(), lastName: lastName() })
      .then((fetchedData) => {

        const txtSuccess = getText("signup", "success", [{ name: 'email', value: email() }])

        // if (fetchedData.status === '200') {
        /*          userContext.setUserStore("userId", fetchedData.userId as string);
                  userContext.setUserStore("userFirstName", fetchedData.userFirstName as string);
                  userContext.setUserStore("userLastName", fetchedData.userLastName as string);
                  userContext.setUserStore("userCompanyId", fetchedData.userCompanyId as string);
                  userContext.setUserStore("userCompanyName", fetchedData.userCompanyName as string);
                  userContext.setUserStore("userAvatar", fetchedData.userAvatar as string);
                  userContext.setUserStore("userLanguage", fetchedData.userLanguage as string);
                  userContext.setUserStore("userCountry", fetchedData.userCountry as string);
        */
        setUserStore({
          userId: email(),
          userFirstName: firstName() as string,
          userLastName: lastName() as string,
          userCompanyId: "" as string,
          userCompanyName: "" as string,
          userAvatar: "" as string,
          userLanguage: "UK-GB" as string,
          userCountry: "GB" as string
        });


        toast.success(txtWelcome() + firstName() + " " + lastName() + txtSuccess(), { duration: 5000, });


        createEffect(() => {
          setVerifyEmail(email())

        })

        setOpenConfirmCodes(true);

        //     setOpen(false);

        //navigate("/onboard", { replace: true });

        // } else {
        //   toast.error('Something went wrong during Sign up! Technical Message: - ' + fetchedData.status, { duration: 5000, });
        //   console.log('Something went wrong during Sign up! - ' + fetchedData.status);
        // }


      }).catch((error) => {
        showError(txtError() as string, `${error.message}`);
      })
  };

  const txtHeader = getText("signup", "header")
  const txtFirstname = getText("contact", "firstname")
  const txtLastname = getText("contact", "lastname")
  const txtEmail = getText("contact", "email")
  const txtTerms = getText("signup", "terms")
  const txtClose = getText("default", "close")
  const txtCookies = getText("signup", "cookies")
  const txtSubmit = getText("signup", "submit")

  return (
    <>
      <Button color='primary' sx={{ minWidth: 150 }} startIcon={<PersonAddIcon />} onClick={handleOpen}>{txtHeader()}</Button>

      {open() && (

        <Dialog open={true} onBackdropClick={handleClose}>
          <DialogTitle>
            {" "}
            <Typography variant="h4">{txtHeader()}</Typography>
          </DialogTitle>
          <DialogContent>



            <Grid container spacing={2}>

              <Grid item xs={2} md={4} container alignItems="center" justifyContent="center">

                <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
                    <CardMedia
                      component="img"
                      sx={{ maxWidth: '100%', maxHeight: '100%' }}
                      image="/assets/imgs/signup/08.png"
                      alt="Live from space"
                    />
                  </Box>
                </Card>
              </Grid>

              <Grid item xs={10} md={8}  >
                <Stack margin={1} spacing={2}
                  justifyContent="center">

                  <FieldTextValue
                    id="signUpfirstName" label={txtFirstname()} textValue=""
                    valueOnChange={value => setFirstName(value)}
                  />
                  <FieldTextValue
                    id="signUpsecondName" label={txtLastname()} textValue=""
                    valueOnChange={value => setLastName(value)}
                  />
                  <FieldEmail
                    id="loginUserEmail" label={txtEmail()} textValue={""}

                    valueOnChange={value => setEmail(value)}
                  />

                  <div>
                    <Checkbox {...labelSignUp} />
                    <Link href="/user/users/legal?name=tos" target="_blank">{txtTerms() as string}</Link> & <Link href="/user/users/legal?name=cookies" target="_blank">{txtCookies() as string}</Link>
                  </div>
                </Stack>
              </Grid>
            </Grid>
            <Divider />
          </DialogContent>
          <DialogActions>

            <SecurityConfirmCodes verificatUser={verifyEmail()} open={openConfirmCodes()} handleOpen={handleOpenConfirmCodes} handleClose={handleCloseConfirmCodes} validationType={"email"} validationMedia={"email"} />


            <Button variant="outlined" onClick={handleClose}>{txtClose()}</Button>
            <Button variant="contained" startIcon={<PersonAddIcon />} onClick={handleSignUp}>{txtSubmit()}</Button>
          </DialogActions>

        </Dialog>
      )}
    </>

  );
}
export { SignUp }
