import { Show, Switch, Match, createSignal, createEffect, JSXElement, splitProps } from "solid-js";
import { Chip, Avatar, Button } from "@suid/material";

import { ShowPreviewInterview } from '../../components/Workflow/CustomerWfInterviewComponent'
import { ShowPreviewContract } from '../../components/Workflow/CustomerWfContractComponent'


import { CheckIcon, QuestionMarkIcon, ScheduleIcon, ContractIcon, ProposalIcon, InterviewIcon } from '../icons';

interface AccountStatusChipProps {
  status?: string;
  children: JSXElement;
}

const AccountStatusChip = (props: AccountStatusChipProps) => {
  const [local] = splitProps(props, ['children'])
  const [colorName, setColorName] = createSignal("success");

  createEffect(() => {
    switch (props?.status) {
      case "active":
        setColorName("success");
        break;
      case "suspended":
        setColorName("error");
        break;
      case "no account":
        setColorName("primary");
        break;
      default:
        break;
    }
  });

  /*  color={() => colorName()} */
  return (
    <Chip
      sx={{ margin: 1 }}
      label={props.children}
      variant="outlined"
      size="small"
      color="success"
    />
  );
}

interface AvatarChipWithIconProps {
  url: string;
  children: JSXElement;
}

const AvatarChipWithIcon = (props: AvatarChipWithIconProps) => {
  const [local] = splitProps(props, ['children'])
  return (
    <Chip
      avatar={<Avatar alt={props.children as string} src={props.url} />}
      label={props.children}
      variant="outlined"
      size="small"

    />
  );
}

interface AvatarChipWithLabelProps {
  children: JSXElement;
}

const AvatarChipWithLabel = (props: AvatarChipWithLabelProps) => {
  let labelCharacter = "?";
  if (props.children)
    labelCharacter = (props.children as string)?.charAt(0);

  return (
    <Chip
      avatar={<Avatar>{labelCharacter}</Avatar>}
      label={props.children}
      variant="outlined"
      size="small"
    />
  );
}

interface WFChipProps {
  wfCompleted?: boolean;
 // iconName?: string;
  children: JSXElement;
  type: "customer" | "candidate"
  category: "question" | "schedule" | "interview" | "proposal" | "contract"
}

const WFChip = (props: WFChipProps) => {
  props.wfCompleted ? props.wfCompleted : props.wfCompleted = false;
  //props.iconName ? props.iconName : props.iconName = ""
  let icon = <CheckIcon />;
  let borderName = props.wfCompleted ? "default" : "dotted";
  const [openDialog, setOpenDialog] = createSignal(false)

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }
  const handleShowPreview = () => {

    switch (props.category) {
      case "question":
        icon = <QuestionMarkIcon />;
        break;
      case "schedule":
        icon = <ScheduleIcon />;
        break;
      case "contract":
        icon = <ContractIcon />;
        setOpenDialog(true)
        break;
      case "proposal":
        icon = <ProposalIcon />;
        break;
      case "interview":
        icon = <InterviewIcon />;
        setOpenDialog(true)
        break;
      default:

        setOpenDialog(true)
        break;
    }
  }
  

  switch (props.category) {
     
    case "question":
      icon = <QuestionMarkIcon />;
      break;
    case "schedule":
      icon = <ScheduleIcon />;
      break;
    case "contract":
      icon = <ContractIcon />;
      break;
    case "proposal":
      icon = <ProposalIcon />;
      break;
    case "interview":
      icon = <InterviewIcon />;
      break;
    default:
      icon = <CheckIcon />;
      break;
  }

  if (props.wfCompleted===true)
     icon = <CheckIcon />;

  return (
    <>
      <Switch>
        <Match when={props.category === "interview"}>
          <ShowPreviewInterview type={props.type} header={props.children as string} open={openDialog()} handleClose={handleCloseDialog} />
        </Match>
        <Match when={props.category === "contract"}>
          <ShowPreviewContract type={props.type} header={props.children as string} open={openDialog()} handleClose={handleCloseDialog} />
        </Match>
      </Switch>

      <Show
        when={props.wfCompleted}
        fallback={
          <Chip
            icon={icon}
            label={props.children}
            color="default"
            variant="outlined"
            size="small"
            sx={{ py: 1, border: borderName }}
            onClick={handleShowPreview}
            style={{
              cursor: 'pointer',
            }}
          />
        }
      >
        <Chip
          icon={icon}
          label={props.children}
          color="success"
          variant="outlined"
          size="small"
          sx={{ py: 1 }}
          onClick={handleShowPreview}
          style={{
            cursor: 'pointer',
          }}
        />
      </Show>
    </>
  );
}
export { AccountStatusChip, WFChip, AvatarChipWithIcon, AvatarChipWithLabel }