 
import { TextField, Typography, Stack } from '@suid/material';
import { Show, type Component } from 'solid-js';
import { getText } from '../SmallComponents/Translate'
import { isExtraSmall } from "../../../utils/sizeUtil"

// Define the type for the DateInput component props
interface DateInputProps {
  id?: string;
  value?: string;
  defaultValue?: string;
  label?: string;
  onChange?: (value: string) => void;
}

// Create the DateInput component
const DateInput: Component<DateInputProps> = (props) => {
  // Handle the change event

  const handleChange = (event: Event) => {
    const newValue = (event.target as HTMLInputElement).value;
    const isValidDate = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(newValue);
    if (isValidDate) {
      props.onChange?.(newValue);
    }
  };

  let date: Date = new Date();
  let year: number = date.getFullYear();
  let month: number = date.getMonth() + 1; // JavaScript months are 0-based, so +1 is necessary
  let day: number = date.getDate();

  // Ensuring 2 digits for day and month
  let formattedDay: string = (day < 10) ? '0' + day : day.toString();
  let formattedMonth: string = (month < 10) ? '0' + month : month.toString();

  let formattedDate: string = `${year}-${formattedMonth}-${formattedDay}`;

  const txtTitle = getText("smallcomponent", "dateformat")
  return (
    <TextField
      sx={{ width: 150 }}
      required
      id={props.id || ''}
      label={props.label || ''}
      variant="outlined"
      size="small"
      type="date"
      defaultValue={props.defaultValue ? props.defaultValue : formattedDate}
      onInput={handleChange}
      onChange={handleChange}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{

        // Disable native validation to prevent the browser's default error messages
        // and use a custom validation function instead
        pattern: '[0-9]{4}-[0-9]{2}-[0-9]{2}',
        title: txtTitle(),
      }}
    />
  );
};

interface DateRangeInputProps {
  fromValue?: string;
  toValue?: string;
  defaultFromValue?: string;
  defaultToValue?: string;
  onFromChange?: (value: string) => void;
  onToChange?: (value: string) => void;
}

const DateRangeInput: Component<DateRangeInputProps> = (props) => {
  const handleDateChangeFrom = (value: string) => {
    props.onFromChange?.(value);
  };
  const handleDateChangeTo = (value: string) => {
    props.onToChange?.(value);
  };

  const txtStartDate = getText("smallcomponent", "startdate")
  const txtEndDate = getText("smallcomponent", "enddate")
  const txtTo = getText("default", "towithspaces")
  return (
    <>
      <Stack direction={isExtraSmall() ? "row" : "row"} spacing={isExtraSmall() ? 1 : 2} alignItems="left" >

        <DateInput
          value={props.fromValue}
          defaultValue={props.defaultFromValue}
          id="dateidfrom"
          label={txtStartDate()}
          onChange={handleDateChangeFrom}
        />
        <Show
          when={!isExtraSmall()}
        >
          <Typography color="text.secondary">{txtTo()}</Typography>
        </Show>
        <DateInput
          value={props.toValue}
          defaultValue={props.defaultToValue}
          id="dateidto"
          label={txtEndDate()}
          onChange={handleDateChangeTo}
        />
      </Stack>
    </>
  );
};

export { DateInput, DateRangeInput }