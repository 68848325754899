
import { MyThemeProvider, ThemeSwitcher, ThemeForAppBar } from "./AppBarToogleColorMode"
import { mobileMenu } from "../../../utils/sizeUtil"
import { Button, BottomNavigation, BottomNavigationAction, } from "@suid/material";

import { SplashBackground } from "../../pages/SplashBackground";
import { HomeButton } from "../Home/HomeButton"
import { Favourites } from "./AppBarFavourites"
import { AppBarSharing } from "./AppBarSharing"
import { SearchIcon, SaveIcon } from '../icons';
import { SearchWithNavigation } from "./AppBarSearchComponent"
import { SelectOrganization } from "./AppBarSelectOrganization"
import { ShowNotifications } from "./AppBarNotifications"
import { OpenQuickLauncher, ShowQuickLauncher } from "./AppBarQuickLaunch"
import { SignUp } from "./SignUp"
import { ShowLoginButton } from "./Login"
import FindAsapLogo from '~/src/assets/imgs/logofindAsap.jpg';

import { ShowMessages } from '../SmallComponents/SmallChatMessage';

import { grey } from "@suid/material/colors";

import { Show, createSignal, } from "solid-js"
import { useNavigate } from "@solidjs/router";

import { getUserStore, setUserStore } from "../../context/userContext";
import { showError } from '../SmallComponents/SmallShowError'

import { getText, GetTextUI, SelectTranslation } from '../SmallComponents/Translate'
import { handleLogout } from '../../../utils/isloggedin'

import { Paper, Stack, Drawer, AppBar, Box, IconButton, Toolbar, Typography, ListItemIcon, Divider, Avatar, Menu, MenuItem, List, ListItem, ListItemButton, Badge, rgbToHex, ListItemText, } from "@suid/material";

import {
  AccountCircleIcon, LogoutIcon, HeadsetMicIcon, CameraIcon, Settings, MoreVertIcon, GridViewIcon, FavouritesIcon,
  SharingIcon, MailIcon, WorkflowIcon, HomeIcon
} from "../icons";

const apiUrl = import.meta.env.VITE_APP_API_URL;

const CompanyLogo = () => {
  return (
    <>
      <Box
        component="img"
        sx={{
          height: 44,
        }}
        alt="Company logo"
        src={FindAsapLogo}
      />
    </>
  );
}

const handleLogOutFromUser = () => {
  const txtError = getText("default", "error", [{ name: 'during', value: '' }])

  try {
    // const navigate = useNavigate();
    // const tmpUserStore = getUserStore()
    // const tmpUrl = `${apiUrl}/security/logout?userId=${tmpUserStore.userId}`

    const isDEV = apiUrl.includes("localhost");
    const tmpUrl = `${apiUrl}/security/logout`

    // console.log('Logout user: - ' + tmpUrl)
    // toast.success('Logout user: - ' + tmpUrl, { duration: 5000, });


    const response = fetch(`${apiUrl}/security/logout`, {
      method: "GET", credentials: "include", headers: { "Content-Type": "application/json" } as HeadersInit
    });

    //    getURL(tmpUrl, 'GET', null)
    //      .then((fetchedData) => {


    handleLogout()
    setUserStore({
      userId: "-1" as string,
      userFirstName: "" as string,
      userLastName: "" as string,
      userCompanyId: "" as string,
      userCompanyName: "" as string,
      userAvatar: "" as string,
      userLanguage: "" as string,
      userCountry: "" as string
    });

    //if (fetchedData.status === '200') {
    //  const userStore = getUserStore()

    //  toast.success('Succesfull logout: - ' + userStore.userFirstName + " " + userStore.userLastName, { duration: 5000, });

    // window.open(`${apiUrl}`) //navigate("/")
    if (isDEV)
      window.location.href = 'http://localhost:3000'
    else
      window.location.href = 'https://findasap.team'
    // } 
    //  else {
    //    showError(txtError() as string, `${fetchedData.status}`);
    //  }

    //   }).catch((error) => {
    //     showError(txtError() as string, `${error.message}`);
    //   })
  } catch (error) {
    showError(txtError() as string, `${error}`);
  }
}


//************************************************************************************************
// Main Toolbar after Login
//************************************************************************************************
type AccountSubMenuProps = {
  open: boolean,
  onClose: () => void;
  anchorEl: null | HTMLElement;
};

const AccountSubMenu = (props: AccountSubMenuProps) => {
  const [anchorEl, setAnchorEl] = createSignal<null | HTMLElement>(null);
  const open = () => Boolean(anchorEl());

  const handleClose = () => {
    setAnchorEl(null);
    props.onClose();
  }

  const userStore = getUserStore()
  const navigate = useNavigate();

  const txtMyProfile = getText("menu", "myprofile")
  const txtOrgChange = getText("menu", "organizationsettings")
  const txtMemoOrganizer = getText("menu", "memoorganizer")
  const txtUploadAvatar = getText("menu", "uploadavatar")
  const txtUploadCompnayLogo = getText("menu", "uploadcompanylogo")
  const txtLogout = getText("menu", "logout")

  return (
    <>
      <Menu
        anchorEl={props.anchorEl}
        onClose={props.onClose}
        id="menu-appbar"
        open={props.open}
        //onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",

            mt: 1.5,
            ["& .MuiAvatar-root"]: {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "#03031C",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >

        <MenuItem onClick={() => navigate("/user")}>
          <Avatar src={userStore.userAvatar ? userStore.userAvatar : "https://mui.com/static/images/avatar/1.jpg"} /> {txtMyProfile()} {userStore.userFirstName}  {userStore.userLastName}
        </MenuItem>

        <SelectOrganization showButton={true} />

        <Divider />

        <ThemeSwitcher />

        <MenuItem onClick={() => navigate("/company")} >
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          {txtOrgChange()}
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <HeadsetMicIcon fontSize="small" />
          </ListItemIcon>
          {txtMemoOrganizer()}
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <CameraIcon fontSize="small" />
          </ListItemIcon>
          {txtUploadAvatar()}
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <CameraIcon fontSize="small" />
          </ListItemIcon>
          {txtUploadCompnayLogo()}
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogOutFromUser} >
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          {txtLogout()}<br />
          {apiUrl}
        </MenuItem>

      </Menu>
    </>
  )
}

const AccountMenu = () => {

  const [openMainMenu, setOpenMainMenu] = createSignal(true);

  const userStore = getUserStore()
  let showLoginName = userStore.userFirstName ? userStore.userFirstName + " " + userStore.userLastName : 'No login name'
  let showCompanyName = userStore.userCompanyName ? userStore.userCompanyName : 'FindAsap.team'

  const txtMyProfile = getText("menu", "myprofile")

  const [anchorEl, setAnchorEl] = createSignal<null | HTMLElement>(null);
  const open = () => Boolean(anchorEl());
  const handleClose = () => setAnchorEl(null);

  const handleMenuOpen = (event: { currentTarget: HTMLElement }) => {
    setAnchorEl(event.currentTarget);
    setOpenMainMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMainMenu(false);
  };

  const [openDrawer, setOpenDrawer] = createSignal(false);
  const handleOpenDrawer = (event: { currentTarget: HTMLElement }) => {
    setAnchorEl(event.currentTarget);
    setOpenDrawer(true);
  };
  const handleCloseDrawer = () => {
    setAnchorEl(null);
    setOpenDrawer(false);
  };

  const [accountMenuOpen, setAccountMenuOpen] = createSignal(false);
  const handleAccountMenuOpen = (event: { currentTarget: HTMLElement }) => {
    setAnchorEl(event.currentTarget);
    setAccountMenuOpen(true);
  };

  const handleAccountMenuClose = () => {
    setAnchorEl(null);
    setAccountMenuOpen(false);
  };

  const navigate = useNavigate();

  const handleNavigateRoute = (name: string) => {
    handleCloseDrawer()
    navigate(name);
  }

  const txtHome = getText("menu", "home")
  const txtLaunch = getText("menu", "launch")

  const txtMore = getText("menu", "more")
  const txtToDo = getText("quicklauncher", "todo")

  const txtFavourite = getText("menu", "favourits")
  const txtShare = getText("sharing", "share")
  const txtMessages = getText("smallcomponent", "messages")
  const txtNotifications = getText("menu", "notifications")

  const txtSelectOrg = getText("menu", "selectorganization")
  const txtOrgChange = getText("menu", "organizationsettings")
  const txtMemoOrganizer = getText("menu", "memoorganizer")
  const txtUploadAvatar = getText("menu", "uploadavatar")
  const txtUploadCompnayLogo = getText("menu", "uploadcompanylogo")
  const txtLogout = getText("menu", "logout")

  const [mobileMenuValue, setMobileMenuValue] = createSignal("/home");

  const [openQuickLauncher, setOpenQuickLauncher] = createSignal(false);
  const handleOpenQuickLauncher = () => {
    setOpenQuickLauncher(true);
  }
  const handleCloseQuickLauncher = () => {
    setOpenQuickLauncher(false);
  }
  const handleMobileMenuClick = (event: { currentTarget: HTMLElement }, item: string) => {
    if (item === "/home" || item === "/customertodo") {
      handleNavigateRoute(item)
    } else if (item === "more") {
      setAnchorEl(event.currentTarget);
      setOpenDrawer(true);
    } else if (item === "launch") {
      handleOpenQuickLauncher();
    }
    setMobileMenuValue(item)
  }

  const hasAvatar = true;

  return (
    <>
      <MyThemeProvider theme={ThemeForAppBar}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <AppBar sx={{ top: 'auto', bottom: 0, background: "var(--find-bg-color-menu)" }} position={mobileMenu ? "fixed" : "static"} >
            <Toolbar sx={{ justifyContent: "space-between" }}  >

              {openMainMenu() && (
                <>
                  <Show
                    when={mobileMenu}
                    fallback={
                      <>
                        <Box sx={{ display: 'none' }}>
                          <CompanyLogo />
                        </Box>

                        <HomeButton />

                        <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
                          <Favourites />
                          <AppBarSharing />
                        </Box>

                        <Box sx={{ display: { xs: 'flex', sm: 'flex' } }}>
                          {/*    <SearchWithNavigation maxWidth={230} /> */}
                          <Button color='primary' startIcon={<SearchIcon />}>
                            <GetTextUI formName={"menu"} label={"search"} />
                          </Button>
                        </Box>

                        <Box sx={{ flexGrow: 1 }} />
                        <Box sx={{ px: 1, display: { xs: 'flex', md: 'flex' } }}>

                          <ShowQuickLauncher />
                          <ShowMessages scrAvatar="/assets/imgs/avatar2.jpg" showPeople={true} newMessages={6} />
                          <ShowNotifications badgeContent="17" showButton={true} />
                          <Box sx={{ px: 1, display: { xs: 'flex' }, minWidth: 60, textAlign: "right" }}>
                            <Box sx={{ px: 1, display: { xs: 'none', md: 'flex' }, minWidth: 60, textAlign: "right" }}>
                              <Typography variant="subtitle1" sx={{ color: grey[200], textAlign: "right" }}> {showLoginName}</Typography>
                              <Typography variant="subtitle2" sx={{ color: grey[400], position: "absolute", top: "30px", textAlign: "right" }}>{showCompanyName} </Typography>
                            </Box>
                            <Show when={hasAvatar === true}
                              fallback={
                                <IconButton
                                  title={txtMyProfile()}
                                  onClick={handleAccountMenuOpen}
                                  color='primary'
                                  size="large"

                                  sx={{ marginLeft: "auto" }}
                                  aria-controls="menu-appbar"
                                  aria-haspopup="true"
                                  aria-expanded={open() ? "true" : undefined}
                                >
                                  <AccountCircleIcon />
                                </IconButton>
                              }>

                              <Avatar
                                alt="My picture"
                                onClick={handleAccountMenuOpen}
                                sx={{ cursor: 'pointer', marginLeft: "auto" }}
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                aria-expanded={open() ? "true" : undefined}
                                src={userStore.userAvatar ? userStore.userAvatar : "https://mui.com/static/images/avatar/1.jpg"}
                              />

                            </Show>
                          </Box>

                        </Box>

                        <AccountSubMenu open={accountMenuOpen()} onClose={handleAccountMenuClose} anchorEl={anchorEl()} />

                        {/*}
                        <Box sx={{ display: { xs: 'none', sm: 'block' }, minWidth: 50, textAlign: "right" }}>
                          <SelectTranslation />
                        </Box>
                          */}
                      </>

                    }
                  >

                    {/* * * * * * * * * * * * * * * * */}
                    {/* Mobile menu*/}
                    {/* * * * * * * * * * * * * * * * */}

                    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, justifyContent: 'space-between' }} elevation={3}>

                      <BottomNavigation
                        sx={{
                          background: "var(--find-bg-color-menu)", color: 'white',
                        }}
                        showLabels
                        value={mobileMenuValue()}
                        onChange={(event, newValue) => {
                          handleMobileMenuClick(event, newValue);
                        }}
                      >
                        <BottomNavigationAction
                          sx={{ background: "var(--find-bg-color-menu)", color: '#A9A9A9' }}
                          label={txtHome()}
                          value="/home"
                          icon={<HomeIcon />}
                        />
                        <OpenQuickLauncher open={openQuickLauncher()} handleClose={handleCloseQuickLauncher} />
                        <BottomNavigationAction
                          sx={{ background: "var(--find-bg-color-menu)", color: '#A9A9A9' }}
                          label={txtLaunch()}
                          value="launch"
                          icon={<GridViewIcon />}
                        />
                        <BottomNavigationAction
                          sx={{ background: "var(--find-bg-color-menu)", color: '#A9A9A9' }}
                          label={txtToDo()}
                          value="/customertodo"
                          icon={<WorkflowIcon />}
                        />

                        <Badge variant="dot" color="error" overlap="circular" >
                          <BottomNavigationAction
                            sx={{ background: "var(--find-bg-color-menu)", color: '#A9A9A9' }}
                            label={txtMore()}
                            value="more"
                            icon={<MoreVertIcon />}
                          >

                          </BottomNavigationAction>
                        </Badge>
                      </BottomNavigation>

                    </Paper>

                    <Drawer
                      anchor={"bottom"}
                      open={openDrawer()}
                      onClose={handleCloseDrawer}
                      sx={{ backgroundColor: "var(--find-bg-color-menu)" }} 
                    >
                      <SearchWithNavigation maxWidth={0} />
                      <Divider />

                      <List  >

                        <SelectOrganization showButton={false} />

                        <ListItemButton onClick={() => navigate("/company")}>
                          <ListItem  >
                            <ListItemIcon>
                              <Settings />
                            </ListItemIcon>
                            <ListItemText>
                              {txtOrgChange()}
                            </ListItemText>
                          </ListItem>
                        </ListItemButton>

                        <ListItemButton onClick={() => handleNavigateRoute("/favourit")}>
                          <ListItem >
                            <ListItemIcon>
                              <FavouritesIcon />
                            </ListItemIcon>
                            <ListItemText>
                              {txtFavourite()}
                            </ListItemText>
                          </ListItem>
                        </ListItemButton>

                        <ListItemButton onClick={() => handleNavigateRoute("/share")}>
                          <ListItem >
                            <ListItemIcon>
                              <SharingIcon />
                            </ListItemIcon>
                            <ListItemText>
                              {txtShare()}
                            </ListItemText>
                            <Badge badgeContent={4} max={99} color="error">
                            </Badge>
                          </ListItem>
                        </ListItemButton>

                        <ListItemButton onClick={() => handleNavigateRoute("/home")}>
                          <ListItem >
                            <ListItemIcon>
                              <MailIcon />
                            </ListItemIcon>
                            <ListItemText>
                              {txtMessages()}
                            </ListItemText>
                            <Badge badgeContent={1} max={99} color="error">

                            </Badge>
                          </ListItem>
                        </ListItemButton>

                        <ShowNotifications badgeContent="22" showButton={false} />

                        <Divider />
                        <Stack direction="row">

                          <ListItemButton onClick={() => handleNavigateRoute("/user")}>
                            <ListItem  >
                              <ListItemIcon>
                                <Avatar sx={{ width: 24, height: 24 }} />
                              </ListItemIcon>
                              <ListItemText>
                                {txtMyProfile()}
                              </ListItemText>
                            </ListItem>
                          </ListItemButton>


                          <ListItemButton onClick={handleLogOutFromUser}>
                            <ListItem  >
                              <ListItemIcon>
                                <LogoutIcon />
                              </ListItemIcon>
                              <ListItemText>
                                {txtLogout()}
                              </ListItemText>
                            </ListItem>
                          </ListItemButton>

                        </Stack>

                      </List>
                    </Drawer>


                  </Show>
                </>
              )}
            </Toolbar>
          </AppBar>

        </Box>
      </MyThemeProvider >
    </>
  );
}

////// BasicAppBar

const BasicAppBar = () => {
  const [open, setOpen] = createSignal(true);

  return (
    <>
      {open() && (
        <Box sx={{ flexGrow: 1 }}>
          <SplashBackground />
          <AppBar position="static" sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
            <Toolbar>
              <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                {/*    <CompanyLogo /> */}
                <Typography variant="h5">FindAsap.team</Typography>
              </Box>

              <SignUp />
              <ShowLoginButton />
              <SelectTranslation />
            </Toolbar>
          </AppBar>

        </Box>
      )}
    </>
  );
}


////// AppBarWithLogo

const AppBarWithLogo = () => {
  const [open, setOpen] = createSignal(true);

  return (
    <>
      {open() && (
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" sx={{ background: "var(--find-bg-color-menu)" }}>
            <Toolbar>

              <CompanyLogo />

            </Toolbar>
          </AppBar>

        </Box>
      )}
    </>
  );
}

export { AccountMenu, AppBarWithLogo, BasicAppBar }