
import Chip from "@suid/material/Chip";
import { Stack, IconButton, FormControl, Menu, MenuList, ListItemIcon, ListItemText, TextField, MenuItem, Box, InputLabel } from "@suid/material";

import OutlinedInput from "@suid/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@suid/material/Select";
import { Theme, useTheme } from "@suid/material/styles";
import { createSignal } from "solid-js";
import { UILanguages } from "../DataLists/DataUILanguageList";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      "max-height": `${ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP}px`,
      width: `${250}px`,
    },
  },
};

const names = [
  "(All) All Languages",
  "(UK) - UK English",
  "(US) - US English",
  "(DA) - Danish",
  "(NO) - Norwegian",
  "(SE) - Swedish",
  "(DE) - German",
];

const getStyles = (name: string, LanguageName: readonly string[], theme: Theme) => {
  return {
    "font-weight":
      LanguageName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


interface FieldLanguageListProps {
  id: string;
  label: string;

  setLabel?: (value: string) => void;
}


const FieldLanguageList = (props: FieldLanguageListProps) => {
  const theme = useTheme();
  const [LanguageName, setLanguageName] = createSignal<string[]>([]);
  const handleChange = (event: SelectChangeEvent<string | string[]>) => {
    const {
      target: { value },
    } = event;

    setLanguageName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div>

      <Stack direction="column" spacing={0.1}>

        <FormControl
          fullWidth

        >
          <InputLabel id={props.id}>{props.label} </InputLabel>
          <Select
            labelId={props.id}
            id={props.id}
            multiple
            value={LanguageName()}
            onChange={handleChange}
            input={
              <FormControl>
                <OutlinedInput id="select-multiple-chip-language" label={props.label} />
              </FormControl>
            }
            renderValue={(selected) => (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 0.5,
                }}
              >
                {selected.map((value) => (
                  <Chip label={value} />
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {names.map((name) => (
              <MenuItem value={name} style={getStyles(name, LanguageName(), theme)}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </div>
  );
}


interface FieldUILanguageListProps {
  defaultCode: string;
  changeLanguage: (value: string) => void;  
  setCode?: (value: string) => void;
}


const FieldUILanguageList = (props: FieldUILanguageListProps) => {

  const [languageName, setLanguageName] = createSignal<string>(props.defaultCode);
  const [anchorEl, setAnchorEl] = createSignal<null | HTMLElement>(null);
  const open = () => Boolean(anchorEl());
  const handleClose = () => setAnchorEl(null);

  const getFlagCode = (languageCode: string): string => {
    const language = UILanguages.find(lang => lang.code === languageCode);
    return language ? language.flagCode : "us";
  }

  const GetFlag = (flagCode: string, width: string) => {
    return (
      
      <img
        loading="lazy"
        width="20"
        src={`https://flagcdn.com/${width}/${flagCode.toLowerCase()}.png`}
        srcSet={`https://flagcdn.com/w40/${flagCode.toLowerCase()}.png 2x`}
        alt=""
      />
     
    )
  }
  return (
    <div>

     
        <IconButton
          aria-label="more"
          id="LanguageButton"
          aria-controls={open() ? "LanguageMenu" : undefined}
          aria-expanded={open() ? "true" : undefined}
          aria-haspopup="true"
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          {GetFlag(getFlagCode(languageName()),"w40")}
        </IconButton>
        <Menu
          id="LanguageMenu"
          MenuListProps={{ "aria-labelledby": "LanguageButton" }}
          anchorEl={anchorEl()}
          open={open()}
          onClose={handleClose}

        >
          {UILanguages.map((name) => (
            <MenuItem value={name.code} onClick={() => {
                setLanguageName(name.code); 
                props.changeLanguage(name.code);
                handleClose();
              }}
              disabled={name.active ? false : true}
              >

              <ListItemIcon>
                  {GetFlag(name.flagCode,"w20")}
              </ListItemIcon>
              <ListItemText>
             
                {name.label}

              </ListItemText>
            </MenuItem>

          ))}
        </Menu>
       
    </div >
  );
}

export { FieldLanguageList, FieldUILanguageList }